import React, { Component } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "../LexisCardGiftList.scss";

export default class MenuDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        { value: "gift", text: "Gift Details", status: "" },
      ],
    };
  }

  render() {
    return (
      <Menu
        anchorEl={this.props.anchorEl}
        id="account-menu"
        open={this.props.open}
        onClose={this.props.onClose}
        onClick={this.props.onClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {this.state.list.map((item, key) => {
          if (this.props.dataFrom) {
            if (
              this.props.dataFrom.status === 2 &&
              item.currentStatus !== this.props.dataFrom.status
            )
              return (
                item.currentStatus !== 0 && (
                  <MenuItem
                    className="dropdownMenuGift"
                    sx={{ fontSize: 14 }}
                    key={key}
                    onClick={() => this.props.onSelect(item)}
                  >
                    {item.text}
                  </MenuItem>
                )
              );
            else if (item.currentStatus !== this.props.dataFrom.status)
              return (
                <MenuItem
                  className="dropdownMenuGift"
                  sx={{ fontSize: 14 }}
                  key={key}
                  onClick={() => this.props.onSelect(item)}
                >
                  {item.text}
                </MenuItem>
              );
          }
        })}
      </Menu>
    );
  }
}
