import React, { Component } from "react";
import { Button, Grid, Tab, Tabs, Dialog } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SearchBox from "../SearchBox.js";
import "./components.scss";
import { initiateCall } from "../../../utils/ApiHandler";
import RequestType from "../../../utils/RequestType";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ActionsDropDownMenu from "./ActionsDropDownMenu";
import {REWARDS_TABLE} from "../../../Globals";
import 'react-quill/dist/quill.snow.css';
import RewardModal from "./rewardModal.js"
import DeleteModal from "./delete.js"
import Notifications from "../../../components/Notifications";

export const CREATE = "Create";
export const EDIT = "Edit";
export const DUPLICATE = "Duplicate";
export const DELETE = "Delete";
const DATA_PAGE = 0; // default page number
const DATA_PAGE_SIZE = 10; // default size of page
// tab values, also status values
const ACTIVE = 1;
const FULLY_REDEEMED = 2;
const DRAFTS = 0;

const ACTIVE_TAB = 0;
const FULLY_REDEEMED_TAB = 1;
const DRAFTS_TAB = 2;

const ITEM_BASE = { // base template for creation of items
    status: DRAFTS,
    name: "",
    srp: 0,
    pts_required: 0,
    quantity: 0,
    photos: [],
    expiry_date: new Date(),
    category: {all: false, featured: false},
    description: "",
    terms: ""
}

export function addTimeToDate(date) {
   const dateNow = new Date()
    const dateObj = new Date(date)
    const hoursNow = dateNow.getHours()
    const minutesNow = dateNow.getMinutes()
    const secondsNow = dateNow.getSeconds()
    const millisecondsNow = dateNow.getMilliseconds()

    return new Date(dateObj.getTime() + minutesNow * 60000 + hoursNow * 60000 * 60)
}

function dateChecker(date) { // required for server side date storage - > weird bug of date decrementing by one otherwise
    const newDate = new Date(date)
    return addTimeToDate(newDate)
}

export default class Reward extends Component {
  constructor(props) {
    super(props);
    this.searchFilterRef = React.createRef();
    this.state = {
      tab: 0,
      dataLoading: false,
      searchDisable: false,
      dataRows: [],
      dataPage: DATA_PAGE,
      dataPageSize: DATA_PAGE_SIZE,
      searchValue: "",
      anchorEl: null,
      open: false,
      modalData: ITEM_BASE,
      status: ACTIVE,
      sort: "asc",
      field: "name", // sort by name asc by default
      type: "",

      editModal: false,
      deleteModal: false,

      activecount: 0,
      redeemedcount: 0,
      draftscount: 0,
    };
  }

  componentDidMount(...args) {
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue);
    this.props.setExcelParams({ // passes parameters for excel exporting
      search: this.state.search,
      status: this.state.status
    })
  }

  handleTabChange = (e, newValue) => {
      const status = newValue === 0 ? 1 : newValue === 1 ? 2 : 0
    this.setState({
      tab: newValue,
      status: status,
      dataPage: DATA_PAGE,
      dataPageSize: DATA_PAGE_SIZE,

    });
    // pass props to parent for excel parameters
    this.props.setExcelParams({
      search: this.state.searchValue,
      status: status
    })
    this.fetchData(DATA_PAGE, DATA_PAGE_SIZE, status, this.state.searchValue);
  };

    modalToggle = () => {
        this.setState({type: CREATE, modalData: ITEM_BASE})
        this.setState({editModal : !this.state.editModal})
    };
    modalEditToggle = () => {
        this.setState({editModal : !this.state.editModal})
    };
    modalDeleteToggle = () => {
        this.setState({deleteModal : !this.state.deleteModal})
    };

  fetchData = async (
    page = null,
    pageSize = null,
    status = null,
    search = null,
    field = null,
    sort = null
  ) => {
    this.setState({ dataLoading: true, searchDisable: true });

    if (field == null) {
      field = this.state.field;
    }

    if (sort == null) {
      sort = this.state.sort;
    }

    const params = {
      tablename: REWARDS_TABLE,
      page: page,
      pageSize: pageSize,
      search: search,
      field: field,
      sort: sort,
      status: status,
    }

    const res = await initiateCall(RequestType.POST, `/rewards`, params);

    if (res) {
      this.setState({
        dataRows: res.data.data.rows,
        activecount: parseInt(res.data.activecount),
        redeemedcount: parseInt(res.data.redeemedcount),
        draftscount: parseInt(res.data.draftscount),
      });
    } else {
      this.setState({
        dataRows: [],
        activecount: 0,
        redeemedcount: 0,
        draftscount: 0,
      });
    }
    this.setState({ dataLoading: false, searchDisable: false });

    // Auto focus on searchbox if this was a search
    if (search != null) {
      document.getElementById("userSearchBox").focus();
    }
  };

  dropDownMenuOpen = (event, row) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
      modalData: row,
    });
  };

  dropDownMenuClose = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: false });
  };
  dropDownMenuSelected = (item) => {
    if (item === EDIT) {
      this.setState({ editModal: true,  type: EDIT })
    } else if (item === DUPLICATE) {
      this.setState({ editModal: true,  type: DUPLICATE})
    } else if (item === DELETE) {
      this.setState({ deleteModal: true })
    }
  };

  rowCount = () => {
    if (this.state.tab === ACTIVE_TAB) {
      return this.state.activecount;
    } else if (this.state.tab === FULLY_REDEEMED_TAB) {
      return this.state.redeemedcount;
    } else if (this.state.tab === DRAFTS_TAB) {
      return this.state.draftscount;
    }

    return -1;
  };

  create = async (item, type) => {
      const params = item
      params.tablename = REWARDS_TABLE
      const userid = JSON.parse(localStorage.getItem("@userdata"))
      params.createdby = userid.value.id // uuid in database
      if (type === DUPLICATE) {
          params.expiry_date = dateChecker(params.expiry_date) // creating / updating the date seems to have issues on pgsql
      }
      const res = await initiateCall(RequestType.POST, `/createrewarditem`, params);
        console.log(res)
      if (res.data.message === 'success') {
            this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue)
            this.flashSuccess("Successfully added Item!", 3000)
          // clear data
          this.setState({modalData: ITEM_BASE})
          this.modalToggle()
      } else {
          this.flashError("Error adding item", 10000)
      }
  }

  submit = async (data) => {
      const type = data.type
      if (type === EDIT) {
          await this.update(data)
      } else if (type === CREATE) {
          await this.create(data, type)
      } else if (type === DUPLICATE) {
          await this.create(data, type) // Duplication is essentially creation
      } else {
          this.flashError("Error! Invalid request!", 3000)
      }
  }

  update = async (item) => {
      const params = item
      params.tablename = REWARDS_TABLE
      const userid = JSON.parse(localStorage.getItem("@userdata"))
      params.modifiedby = userid.value.id
      params.expiry_date = dateChecker(params.expiry_date)

      const res = await initiateCall(RequestType.POST, `/editrewarditem`, params);

      if (res.data.message === 'success') {
          this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue)
          this.modalEditToggle()
          this.flashSuccess("Successfully updated Item!", 3000)
      } else {
          this.flashError("Error updating Item! Please ensure all fields are filled in!", 3000)
      }
  }

    delete = async (item) => {
        const params = item
        params.tablename = REWARDS_TABLE

        const res = await initiateCall(RequestType.POST, `/deleterewarditem`, params);

        if (res.data.message === 'success') {
            this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue)
            this.modalDeleteToggle()
            this.flashSuccess("Successfully deleted Item!", 3000)
        } else {
            this.flashError("Error deleting Item!", 3000)
        }
    }

  searchBoxChange = (event) => {
    this.setState({ searchValue: event.target.value });
    clearTimeout(this.searchFilterRef);
    this.searchFilterRef = setTimeout(
      () => this.fetchData(DATA_PAGE, DATA_PAGE_SIZE, this.state.status, event.target.value),
      700
    );
    this.setState({
      dataPage: DATA_PAGE,
      dataPageSize: DATA_PAGE_SIZE
    })

    // pass props to parent for excel parameters
    this.props.setExcelParams({
      search: event.target.value,
      status: this.state.status
    })
  };

  handleSortModelChange = (event) => {
    if (event[0]) {
      let field = event[0].field;
      let sort = event[0].sort;

      this.setState({
        sort: sort,
        field: field,
      });

      this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue, field, sort);
    }
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 150, flex: 1 },
      { field: "name", headerName: "Item Name", width: 300, flex: 1 },
      {
        field: "pts_required",
        headerName: "Pts Required",
        width: 130,
        flex: 1,
      },
      { field: "quantity_redeemed", headerName: "Qty Redeemed", width: 130, flex: 1,
      renderCell: (params) => {
          return (
              <div>
                  {params.row.quantity_redeemed} / {params.row.quantity}
              </div>
          );
      }},
      {
        field: "pretty_expiry_date",
        headerName: "Expiry Date",
        width: 150,
        flex: 1,
      },
      {
        field: "pretty_modifiedon",
        headerName: "Modified On",
        width: 150,
        flex: 1,
      },
      {
        field: "action",
        headerName: "Actions",
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              <MoreHorizIcon
                onClick={(event) => this.dropDownMenuOpen(event, params.row)}
              />
              {this.state.open ? (
                <ActionsDropDownMenu
                  anchorEl={this.state.anchorEl}
                  open={this.state.open}
                  onClose={this.dropDownMenuClose}
                  onClick={this.dropDownMenuClose}
                  onSelect={this.dropDownMenuSelected}
                  dataFromItem={this.state.modalData}
                />
              ) : (
                <div></div>
              )}
            </div>
          );
        },
      },
    ];
    return (
      <>
      {/* create / duplicate / edit modal */}
      <Dialog
            open={this.state.editModal}
            onClose={this.modalEditToggle}
            scroll="paper"
            PaperProps={{
                style: { borderRadius: 12 }
              }}
            >
        <RewardModal onClose={this.modalEditToggle}
                   shown={this.state.editModal} 
                   data={this.state.modalData} 
                   submit={this.submit}
                   type={this.state.type}/>
      </Dialog>

      {/* delete modal */}
      <Dialog
            open={this.state.deleteModal}
            onClose={this.modalDeleteToggle}
            scroll="paper"
            PaperProps={{
                style: { borderRadius: 12 }
              }}
            >
        <DeleteModal onClose={this.modalDeleteToggle} shown={this.state.deleteModal} data={this.state.modalData} delete={this.delete}/>
      </Dialog>

      <Grid className="container-reward" container spacing={1} sx={{ padding: "12px", marginTop: "40px" }}>
        <Grid className="container-reward-item" item xs={6}>
          <Button
            variant="contained"
            sx={{
              marginTop: "28px",
              textTransform: "none",
            }}
            className="createBtn"
            onClick={this.modalToggle}
          >
            Create Item
          </Button>
        </Grid>
        <Grid item xs={6}>
          <SearchBox
            boxlean={true}
            handleTextChange={this.searchBoxChange}
            disabled={this.state.searchDisable}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.handleTabChange}
            className="datatabs"
            TabIndicatorProps={{
              style: {
                height: "3px",
                marginTop: "-3px",
              },
            }}
            sx={{
              border: " solid rgba(224, 224, 224, 1)",
              borderWidth: "1px 1px 1px 0px;",
            }}
          >
            <Tab
              label={`Active (${this.state.activecount})`}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label={`Fully Redeemed (${this.state.redeemedcount})`}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label={`Drafts (${this.state.draftscount})`}
              sx={{ textTransform: "none" }}
            />
          </Tabs>
          <DataGrid
            rows={this.state.dataRows}
            columns={columns}
            loading={this.state.dataLoading}
            rowsPerPageOptions={[10, 50, 100]}
            paginationMode="server"
            page={this.state.dataPage}
            sortingMode="server"
            rowCount={this.rowCount()}
            pageSize={this.state.dataPageSize}
            sx={{
              bgcolor: "white",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderTopWidth: 0,
            }}
            autoHeight
            disableColumnSelector
            disableSelectionOnClick
            onPageChange={(newPage) => {
              this.setState({ dataPage: newPage });
              this.fetchData(newPage, this.state.dataPageSize, this.state.status, this.state.searchValue);
            }}
            onPageSizeChange={(newPageSize) => {
              this.setState({ dataPageSize: newPageSize, dataPage: DATA_PAGE });
              this.fetchData(DATA_PAGE, newPageSize, this.state.status, this.state.searchValue);
            }}
            onSortModelChange={this.handleSortModelChange}
          />
        </Grid>
        </Grid>
          <Notifications
              setFlashSuccess={(func) => (this.flashSuccess = func)}
              setFlashError={(func) => (this.flashError = func)}
          />
        </>
    );
  }
}
