import BasePageComponent from "../../components/BasePageComponent";
import "./AdminManagement.scss";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import DialogActions from "@mui/material/DialogActions";

import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Api from "../../services/Api";
export default class AdminManagement extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userArray: [],

      userId: "",
      userEmail: "",
      userType: "",

      openAddDialog: false,
      addName: "",
      addEmail: "",
      addRole: "Admin",
      errorName: "",
      errorEmail: "",
      errorRole: "",

      selectedIndex: { id: "", name: "", email: "", usertype: "" },
      openDeleteDialog: false,

      openEditDialog: false,
      editRole: "",
      roleEdit: "",
      ownerPassword: "",
      showOwnerPassword: false,
      errorOwnerPassword: "",
    };
  }

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    //let userdata = JSON.parse(localStorage.getItem("@userdata"));
    let userdata = this.getWithExpiry("@userdata");
    this.setState({
      userId: userdata.id,
      userEmail: userdata.email,
      userType: userdata.usertype,
    });

    this.getdata();
  };

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  getdata = async () => {
    this.setState({ isLoading: true });

    const res = await Api.get(`/getAllCmsUser/${this.state.id}`);
    if (res.data.message === "success") {
      let sortedArr = [[],[],[],[]];
      res.data.data.map((x) => {
        if (x.usertype == "Owner"){sortedArr[0].push(x)}
        else if (x.usertype == "Super Admin"){sortedArr[1].push(x)}
        else if (x.usertype == "Admin"){sortedArr[2].push(x)}
        else if (x.usertype == "Intern"){sortedArr[3].push(x)}
      })
      sortedArr.forEach((arr) => {arr.sort((a, b) => a.name.localeCompare(b.name))});
      this.setState({ userArray: sortedArr.flat() });
      this.setState({ isLoading: false });
    }
  };

  checkName = () => {
    if (this.state.addName === "") {
      this.setState({ errorName: "Please enter a name" });
      return false;
    } else {
      this.setState({ errorName: "" });
      return true;
    }
  };
  checkEmail = () => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (
      this.state.addEmail === "" ||
      regex.test(this.state.addEmail) === false
    ) {
      this.setState({ errorEmail: "Please enter a email" });
      return false;
    } else {
      this.setState({ errorEmail: "" });
      return true;
    }
  };

  checkRole = () => {
    if (this.state.addRole === "") {
      this.setState({ errorRole: "Please enter a role" });
      return false;
    } else {
      this.setState({ errorRole: "" });
      return true;
    }
  };

  openNewDialog = () => {
    this.setState({ openAddDialog: true });
  };

  handleChange = (event) => {
    this.setState({ addRole: event.target.value });
  };

  handleEditChange = (event) => {
    this.setState({ editRole: event.target.value });
  };

  handleClickShowOwnerPassword = () => {
    this.setState({ showOwnerPassword: !this.state.showOwnerPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  closeNewDialog = () => {
    this.setState({ openAddDialog: false });
  };

  addNewAdmin = async () => {
    let cName = this.checkName();
    let cEmail = this.checkEmail();
    let cRole = this.checkRole();

    if (cName && cEmail && cRole) {
      let data = {
        name: this.state.addName,
        email: this.state.addEmail,
        usertype: this.state.addRole,
      };
      const res = await Api.post(`/addNewCmsusers`, data);
      if (res.data.message === "success") {
        this.setState({ addName: "", addEmail: "", addRole: "" });
        this.closeNewDialog();
        this.getdata();
      } else {
        this.setState({ errorEmail: res.data.message });
      }
    }
  };

  openDeleteDialog = (data) => {
    this.setState({
      selectedIndex: {
        id: data.id,
        name: data.name,
        email: data.email,
        usertype: data.usertype,
      },
    });
    this.setState({ openDeleteDialog: true });
  };

  closeDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  deleteUser = async (id) => {
    const data = {
      id: id,
    };
    const res = await Api.post(`/deleteCmsuser`, data);
    if (res.data.message === "success") {
      this.setState({
        selectedIndex: { id: "", name: "", email: "", usertype: "" },
        openDeleteDialog: false,
      });
      this.getdata();
    }
  };

  editUser = async (user) => {
    console.log(user);
    await this.setState({
      selectedIndex: {
        id: user.id,
        name: user.name,
        email: user.email,
        usertype: user.usertype,
      },
      roleEdit: user.usertype,
    });
    console.log(this.state.selectedIndex, this.state.roleEdit);
    this.setState({ openEditDialog: true });
  };

  closeEditDialog = () => {
    this.setState({
      selectedIndex: { id: "", name: "", email: "", usertype: "" },
      openEditDialog: false,
      editRole: "",
    });
  };

  checkPassword = () => {
    if (this.state.ownerPassword === "") {
      this.setState({ errorOwnerPassword: "Please enter your password" });
      return false;
    } else {
      this.setState({ errorOwnerPassword: "" });
      return true;
    }
  };

  addEditAdmin = async () => {
    var status = false;
    if (this.state.editRole === "Owner") {
      let checks = this.checkPassword();
      if (checks) {
        var pw = {
          id: this.state.userId,
          password: this.state.ownerPassword,
        };
        const response = await Api.post(`/verifyPassword`, pw);
        if (response.data.message === "success") {
          status = response.data.data;
          if (response.data.data === false) {
            this.setState({
              errorOwnerPassword: "Please enter a valid password",
            });
          }
        }
      }
    } else {
      status = true;
    }

    if (status) {
      var data = {
        usertype: this.state.editRole,
        id: this.state.selectedIndex.id,
        userId: this.state.userId,
      };

      const res = await Api.post(`/editCmsuser`, data);

      if (res.data.message === "success") {
        this.setState({
          selectedIndex: { id: "", name: "", email: "", usertype: "" },
          openEditDialog: false,
        });

        if (this.state.editRole === "Owner") {
          localStorage.clear();
          window.location.href = `/`;
        } else {
          this.setState({
            editRole: "",
          });
          this.getdata();
        }
      }
    }
  };

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{ fontWeight: "bold" }}
                >
                  Admin Management
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ margin: "auto 0" }}>
                <Button
                  variant="contained"
                  className="cmsAdminBtn"
                  onClick={this.openNewDialog}
                >
                  Add Admin
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <div className="AdminManagement">
          <div className="container">
            {this.state.isLoading === false ? (
              <div className="userlist">
                {this.state.userArray.map((data, index) => (
                  <div className="useritem" key={index}>
                    <div className="flex">
                      <div className="left">
                        <p className="name">{data.name}</p>
                        <p className="email">{data.email}</p>
                      </div>
                      <div className="right">
                        <p className="role">{data.usertype}</p>
                        {data.email !== this.state.userEmail &&
                        ((this.state.userType === "Owner" &&
                          data.usertype !== "Owner") ||
                          (this.state.userType === "Super Admin" &&
                            data.usertype === "Admin") ||
                          (this.state.userType === "Super Admin" &&
                            data.usertype === "Intern")) ? (
                          <div className="actions">
                            <IconButton onClick={() => this.editUser(data)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => this.openDeleteDialog(data)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div className="actions hideActions">
                            <IconButton onClick={() => this.editUser(data)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => this.openDeleteDialog(data)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <CircularProgress className="loading" />
            )}
            <Dialog
              className="adminDialogTitle"
              onClose={this.closeNewDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.openAddDialog}
              minWidth={"500"}
            >
              <DialogTitle className="dialogTitle">
                <div className="dialogTitle">Add Admin</div>
              </DialogTitle>
              <DialogContent dividers className="dialogContent">
                <div>
                  <p className="headerText">
                    An email and temporary password will be sent to this user to
                    activate their account.
                  </p>
                  <FormControl className="form-control" variant="outlined">
                    <label htmlFor="name">Name</label>
                    <OutlinedInput
                      id="name"
                      type="text"
                      className="input"
                      variant="outlined"
                      value={this.state.addName}
                      fullWidth
                      onChange={(e) =>
                        this.setState({ addName: e.target.value })
                      }
                    />
                    <div className="errorMessage">{this.state.errorName}</div>
                  </FormControl>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <FormControl className="form-control" variant="outlined">
                    <OutlinedInput
                      id="email"
                      type="text"
                      className="input"
                      variant="outlined"
                      value={this.state.addEmail}
                      onChange={(e) =>
                        this.setState({ addEmail: e.target.value })
                      }
                    />
                    <div className="errorMessage">{this.state.errorEmail}</div>
                  </FormControl>
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Assign Role
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={this.state.addRole}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="Intern"
                        control={<Radio />}
                        label="Intern"
                      />
                      <FormControlLabel
                        value="Admin"
                        control={<Radio />}
                        label="Admin"
                      />
                      {this.state.userType === "Owner" && (
                        <FormControlLabel
                          value="Super Admin"
                          control={<Radio />}
                          label="Super Admin"
                        />
                      )}
                    </RadioGroup>
                    <div className="errorMessage">{this.state.errorRole}</div>
                  </FormControl>
                </div>
              </DialogContent>
              <div className="addAdmin">
                <DialogActions>
                  <Button
                    className="cancelButton"
                    variant="text"
                    onClick={this.closeNewDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="addButton"
                    variant="contained"
                    onClick={this.addNewAdmin}
                  >
                    Add
                  </Button>
                </DialogActions>
              </div>
            </Dialog>

            <Dialog
              className="adminDialogDelete"
              onClose={this.closeDeleteDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.openDeleteDialog}
              minWidth={"500"}
            >
              <DialogTitle className="dialogTitle">
                <div className="dialogTitle">Remove Admin?</div>
              </DialogTitle>
              <DialogContent dividers className="dialogContent">
                <div>
                  <p className="headerText">
                    Are you sure you want to remove the following admin? You
                    cannot undo this action.
                  </p>
                </div>
                <div class="flex">
                  <div className="left">
                    <p className="name">{this.state.selectedIndex.name}</p>
                    <p className="email">{this.state.selectedIndex.email}</p>
                  </div>
                  <div className="right">
                    <p className="role">{this.state.selectedIndex.usertype}</p>
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  className="cancelButton"
                  variant="text"
                  onClick={this.closeDeleteDialog}
                >
                  Cancel
                </Button>
                <Button
                  className="deleteButton"
                  variant="contained"
                  onClick={() => this.deleteUser(this.state.selectedIndex.id)}
                >
                  Remove
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              className="adminEditDialog"
              onClose={this.closeEditDialog}
              open={this.state.openEditDialog}
              minWidth={"500"}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",
                  },
                },
              }}
            >
              <DialogTitle className="dialogTitle">
                <div className="dialogTitle">Edit Admin</div>
              </DialogTitle>
              <DialogContent dividers className="dialogContent">
                <div className="flex">
                  <div className="left">
                    <p className="name">{this.state.selectedIndex.name}</p>
                    <p className="email">{this.state.selectedIndex.email}</p>
                  </div>
                  <div className="right">
                    <p className="role">{this.state.selectedIndex.usertype}</p>
                  </div>
                </div>

                <div className="form-group">
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Change Admin Role
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={
                      !this.state.editRole
                        ? this.state.roleEdit
                        : this.state.editRole
                    }
                    onChange={this.handleEditChange}
                  >
                    <FormControlLabel
                      value="Intern"
                      control={<Radio />}
                      label="Intern"
                    />
                    <FormControlLabel
                      value="Admin"
                      control={<Radio />}
                      label="Admin"
                    />
                    {this.state.userType === "Owner" && (
                      <FormControlLabel
                        value="Super Admin"
                        control={<Radio />}
                        label="Super Admin"
                      />
                    )}
                    {this.state.selectedIndex.usertype === "Super Admin" && (
                      <div>
                        <FormControlLabel
                          value="Owner"
                          control={<Radio />}
                          label="Owner"
                        />
                        <div>
                          {this.state.editRole === "Owner" && (
                            <div>
                              <p>
                                For security purposes, please re-enter your
                                password below.
                              </p>
                              <div className="form-group">
                                <label htmlFor="ownerPassword">Password</label>
                                <FormControl
                                  className="form-control"
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    className="input"
                                    name="ownerPassword"
                                    id="ownerPassword"
                                    fullWidth
                                    type={
                                      this.state.showOwnerPassword
                                        ? "text"
                                        : "password"
                                    }
                                    value={this.state.ownerPassword}
                                    onChange={(e) =>
                                      this.setState({
                                        ownerPassword: e.target.value,
                                      })
                                    }
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            this.handleClickShowOwnerPassword
                                          }
                                          onMouseDown={
                                            this.handleMouseDownPassword
                                          }
                                          edge="end"
                                        >
                                          {this.state.showOwnerPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                  <div className="errorMessage">
                                    {this.state.errorOwnerPassword}
                                  </div>
                                </FormControl>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </RadioGroup>
                </div>
              </DialogContent>
              <div className="addAdmin">
                <DialogActions>
                  <Button
                    className="cancelButton"
                    variant="text"
                    onClick={this.closeEditDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="addButton"
                    variant="contained"
                    onClick={this.addEditAdmin}
                  >
                    Save
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </div>
        </div>
      </>
    );
  }
}
