import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export default class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.enterToSearch);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.enterToSearch);
  }

  enterToSearch = (e) => {
    if (e.key === "Enter" && this.state.isFocused) {
      this.props.handleTextChange(e);
    }
  };

  setFocused = () => {
    this.setState({ isFocused: true });
  };

  setNotFocused = () => {
    this.setState({ isFocused: false });
  };

  handleInputChange = (e) => {
    // Call the onChange prop function passed from the parent component
    if (this.props.onChange) {
      this.props.onChange(e.target.value, this.props.field);
    }
  };

  render() {
    return (
      <Box style={{ float: 'left', marginTop: 5 }}>
        <TextField
          className="inputField"
          disabled={this.props.disabled}
          hiddenLabel
          onFocus={this.setFocused}
          onBlur={this.setNotFocused}
          size="small"
          defaultValue={this.props.defaultValue}
          onChange={this.handleInputChange}
        />
      </Box>
    );
  }
}
