import React, { Component } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {EDIT, DUPLICATE, DELETE} from "./reward"

export default class ActionsDropDownMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Menu
                anchorEl={this.props.anchorEl}
                id="account-menu"
                open={this.props.open}
                onClose={this.props.onClose}
                onClick={this.props.onClick}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.05))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    sx={{ fontSize: 14 }}
                    onClick={() => this.props.onSelect(EDIT)}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    sx={{ fontSize: 14 }}
                    onClick={() => this.props.onSelect(DUPLICATE)}
                >
                    Duplicate
                </MenuItem>
                <MenuItem
                    sx={{ fontSize: 14, color: 'red' }}
                    onClick={() => this.props.onSelect(DELETE)}
                >
                    Delete
                </MenuItem>
            </Menu>
        );
    }
}
