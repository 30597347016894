import React from "react";
import Checkbox from "@mui/material/Checkbox";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";

/**
 * Helps with multi-row selection in user tables.
 */
function CheckBox() {
  return (
    <Checkbox
      icon={<CircleUnchecked />}
      checkedIcon={<CircleCheckedFilled />}
    />
  );
}

export {
    CheckBox
}