import "./Styles/AdminUsers.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DropDownMenu from "./DropDownMenu";
import AlertDialog from "./AlertDialog";
import EditDetailsDialog from "./EditDetailsDialog";
import { Component } from "react";
import Api from "../../../services/Api";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";

export default class AdminUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
      selectedUser: [],
      DialogOpen: false,
      DialogTitle: "Title",
      DialogMessage: "Deactive the following account?",
      DialogMessageSub: "",
      DialogButtonText: "Button",
      DialogButtonColor: "primary",
      EditDialog: false,
      adminUser: [],
      userid: "",
    };
  }

  columns = [
    {
      field: "name",
      headerName: "Name",
      width: 50,
      editable: true,
      flex: 1,
    },
    {
      field: "contactnumber",
      headerName: "Contact No.",
      width: 50,
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) {
          return <div>-</div>;
        } else {
          return <div>{params.value}</div>;
        }
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 160,
      flex: 1,
    },
    {
      field: "createdon",
      headerName: "Date Joined",
      sortable: false,
      width: 50,
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "usertype",
      headerName: "Role",
      sortable: false,
      width: 100,
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <MoreHorizIcon
          onClick={(event) => this.dropDownMenuOpen(event, params.row)}
        />
      ),
    },
  ];

  componentDidMount = async (...args) => {
    const param = new URLSearchParams(window.location.search);
    const paramater = param.get("id");

    this.setState({ userid: paramater });

    this.getdata(paramater);
  };
  getdata = async (userid) => {
    const res = await Api.get(`/getAllAdminUser/${userid}`);
    this.setState({ adminUser: res.data.data });
  };

  dropDownMenuOpen = (event, row) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
      selectedUser: row,
    });
  };

  dropDownMenuClose = (event, row) => {
    this.setState({
      setAnchorEl: event.currentTarget,
      open: false,
    });
  };

  dropdownMenuSelected = (itemdata) => {
    //updated props
    this.setState({
      DialogOpen: true,
      DialogTitle: itemdata.text,
      DialogMessage: itemdata.buttonStatus + " " + "the following account?",
      DialogMessageSub: this.state.selectedUser.name,
      DialogButtonText: itemdata.buttonStatus,
      DialogButtonColor: itemdata.value == "activate" ? "primary" : "error",
      EditDialog: false,
    });
  };

  editDetailSelected = (itemdata) => {
    //  console.log(itemdata);
    this.setState({
      DialogOpen: false,
      DialogTitle: itemdata.text,
      DialogMessage: itemdata.buttonStatus + " " + "the following account?",
      DialogMessageSub: this.state.selectedUser.name,
      DialogButtonText: itemdata.buttonStatus,
      DialogButtonColor: itemdata.value == "edit" ? "primary" : "error",
      EditDialog: true,
      selectedUser: itemdata.data,
      selectedEmail: itemdata.data.email,
      selectedName: itemdata.data.name,
    });
  };
  getselectedUser = () => {
    return this.state.selectedUser;
  };

  updateUser = async () => {
    const data = {
      name: this.state.selectedName,
      email: this.state.selectedEmail,
      id: this.state.selectedUser.id,
      type: this.state.selectedUser.usertype,
    };
    console.log(data);
    const res = await Api.post(`/updateAdminUser`, data);
    console.log(res);
    if (res.data.message == "success") {
      this.closeAlertDialog();
      this.getdata(this.state.userid);
    }
  };

  deleteUser = async () => {
    const data = {
      id: this.state.selectedUser.id,
    };

    const res = await Api.post(`/deleteAdminUser`, data);
    console.log(res.data);
    if (res.data.message == "success") {
      this.closeAlertDialog();
      this.getdata(this.state.userid);
    }
  };
  closeAlertDialog = (event) => {
    this.setState({
      DialogOpen: false,
      EditDialog: false,
    });
  };

  rows = [
    {
      id: 1,
      name: "admin 1",
      contacts: "2042384904332",
      email: "daskjdksj@ggmail.com",
      datejoined: "may 3 2022",
      role: "Admin",
      status: "undecided",
    },
    {
      id: 2,
      name: "admin 2",
      contacts: "0903237247344",
      email: "daskjdksj@ggmail.com",
      datejoined: "may 3 2022",
      role: "Admin",
      status: "undecided",
    },
    {
      id: 3,
      name: "admin 3",
      contacts: "7489273478249",
      email: "daskjdksj@ggmail.com",
      datejoined: "may 3 2022",
      role: "Admin",
      status: "undecided",
    },
  ];

  render() {
    return (
      <div>
        <Grid
          md={12}
          sx={{ backgroundColor: "white", height: "103vh", marginTop: "90px" }}
        >
          <DataGrid
            rows={this.state.adminUser}
            columns={this.columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </Grid>
        <Dialog
          open={this.state.EditDialog}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth
          maxWidth="xs"
          onClose={this.closeAlertDialog}
        >
          <DialogTitle
            sx={{ backgroundColor: "#f5f2f2", fontWeight: "bold" }}
            className="DialogTitle"
            id="scroll-dialog-title"
          >
            Edit Details
          </DialogTitle>

          <DialogContent>
            <DialogContentText style={{ padding: "5px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <label style={{ marginBottom: "5px" }}>Name</label>
                <TextField
                  id="outlined-basic"
                  value={this.state.selectedName}
                  onChange={(e) =>
                    this.setState({ selectedName: e.target.value })
                  }
                  inputProps={{ style: { padding: 9 } }}
                  variant="outlined"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <label style={{ marginBottom: "5px" }}>Email</label>
                <TextField
                  id="outlined-basic"
                  value={this.state.selectedEmail}
                  onChange={(e) =>
                    this.setState({ selectedEmail: e.target.value })
                  }
                  inputProps={{ style: { padding: 9 } }}
                  variant="outlined"
                />
              </div>
            </DialogContentText>
          </DialogContent>

          <DialogActions sx={{ backgroundColor: "#f5f2f2" }}>
            <Button className="Button" onClick={this.closeAlertDialog}>
              Close{" "}
            </Button>
            <Button
              className="Button"
              variant="contained"
              onClick={this.updateUser}
            >
              {" "}
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        <DropDownMenu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          selectedUser={this.state.selectedUser}
          close={this.dropDownMenuClose}
          dropDownMenuClose={this.dropDownMenuClose}
          selectItem={this.dropdownMenuSelected}
          editSelected={this.editDetailSelected}
        />

        <AlertDialog
          DialogOpen={this.state.DialogOpen}
          dialogTitle={this.state.DialogTitle}
          DialogMessage={this.state.DialogMessage}
          DialogMessageSub={this.state.DialogMessageSub}
          DialogButtonText={this.state.DialogButtonText}
          DialogButtonColor={this.state.DialogButtonColor}
          DialogEmail={this.state.selectedUser.email}
          DialogCloser={this.closeAlertDialog}
          deleteUser={this.deleteUser}
        />

        {/* <EditDetailsDialog
                  editOpen ={this.state.EditDialog} 
                  close ={this.closeAlertDialog}
                  getuserdata={this.getselectedUser}
                  userdata={this.state.selectedUser}
                  

             /> */}
      </div>
    );
  }
}
