import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


export default{
    userdata: {
      name: 'test'
    }
}

export const GlobalContext = React.createContext([])


export const ButtonGradient = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#2964F5"),
    background: "linear-gradient(90deg, #AB66FF 0%, #74B6F7 100%);",
    '&:hover': {
      background: "linear-gradient(90deg, #AB66FF 0%, #74B6F7 80%);",
    },
    textTransform:"capitalize",
    fontFamily:"inter",
    fontSize: "16px",
    fontWeight:"400",
    padding:"6px 16px"
}));
export const REWARDS_TABLE = "rewards"
export const REDEMPTION_TABLE = "rewards_redemption"
