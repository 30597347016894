import axios from 'axios';
import { getEnvVars } from './Environment'
import Globals from '../Globals'
// import EventBus from 'react-native-event-bus'

const { apiUrl } = getEnvVars();

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  timeout: 20000
});

axiosInstance.interceptors.request.use(function (config) {
  if (Globals.userdata.jwt){
    config.headers.post = {"Authorization": Globals.userdata.jwt}
    config.headers.get = {"Authorization": Globals.userdata.jwt}    
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
  return Promise.resolve(response)
}, function (error) {
  return Promise.reject(error);
});


export default axiosInstance;

export function ApiGet(apiEndpoint) {
  apiEndpoint = apiUrl + "/api" + apiEndpoint;
  console.log("ApiGet",apiEndpoint)
  return axiosInstance({
    method: 'GET',
    url: apiEndpoint,
    baseURL: apiUrl,
  })
}
