import React, { Component } from "react";
import { Button, Grid, Box, Typography, DialogContent, DialogActions } from "@mui/material";
import "./components.scss";

const style = {
    modal: {
        width: 400,
        bgcolor: "white",
        boxShadow: 24,
        padding: "27px 22px 27px 22px",
    },
    header: {
        backgroundColor: "#F1F2F6",
        padding: 3,
    }
};

export default class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    render() {
        return (
            <>
            <Grid item xs={12} sx={{...style.header,  fontWeight: 'bold' }}>Delete Item?</Grid>
            <DialogContent dividers={true} sx={{padding: 0}}>
            <Box sx={style.modal} >
                <Typography>This action cannot be undone. Are you sure you want to proceed?</Typography>
            </Box>
            </DialogContent>
                <DialogActions sx={{padding: 2}}>
                <Button
                        variant="text"
                        sx={{
                        textTransform: "none",
                        color: "red"
                        }}
                        className="createBtn"
                        onClick={this.props.onClose}
                    >
                        Cancel
                    </Button><Button
                        variant="contained"
                        sx={{
                        textTransform: "none", backgroundColor: "red",
                        '&:hover': {
                            background: "#ff2626",
                         },
                        }}
                        className="delBtn"
                        onClick={() => this.props.delete(this.props.data)}
                    >
                        Delete
                    </Button>
                </DialogActions>
                </>
        );
    }
}