import { Component } from "react";
import "./ResetPassword.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import { initiateCall } from "../../utils/ApiHandler";
import RequestType from "../../utils/RequestType";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Notifications from "../../components/Notifications";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showPassword: false,
      showCfmPassword: false,
      password: "",
      cfmPassword: "",
      errorPassword: "",
      errorCfmPassword: "",
      boolErrorPassword: false,
      boolErrorCfmPassword: false,
      payload: "",
      emailtype: "",
    };
  }

  async componentDidMount() {
    const authResult = new URLSearchParams(window.location.search);
    const code = authResult.get("code");
    if (code == null || code === "") {
      window.location.assign("/");
    } else {
      this.setState({ payload: code });

      const data = {
        payload: code,
      };

      const res = await initiateCall(RequestType.POST, `/decodePayLoad`, data);

      if (res.data.message === "success") {
        this.setState({ emailtype: res.data.data });
      }
    }
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClickShowCfmPassword = () => {
    this.setState({
      showCfmPassword: !this.state.showCfmPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  passwordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  cfmPasswordChange = (e) => {
    this.setState({ cfmPassword: e.target.value });
  };

  passwordValidation() {
    // 1 upper, 1 lower, 1 special, 1 number, minimum 8 characters
    const regex =
      /^(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!this.state.password || regex.test(this.state.password) === false) {
      this.setState({
        errorPassword:
          "Password is not valid. Ensure password has a minimum of 8 Characters, with at least a symbol, upper and lower case letters and a number.",
        boolErrorPassword: true,
      });
      return false;
    }
    this.setState({
      errorPassword: "",
      boolErrorPassword: false,
    });
    return true;
  }
  cfmPasswordValidation() {
    if (
      !this.state.cfmPassword ||
      this.state.password !== this.state.cfmPassword
    ) {
      this.setState({
        errorCfmPassword: "Passwords do not match.",
        boolErrorCfmPassword: true,
      });
      return false;
    }
    this.setState({
      errorCfmPassword: "",
      boolErrorCfmPassword: false,
    });
    return true;
  }

  handleReset = async () => {
    this.setState({ isLoading: true });
    this.passwordValidation();
    this.cfmPasswordValidation();

    if (!this.passwordValidation() || !this.cfmPasswordValidation()) {
      this.setState({ isLoading: false });
      return;
    }

    const res = await initiateCall(RequestType.POST, `/resetpassword`, {
      password: this.state.password,
      payload: this.state.payload,
    });

    if (res.data.message === "success") {
      this.setState({
        isLoading: false,
      });
      this.flashSuccess("Successful!", 3000);
      this.props.history.push("/");
    } else {
      this.setState({
        isLoading: false,
      });
      this.flashError("Please try again.", 3000);
    }
  };

  render() {
    return (
      <div className="ResetPassword">
        <Grid container>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <img
              className="MainLogo"
              src="assets/images/LexisJobs_Logo.png"
              alt="LexisJobs App"
            />
            <Paper elevation={10} sx={{ justifyContent: "center", padding: 4 }}>
              {this.state.emailtype === "setpw" ? (
                <Typography className="SubHeader">Set your password</Typography>
              ) : (
                <Typography className="SubHeader">
                  Reset your password
                </Typography>
              )}
              <Typography className="subtext">
                Please enter your new password
              </Typography>
              <Typography className="SubLabel">Password</Typography>
              <Box
                sx={{
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.password}
                  id="standard-adornment-password"
                  onChange={this.passwordChange}
                  type={this.state.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={this.state.boolErrorPassword}
                  helperText={
                    this.state.boolErrorPassword ? this.state.errorPassword : ""
                  }
                />
              </Box>
              <Typography className="SubLabel">Confirm Password</Typography>
              <Box
                sx={{
                  paddingBottom: "0px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.cfmPassword}
                  id="standard-adornment-password"
                  onChange={this.cfmPasswordChange}
                  type={this.state.showCfmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowCfmPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showCfmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={this.state.boolErrorCfmPassword}
                  helperText={
                    this.state.boolErrorCfmPassword
                      ? this.state.errorCfmPassword
                      : ""
                  }
                />
              </Box>
              {!this.state.isLoading && (
                <Button className="resetButton" onClick={this.handleReset}>
                  Reset Password
                </Button>
              )}
              {this.state.isLoading && (
                <LoadingButton
                  className="resetButton"
                  loading={true}
                  loadingPosition="start"
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Reset Password
                </LoadingButton>
              )}
            </Paper>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <Notifications
          setFlashSuccess={(func) => (this.flashSuccess = func)}
          setFlashError={(func) => (this.flashError = func)}
        />
      </div>
    );
  }
}
