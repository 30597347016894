import { Component } from "react";
import "./Login.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import { initiateCall } from "../../utils/ApiHandler";
import RequestType from "../../utils/RequestType";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Notifications from "../../components/Notifications";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailSubmittedEmpty: false,
      errorEmail: "",
      password: "",
      passwordSubmittedEmpty: false,
      showPassword: false,
      isLoading: false,
    };
  }

  enterToLogin = (e) => {
    if (e.key === "Enter") {
      this.handleLogin();
    }
  };

  async componentDidMount() {
    window.addEventListener("keydown", this.enterToLogin);
  }

  async componentWillUnmount() {
    window.removeEventListener("keydown", this.enterToLogin);
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  emailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  passwordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  hasEmptyInput() {
    let hasEmpty = false;

    if (this.state.email === "") {
      hasEmpty = true;
      this.setState({
        emailSubmittedEmpty: true,
        errorEmail: "Please enter an email address.",
      });
    } else {
      this.setState({ emailSubmittedEmpty: false, errorEmail: "" });
    }

    if (this.state.password === "") {
      hasEmpty = true;
      this.setState({ passwordSubmittedEmpty: true });
    } else {
      this.setState({ passwordSubmittedEmpty: false });
    }

    return hasEmpty;
  }

  handleLogin = async () => {
    if (this.hasEmptyInput()) {
      return;
    }

    if (!this.emailValidation()) {
      return;
    }

    this.setState({ isLoading: true });
    const res = await initiateCall(RequestType.POST, `/login`, {
      email: this.state.email,
      password: this.state.password,
    });
    if (res) {
      this.setWithExpiry("@userdata", res.data, 60000 * 60);
      //window.localStorage.setItem("@userdata", JSON.stringify(res.data));
      this.flashSuccess("Login successful!", 3000);
      this.props.history.push("/dashboard");
    } else {
      this.flashError("Failed to login. Please try again.", 3000);
    }
    this.setState({ isLoading: false });
  };

  setWithExpiry(key, value, ttl) {
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  emailValidation() {
    /*
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      */
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (regex.test(this.state.email) === false) {
      this.setState({
        emailSubmittedEmpty: true,
        errorEmail: "Email address is not valid.",
      });
      return false;
    }

    this.setState({
      emailSubmittedEmpty: false,
      errorEmail: "",
    });
    return true;
  }

  render() {
    return (
      <div className="Login">
        <div className="LoginContainer">
          <Grid item xs={4}>
            <img
              className="MainLogo"
              src="assets/images/LexisJobs_Logo.png"
              alt="LexisJobs App"
            />
            <Paper elevation={10} className="LoginPaper">
              <Typography className="SubHeader">
                Log in to your account
              </Typography>
              <Typography className="SubLabel">Email</Typography>
              <Box
                sx={{
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.email}
                  id="input-with-sx"
                  onChange={this.emailChange}
                  error={this.state.emailSubmittedEmpty}
                  helperText={
                    this.state.emailSubmittedEmpty ? this.state.errorEmail : ""
                  }
                />
              </Box>
              <Typography className="SubLabel">Password</Typography>
              <Box
                sx={{
                  paddingBottom: "0px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.password}
                  id="standard-adornment-password"
                  onChange={this.passwordChange}
                  type={this.state.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={this.state.passwordSubmittedEmpty}
                  helperText={
                    this.state.passwordSubmittedEmpty
                      ? "Please enter a password."
                      : ""
                  }
                />
              </Box>
              <Typography
                onClick={() => this.props.history.push("/forgotpassword")}
                className="ForgotPasswordText"
              >
                Forgot password?
              </Typography>
              {!this.state.isLoading && (
                <Button className="LoginButton" onClick={this.handleLogin}>
                  Log In
                </Button>
              )}
              {this.state.isLoading && (
                <LoadingButton
                  className="LoginButton"
                  loading={true}
                  loadingPosition="start"
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Log In
                </LoadingButton>
              )}
            </Paper>
          </Grid>
        </div>
        <Notifications
          setFlashSuccess={(func) => (this.flashSuccess = func)}
          setFlashError={(func) => (this.flashError = func)}
        />
      </div>
    );
  }
}
