import React, { Component } from "react";
import { Button, Grid, Box, Typography, FormControl,
    FormControlLabel, Radio, FormLabel, RadioGroup, TextField, InputAdornment, OutlinedInput,
     DialogContent, Stack, FormGroup, Checkbox, DialogActions, Link, IconButton} from "@mui/material";
import "./components.scss";
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {DUPLICATE, EDIT} from "./reward";
import Notifications from "../../../components/Notifications";

const ACTIVE = 1;
const FULLY_REDEEMED = 2;
const DRAFTS = 0;

const style = {
    modal: {
        width: 550,
        bgcolor: "white",
        boxShadow: 24,
        padding: 2,
    },
    header: {
        backgroundColor: "#F1F2F6",
        padding: 3,
    }
};

export default class EditModal extends Component {
    constructor(props) {
        super(props);
        const parsedData = {type: this.props.type, isSubmitting: false}
        for (const key in props.data) {
            parsedData[key] = props.data[key]
        }
        if (this.props.type === DUPLICATE) {
            parsedData["status"] = DRAFTS // duplicates are drafts by default
        }


        this.state = parsedData

    }
    
    fileSelectedHandler = async (e) => {
        const fileListAsArray = Array.from(e.target.files || [])
        const file_size = fileListAsArray.map(i => i.size);
        const larger = file_size.filter(i => i > 2097152) //2 MB = 2097152

        if (fileListAsArray.length + this.state.photos.length > 10){
            this.flashError("Maximum of 10 files allowed!")
        }
        else if(larger.length){
            this.flashSuccess("Maximum file size of 2MB")
        }

        else {
            const convertedFiles = await this.convertToBase64(fileListAsArray)
            this.setState({ photos: [...this.state.photos, ...convertedFiles] })
        }
        
    }

    // remove header of base64 encoding, to obtain the payload
    removeHeader = convertedFile => {
        const lastIndexOfHeader = convertedFile.indexOf(",")
        const removeHeader = convertedFile.substring(lastIndexOfHeader + 1) // +1 needed since substring includes the char at index
        return removeHeader
    }

    // helper function for conversion to base64
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    // All photos need to be converted to base64 before upload
    convertToBase64 = async (files) => {
        const base64 = []
        for (let i = 0; i < files.length; i++) {
            try {
                const convertedFile = await this.toBase64(files[i])
                const removeHeader = this.removeHeader(convertedFile)
                base64.push({file: removeHeader , name: files[i].name})
            } catch (e) {
                this.flashError("Error uploading images!" + e, 3000)
                return null;
            }
        }
        return base64
    }

    // deletion of file is local only, no rights for server deletion
    deleteFile(index) {
        this.setState({
            photos: this.state.photos.filter((_, i) => i !== index)
        });
    }

    setDate = (date) => {
        this.setState({expiry_date: date})
    }

    setDescription = (desc) => {
        this.setState({description: desc})
    }

    setTerms = (term) => {
        this.setState({terms: term})
    }

    submit = async () => {
        this.setState({isSubmitting : true})
        await this.props.submit(this.state)
        this.setState({isSubmitting : false})
    }

    render() {
        return (
            <>
            <Grid item xs={12} sx={{...style.header,  fontWeight: 'bold' }}>{`${this.state.type} Item`}</Grid>
            <DialogContent dividers={true} sx={{padding: 0}}>
            <Box sx={style.modal} >
                <Grid item xs={12} sx={{borderBottom: 2, borderColor: "#F1F2F6"}}>
                    <FormControl>
                    <FormLabel sx={{color: "#000"}}>Status</FormLabel>
                    <RadioGroup
                        value={this.state.status} // duplicates are draft by default
                        name="radio-buttons-group"
                        onChange={(event, value) => {
                            this.setState({status: value})
                        }}
                    >
                        <FormControlLabel value={DRAFTS} control={<Radio />}
                        label={<Box style={{flexDirection: 'row', display: "flex"}} >
                            <Typography>Draft</Typography><Typography sx={{fontSize: "1rem",paddingLeft: "5px", color: "#666666"}}>{`(Item will be hidden from LexisCard app)`}</Typography></Box>} />
                        <FormControlLabel value={ACTIVE} control={<Radio />}
                        label={<Box style={{flexDirection: 'row', display: "flex"}} >
                            <Typography>Active</Typography><Typography sx={{fontSize: "1rem",paddingLeft: "5px", color: "#666666"}}>{`(Item will be redeemable on LexisCard app)`}</Typography></Box>} />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Stack spacing={2}>
                    <Box sx={{marginTop: 2}}>
                        <Typography>Item Name</Typography>
                        <OutlinedInput
                        sx={{fieldset: { borderColor: "black" }}}
                        fullWidth
                        defaultValue={this.state.name}
                        onChange={event => {
                            this.setState({name: event.target.value})
                        }}
                        />
                    </Box>
                    <Box>
                        <Typography>SRP</Typography>
                        <OutlinedInput
                        sx={{fieldset: { borderColor: "black" }}}
                        fullWidth
                        type="number"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        defaultValue={this.state.srp}
                        onChange={event => {
                            this.setState({srp: event.target.value})
                        }}
                        />
                    </Box>
                    <Box>
                        <Typography>Pts Required Per Unit</Typography>
                        <OutlinedInput
                        sx={{fieldset: { borderColor: "black" }}}
                        fullWidth
                        type="number"
                        inputProps={{min: 0}}
                        defaultValue={this.state.pts_required}
                        onChange={event => {
                            this.setState({pts_required: Math.round(event.target.value)})
                        }}
                        />
                    </Box>
                    <Box>
                        <Typography>Qty</Typography>
                            <OutlinedInput
                                sx={{fieldset: { borderColor: "black" }}}
                                fullWidth
                                type="number"
                                inputProps={{min: 0}}
                                disabled={(this.state.type === EDIT || this.state.type === DUPLICATE)}
                                defaultValue={this.state.quantity}
                                onChange={event => {
                                    this.setState({quantity: Math.round(event.target.value)})
                                }}
                            />
                    </Box>
                    <Box>
                        <Typography>Photos</Typography>
                        <Typography sx={{fontSize: 12, marginTop: 1, marginBottom: 1, color: "#666666"}}>Upload up to 10 files. Supported file formats: JPG, JPEG, PNG, Max 2MB per file.</Typography>
                        {this.state.photos.length > 0 &&
                            this.state.photos.map((item, index) => {
                                return(
                                    <Box key={index} name={item.name} className="photos">
                                        <Link href={item.url ? item.url : "#"} underline="hover" target={item.url ? "_blank" : ""}>
                                            {item.name}
                                        </Link>
                                        <IconButton sx={{padding: 0, float: "right"}} onClick={() => this.deleteFile(index)}>
                                            <DeleteIcon sx={{color: "#666666"}}/>
                                        </IconButton>
                                    </Box>
                                )
                            })}
                        <Button
                        variant="outlined"
                        sx={{
                        textTransform: "none",
                        }}
                        className="createBtn"
                        //onClick={this.upload}
                        component="label"
                        >
                            Upload Photos
                            <input
                                type="file"
                                multiple onChange={async (e) => {
                                    await this.fileSelectedHandler(e)
                            }}
                                hidden
                                accept="image/png, image/jpeg, image/jpg"
                                key={this.state.photos}
                            />
                        </Button>
                    </Box>
                    <Box>
                    <Typography>Expiry Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={this.state.expiry_date}
                            onChange={this.setDate}
                            renderInput={(params) => <TextField sx={{fieldset: { borderColor: "black" }}} fullWidth {...params} />}
                        />
                        </LocalizationProvider>
                    </Box>
                    <Box>
                    <FormGroup>
                        <FormLabel>Category</FormLabel>
                        <FormControlLabel control={<Checkbox />}
                                          onChange={(event) => {
                                              this.setState({category: {...this.state.category,
                                                          featured: event.target.checked}})
                                          }}
                                          checked={this.state.category.featured}
                                          label="Featured Reward" />
                        <FormControlLabel control={<Checkbox />}
                                          onChange={(event) => {
                                              this.setState({category: {...this.state.category,
                                                          all: event.target.checked}})
                                          }}
                                          checked={this.state.category.all}
                                          label="All Rewards" />
                    </FormGroup>
                    </Box>
                    <Box>
                        <Typography>Description</Typography>
                        <ReactQuill theme="snow" value={this.state.description} onChange={this.setDescription} className="rte"/>
                    </Box>
                    <Box>
                        <Typography>Terms and Conditions</Typography>
                        <ReactQuill theme="snow" value={this.state.terms} onChange={this.setTerms} className="rte"/>
                    </Box>
                </Stack>
            </Box>
            </DialogContent>
                <DialogActions sx={{padding: 2}}>
                <Button
                        variant="text"
                        sx={{
                        textTransform: "none",
                        color: "red"
                        }}
                        className="createBtn"
                        onClick={this.props.onClose}
                    >
                        Cancel
                    </Button><Button
                        variant="contained"
                        sx={{
                        textTransform: "none",
                        }}
                        className="createBtn rwd"
                        onClick={() => this.submit()}
                        disabled={this.state.isSubmitting}
                    >
                        Save
                    </Button>
                </DialogActions>
                <Notifications
                    setFlashSuccess={(func) => (this.flashSuccess = func)}
                    setFlashError={(func) => (this.flashError = func)}
                />
                </>
        );
    }
}