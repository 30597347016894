import { initiateCall } from "./ApiHandler";
import RequestType from "./RequestType";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";
import {REDEMPTION_TABLE, REWARDS_TABLE} from "../Globals";

const lexSeekerTableMap = {
  "Full Name": "fullname",
  "Contact Number": "contactnum",
  Email: "useremail",
  "Date Joined": "createdon",
  Status: "status",
};
const lexPloyerTableMap = {
  "Company Name": "compname",
  "UEN No.": "compuen",
  "Date Joined": "createdon",
  Status: "status",
};

const lexisCardTableMap = {
  Email: "useremail",
  "Date Joined": "createdon",
  "Referral Code": "refcode",
  "Redemption Code" : "redemption_code",
  "Referred By": "refby",
  "No.s of Refers": "refby",
  Subscription: "subscription",
  Status: "status",
};

const lexisCardSalesTableMap = {
  "Customer Email": "useremail",
  "Plan": "selectedplan",
  "Plan Period": "subscription_period_status",
  "Start Date": "date_start",
  "Expiry Date": "expiry_date",
  "Platform Fee": "commission_percentage",
  "Net amount in USD": "price_in_usd",
  "Payment Type": "payment_type",
  "Platform": "platform",
  "Country Code": "country_code",
};

const activeJobTableMap = {
  Company: "companyname",
  "Job Title": "jobtitle",
  ID: "id",
  "Active From": "fdate",
  "Active To": "tdate",
  "New Application(s)": "new_applicants",
  Shortlisted: "shortlisted",
  "Not Suitable": "not_suitable",
  "Match(es)": "matches_count",
  "Total Applications": "applicant_count",
  "Total Views": "views",
};

const inactiveJobTableMap = {
  Company: "companyname",
  "Job Title": "jobtitle",
  ID: "id",
  "Deactivate On": "activated",
  "New Application(s)": "new_applicants",
  Shortlisted: "shortlisted",
  "Not Suitable": "not_suitable",
  "Match(es)": "matches_count",
  "Total Applications": "applicant_count",
  "Total Views": "views",
};

const reportedJobTableMap = {
  Company: "companyname",
  "Job Title": "jobtitle",
  ID: "id",
  "Active From": "fdate",
  "Active To": "tdate",
  "New Application(s)": "new_applicants",
  Shortlisted: "shortlisted",
  "Not Suitable": "not_suitable",
  "Match(es)": "matches_count",
  "Total Applications": "applicant_count",
  "Total Views": "views",
  "Reported On": "reporteddate",
  "Reason for Reporting": "reportedmessage",
};

const againstJobTableMap = {
  Company: "companyname",
  "Job Title": "jobtitle",
  ID: "id",
  "Active From": "fdate",
  "Active To": "tdate",
  "New Application(s)": "new_applicants",
  Shortlisted: "shortlisted",
  "Not Suitable": "not_suitable",
  "Match(es)": "matches_count",
  "Total Applications": "applicant_count",
  "Total Views": "views",
  "Reported On": "reporteddate",
  "Reason for Reporting": "reportedmessage",
  "Updated On": "againstdate",
  Remarks: "againstmessage",
};

const lexisCardRewardsTableMap = {
  "ID": "id",
  "Item Name": "name",
  "Pts Required": "pts_required",
  "Qty Redeemed": "quantity_redeemed",
  "Quantity" : "quantity",
  "Expiry Date": "expiry_date",
  "Modified On": "modifiedon"
};

const lexisCardRedemptionsTableMap = {
  "Email": "useremail",
  "Created On": "createdon",
  "Item Name": "name",
  "Modified on": "modifiedon",
  "Remarks": "remarks",
  "Reward ID": "rewards_id"
};

const dashboardLexSeekerPloyersBar = {
  Year: "year",
  Month: "month",
  Activated: "activated",
  Deactivated: "deactivated",
  Suspended: "suspended",
  Deleted: "deleted",
};

const dashboardJobPostsBar = {
  Year: "year",
  Month: "month",
  Active: "active",
  Inactive: "inactive",
  Reported: "reported",
  "Against Policy": "against",
};

const dashboardLexSeekerPloyers = {
  Activated: "activated",
  Deactivated: "deactivated",
  Suspended: "suspended",
  Deleted: "deleted",
};

const dashboardLexisJobsJPosts = {
  Active: "active",
  Inactive: "inactive",
  Reported: "reported",
  "Against Policy": "against_policy",
};

const dashboardLexisCardPP = {
  Lite: "lite",
  Pro: "pro",
  "Pro+": "pro_plus",
  "Pro Unlimited": "pro_unlimited",
};

const dashboardLexisCardCP = {
  CPro: "cpro",
  CPremium: "cpremium",
};

const dashboardLexisCardBar = {
  Year: "year",
  Month: "month",
  "Personal Plans": "personal",
  "Corporate Plans": "corporate",
};

/**
 * Retrieves mapped table headers.
 * @param {JSON} tableMap table map to use
 * @param {Array} tableHeaders headers from the table
 */
function getMappedTableHeaders(tableMap, tableHeaders) {
  let mappedTableHeaders = [];
  for (var i = 0; i < tableHeaders.length; i++) {
    const header = tableHeaders[i];
    mappedTableHeaders.push(tableMap[header]);
  }
  return mappedTableHeaders;
}

/**
 * Serves as the helper for exporting data into excel.
 * @param {string} workbookName name of workbook
 * @param {string} tableName name of the table to lookup
 */
async function exportAsExcel(
  workbookName,
  tableName,
  selectedUsers,
  search,
  status
) {
  let res = null;
  if (
    (tableName === "jobposting" && status === "2") ||
    (tableName === "jobposting" && status === "3")
  ) {
    var newA = [];
    for (var i = 0; i < selectedUsers.length; i++) {
      let t = moment(selectedUsers[i]).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
      newA.push(t);
    }

    res = await initiateCall(RequestType.POST, "/export-excel", {
      tableName: tableName,
      selectedUsers: newA,
      search: search,
      status: status,
    });
  } else if (tableName === REWARDS_TABLE) {
    res = await initiateCall(RequestType.POST, "/export-excel-rewards", {
      tableName: tableName,
      search: search,
      status: status,
    });
    console.log(res)
  } else if (tableName === REDEMPTION_TABLE) {
    res = await initiateCall(RequestType.POST, "/export-excel-redemptions", {
      tableName: tableName,
      search: search,
      status: status,
    });
    console.log(res)
  } else {
      res = await initiateCall(RequestType.POST, "/export-excel", {
        tableName: tableName,
        selectedUsers: selectedUsers,
        search: search,
        status: status,
      });
  }

  // Guard clause against faild response
  if (res == null) {
    return;
  }

  let exportData = res.data.data.rows;

  if (!exportData) {
    return;
  }

  if (
    tableName === "signuplexseeker" ||
    tableName === "signuplexployer" ||
    tableName === "namecards" &&
    status !== 'sales'
  ) {
    for (let i = 0; i < exportData.length; i++) {
      exportData[i].createdon = moment(exportData[i].createdon).format(
        "DD-MM-YYYY"
      );
    }
  }

  let worksheet = null;
  if (tableName === "signuplexseeker") {
    worksheet = getLexSeekerWorkSheet(exportData);
  } else if (tableName === "signuplexployer") {
    worksheet = getLexPloyerWorkSheet(exportData);
  } else if (tableName === "namecards") {
    if(status === 'sales'){
      for (let i = 0; i < exportData.length; i++) {
        exportData[i].payment_type = 'In App Purchase';
      }
      worksheet = getLexisCardSalesWorkSheet(exportData);
    } else {
      worksheet = getLexisCardWorkSheet(exportData);
    }
  } else if (tableName === "jobposting") {
    if (status === "0") {
      for (let i = 0; i < exportData.length; i++) {
        exportData[i].fdate = moment(exportData[i].createdon).format(
          "DD-MM-YYYY"
        );
        exportData[i].tdate = moment(exportData[i].createdon).format(
          "DD-MM-YYYY"
        );
      }
    } else if (status === "1") {
      for (let i = 0; i < exportData.length; i++) {
        exportData[i].activated = moment(exportData[i].activated).format(
          "DD-MM-YYYY"
        );
      }
    } else if (status === "2") {
      for (let i = 0; i < exportData.length; i++) {
        exportData[i].fdate = moment(exportData[i].createdon).format(
          "DD-MM-YYYY"
        );
        exportData[i].tdate = moment(exportData[i].createdon).format(
          "DD-MM-YYYY"
        );
        exportData[i].reporteddate = moment(exportData[i].reporteddate).format(
          "DD-MM-YYYY"
        );
      }
    } else if (status === "3") {
      for (let i = 0; i < exportData.length; i++) {
        exportData[i].fdate = moment(exportData[i].createdon).format(
          "DD-MM-YYYY"
        );
        exportData[i].tdate = moment(exportData[i].createdon).format(
          "DD-MM-YYYY"
        );
        exportData[i].reporteddate = moment(exportData[i].reporteddate).format(
          "DD-MM-YYYY"
        );
        exportData[i].againstdate = moment(exportData[i].againstdate).format(
          "DD-MM-YYYY"
        );
      }
    }
    worksheet = getJobPostWorkSheet(exportData, status);
  } else if (tableName === REWARDS_TABLE) {
    worksheet = getLexisCardRewardsWorkSheet(exportData);
  } else if (tableName === REDEMPTION_TABLE) {
    worksheet = getLexisCardRedemptionsWorkSheet(exportData);
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, workbookName);
  XLSX.writeFile(workbook, workbookName + ".xlsx");
}

async function exportAsDashboardExcel(workbookName, type) {
  const workbook = XLSX.utils.book_new();

  if (type === 0) {
    await writeBarToSheet(workbook, "LexSeekers");
    await writeBarToSheet(workbook, "LexPloyers");
    await writeBarToSheet(workbook, "Job Posts");

    await writePieToSheet(workbook, "signuplexseeker");
    await writePieToSheet(workbook, "signuplexployer");
    await writePieToSheet(workbook, "jobposting");
  } else if (type === 1) {
    await writeBarToSheet(workbook, "LexisCard");
    await writePieToSheet(workbook, "namecards", "pp");
    await writePieToSheet(workbook, "namecards", "cp");
  }

  XLSX.writeFile(workbook, workbookName + ".xlsx");
}
async function writeBarToSheet(workbook, name) {
  let worksheet = null;
  let res = null;

  res = await initiateCall(RequestType.POST, "/export-dashboardBarExcel", {
    type: name,
  });

  if (res == null) {
    return;
  }
  let exportData = res.data.data.rows[0].meta;

  if (name === "LexSeekers") {
    worksheet = dashboardLexSeekerPloyersBarWorkSheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "LexSeekers Bar");
  } else if (name === "LexPloyers") {
    worksheet = dashboardLexSeekerPloyersBarWorkSheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "LexPloyer Bar");
  } else if (name === "Job Posts") {
    worksheet = dashboardJobPostBarWorkSheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Job Post Bar");
  } else if (name === "LexisCard") {
    worksheet = dashboardLexisCardWorkSheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "LexisCard Bar");
  }
}

async function writePieToSheet(workbook, name, plan) {
  let worksheet = null;
  let res = null;

  res = await initiateCall(RequestType.POST, "/export-dashboardPieExcel", {
    tableName: name,
    plan: plan,
  });

  if (res == null) {
    return;
  }

  let exportData = res.data.data.rows;

  if (name === "signuplexseeker") {
    worksheet = dashboardLexSeekerPloyersWorkSheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "LexSeekers Pie");
  } else if (name === "signuplexployer") {
    worksheet = dashboardLexSeekerPloyersWorkSheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "LexPloyer Pie");
  } else if (name === "jobposting") {
    worksheet = dashboardJobPostsWorkSheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Job Post Pie");
  } else if (name === "namecards") {
    worksheet = dashboardLexisCardsWorksheet(exportData, plan);
    if (plan === "pp") {
      XLSX.utils.book_append_sheet(workbook, worksheet, "Personal Plans Pie");
    } else if (plan === "cp") {
      XLSX.utils.book_append_sheet(workbook, worksheet, "Coporate Plans Pie");
    }
  }
}

/**
 * Retrieves LexisCard Reward worksheet.
 * @param {JSON} exportData JSON data to export
 */
function getLexisCardRewardsWorkSheet(exportData) {
  const tableHeaders = [
    "ID",
    "Item Name",
    "Pts Required",
    "Qty Redeemed",
      "Quantity",
    "Expiry Date",
    "Modified on"
  ];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
      lexisCardRewardsTableMap,
      tableHeaders
  );
  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

/**
 * Retrieves LexisCard Redemption worksheet.
 * @param {JSON} exportData JSON data to export
 */
function getLexisCardRedemptionsWorkSheet(exportData) {
  const tableHeaders = [
    "Email",
    "Created On",
    "Item Name",
    "Modified on",
    "Remarks",
    "Reward ID"
  ];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
      lexisCardRedemptionsTableMap,
      tableHeaders
  );
  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

/**
 * Retrieves LexSeeker worksheet.
 * @param {JSON} exportData JSON data to export
 */
function getLexSeekerWorkSheet(exportData) {
  const tableHeaders = [
    "Full Name",
    "Contact Number",
    "Email",
    "Date Joined",
    "Status",
  ];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
    lexSeekerTableMap,
    tableHeaders
  );
  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

/**
 * Retrieves LexSeeker worksheet.
 * @param {JSON} exportData JSON data to export
 */
function getLexPloyerWorkSheet(exportData) {
  const tableHeaders = ["Company Name", "UEN No.", "Date Joined", "Status"];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
    lexPloyerTableMap,
    tableHeaders
  );
  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

function getLexisCardWorkSheet(exportData) {
  const tableHeaders = [
    "Email",
    "Date Joined",
    "Referral Code",
    "Redemption Code",
    "Referred By",
    "No.s of Refers",
    "Subscription",
    "Status",
  ];

  const mappedTableHeaders = getMappedTableHeaders(
    lexisCardTableMap,
    tableHeaders
  );

  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);

  return worksheet;
}

function getLexisCardSalesWorkSheet(exportData) {
  const tableHeaders = [
    "Customer Email",
    "Plan",
    "Plan Period",
    "Start Date",
    "Expiry Date",
    "Platform Fee",
    "Net amount in USD",
    "Payment Type",
    "Platform",
    "Country Code",
  ];

  const mappedTableHeaders = getMappedTableHeaders(
    lexisCardSalesTableMap,
    tableHeaders
  );

  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);

  return worksheet;
}

function getJobPostWorkSheet(exportData, status) {
  var worksheet;
  var tableHeaders;
  var mappedTableHeaders;
  var numColumns;

  if (status === "0") {
    tableHeaders = [
      "Company",
      "Job Title",
      "ID",
      "Active From",
      "Active To",
      "New Application(s)",
      "Shortlisted",
      "Not Suitable",
      "Match(es)",
      "Total Applications",
      "Total Views",
    ];
    mappedTableHeaders = getMappedTableHeaders(activeJobTableMap, tableHeaders);

    numColumns = tableHeaders.length;
    worksheet = XLSX.utils.json_to_sheet(exportData, {
      header: mappedTableHeaders,
    });
    worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  } else if (status === "1") {
    tableHeaders = [
      "Company",
      "Job Title",
      "ID",
      "Deactivate On",
      "New Application(s)",
      "Shortlisted",
      "Not Suitable",
      "Match(es)",
      "Total Applications",
      "Total Views",
    ];
    mappedTableHeaders = getMappedTableHeaders(
      inactiveJobTableMap,
      tableHeaders
    );

    numColumns = tableHeaders.length;
    worksheet = XLSX.utils.json_to_sheet(exportData, {
      header: mappedTableHeaders,
    });
    worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  } else if (status === "2") {
    tableHeaders = [
      "Company",
      "Job Title",
      "ID",
      "Active From",
      "Active To",
      "New Application(s)",
      "Shortlisted",
      "Not Suitable",
      "Match(es)",
      "Total Applications",
      "Total Views",
      "Reported On",
      "Reason for Reporting",
    ];
    mappedTableHeaders = getMappedTableHeaders(
      reportedJobTableMap,
      tableHeaders
    );

    numColumns = tableHeaders.length;
    worksheet = XLSX.utils.json_to_sheet(exportData, {
      header: mappedTableHeaders,
    });
    worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  } else if (status === "3") {
    tableHeaders = [
      "Company",
      "Job Title",
      "ID",
      "Active From",
      "Active To",
      "New Application(s)",
      "Shortlisted",
      "Not Suitable",
      "Match(es)",
      "Total Applications",
      "Total Views",
      "Reported On",
      "Reason for Reporting",
      "Updated On",
      "Remarks",
    ];
    mappedTableHeaders = getMappedTableHeaders(
      againstJobTableMap,
      tableHeaders
    );

    numColumns = tableHeaders.length;
    worksheet = XLSX.utils.json_to_sheet(exportData, {
      header: mappedTableHeaders,
    });
    worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  }
  return worksheet;
}

function cleanUpWorkSheet(numColumns, worksheet, tableHeaders) {
  // Filter unnecessary columns
  let range = XLSX.utils.decode_range(worksheet["!ref"]);
  range.e["c"] = numColumns - 1;
  worksheet["!ref"] = XLSX.utils.encode_range(range);

  // Update actual table headers
  let counter = 0;
  for (var C = range.s.c; C <= range.e.c; ++C) {
    var address = XLSX.utils.encode_col(C) + "1";
    if (!worksheet[address]) {
      continue;
    }
    worksheet[address].v = tableHeaders[counter];
    counter++;
  }
  return worksheet;
}

function dashboardLexSeekerPloyersWorkSheet(exportData) {
  const tableHeaders = ["Activated", "Deactivated", "Suspended", "Deleted"];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
    dashboardLexSeekerPloyers,
    tableHeaders
  );
  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

function dashboardJobPostsWorkSheet(exportData) {
  const tableHeaders = ["Active", "Inactive", "Reported", "Against Policy"];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
    dashboardLexisJobsJPosts,
    tableHeaders
  );
  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

function dashboardLexisCardsWorksheet(exportData, plan) {
  let tableHeaders;
  let mappedTableHeaders;

  if (plan === "pp") {
    tableHeaders = ["Lite", "Pro", "Pro+", "Pro Unlimited"];

    mappedTableHeaders = getMappedTableHeaders(
      dashboardLexisCardPP,
      tableHeaders
    );
  } else if (plan === "cp") {
    tableHeaders = ["CPro", "CPremium"];

    mappedTableHeaders = getMappedTableHeaders(
      dashboardLexisCardCP,
      tableHeaders
    );
  }

  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

function dashboardLexSeekerPloyersBarWorkSheet(exportData) {
  const tableHeaders = [
    "Year",
    "Month",
    "Activated",
    "Deactivated",
    "Suspended",
    "Deleted",
  ];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
    dashboardLexSeekerPloyersBar,
    tableHeaders
  );

  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

function dashboardJobPostBarWorkSheet(exportData) {
  const tableHeaders = [
    "Year",
    "Month",
    "Active",
    "Inactive",
    "Reported",
    "Against Policy",
  ];

  // Get JSON data and convert to worksheet
  const mappedTableHeaders = getMappedTableHeaders(
    dashboardJobPostsBar,
    tableHeaders
  );

  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });
  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

function dashboardLexisCardWorkSheet(exportData) {
  const tableHeaders = ["Year", "Month", "Personal Plans", "Corporate Plans"];

  const mappedTableHeaders = getMappedTableHeaders(
    dashboardLexisCardBar,
    tableHeaders
  );

  const numColumns = tableHeaders.length;
  let worksheet = XLSX.utils.json_to_sheet(exportData, {
    header: mappedTableHeaders,
  });

  worksheet = cleanUpWorkSheet(numColumns, worksheet, tableHeaders);
  return worksheet;
}

const exportEmail = async () =>{

  // Get data from database
  const res = await initiateCall(RequestType.POST, "/export-Email-Data");
  // Convert data to worksheet format
  const personalWorksheet = XLSX.utils.json_to_sheet(res.data.Personal);
  const corporateWorksheet = XLSX.utils.json_to_sheet(res.data.Corporate);

  const emails = [
    ...res.data.Summary.cUsers.map(email => ({ type: 'Corporate Users', email })),
    ...res.data.Summary.freeUsers.map(email => ({ type: 'Free Users', email })),
    ...res.data.Summary.paidUsers.map(email => ({ type: 'Paid Users', email })),
    ...res.data.Summary.cplans.map(email => ({ type: 'Corporate Plans', email })),
  ];
  const SummaryWorksheet = XLSX.utils.json_to_sheet(emails, { header: ['type', 'email'] })
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, personalWorksheet, 'Personal Plans');
  XLSX.utils.book_append_sheet(workbook, corporateWorksheet, 'Corporate Plans');
  XLSX.utils.book_append_sheet(workbook, SummaryWorksheet, 'Summary');

  // Write the workbook to a file

  XLSX.writeFile(workbook, "ExportEmail.xlsx");
}

export { exportAsExcel, exportAsDashboardExcel, exportEmail };
