import React, { Component } from "react";
import MenuDrawer from "../components/MenuDrawer";
import "typeface-inter";
import Box from "@mui/material/Box";
//import Toolbar from "@mui/material/Toolbar";
import Api from "../services/Api";

const drawerWidth = 240;

export default class BasePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: {}
    };
  }

  async componentDidMount() {
    //let userdata = window.localStorage.getItem("@userdata");
    let userdata = this.getWithExpiry("@userdata");

    if (userdata === null) {
      this.props.history.push("/");
    }

    await this.getPerm(userdata.usertype);
    this.checkRoutePerm();
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  getPerm = async (type) => {
    const res = await Api.post("/getPermission", { type: type });

    if (res.data.message === "success") {
      this.setState({ permission: res.data.data[0].permission });
    }
  };

  checkRoutePerm = async () => {
    let path = this.props.history.location.pathname
    path = path.replace("/","")
    const indexPath = path.indexOf("/") > 0 ? path.indexOf("/") : path.length
    const rootOfPath = path.substring(0, indexPath)

    if(rootOfPath !== 'lexiscardgifts' && this.state.permission?.menu?.lexiscard){
      if(!this.state.permission?.menu[rootOfPath]) {
        this.props.history.push("/dashboard");
      }
    }
    
  }

  render(Page) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <MenuDrawer
            drawerWidth={drawerWidth}
            currentDisplay={Page.props.className}
            history={this.props.history}
          />
          {/* <Header drawerWidth={drawerWidth} currentDisplay={Page.props.className} /> */}
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {Page}
          </Box>
        </Box>
      </>
    );
  }
}
