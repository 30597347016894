import React, { Component} from 'react';
import "./Footer.scss"
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";

/**
 * Provides popup notifications to show updates to users.
 */
export default class Notifications extends Component  { 
    constructor(props){
        super(props)
        this.state = {
          infoMessage: "",
          infoType: "success",
          isFlashingInfo: false
        }
        this.sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    }

    async componentDidMount () {
      this.props.setFlashSuccess(this.flashSuccess);
      this.props.setFlashError(this.flashError);
    }

    /**
     * Sets notification type to success and forwards call to flash message.
     * @param {string} message message to show
     * @param {int} duration duration to show message for
     */
    flashSuccess = async (message, duration) => {
      this.setState({infoType: "success"});
      this.flash(message, duration);
    }

    /**
     * Sets notification type to error and forwards call to flash message.
     * @param {string} message message to show
     * @param {int} duration duration to show message for
     */
    flashError = async (message, duration) => {
      this.setState({infoType: "error"});
      this.flash(message, duration);
    }

    /**
     * Flashes message for a specified duration.
     * @param {string} message message to show
     * @param {int} duration duration to show message for
     */
    flash = async (message, duration) => {
      this.setState({infoMessage: message});
      this.setState({isFlashingInfo: true});
      await this.sleep(duration);
      this.setState({isFlashingInfo: false});
    }

    render(){
        return (
          <Snackbar
            open={this.state.isFlashingInfo}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert severity={this.state.infoType}>{this.state.infoMessage}</Alert>
          </Snackbar>
        )
    }
}

