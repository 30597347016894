import React, { Component } from "react";
import { Button, Grid, Box, Typography, FormControl,
    FormControlLabel, Radio, FormLabel, RadioGroup, TextField,
     DialogContent, Stack, DialogActions} from "@mui/material";
import "./components.scss";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import 'react-quill/dist/quill.snow.css';
import {dateChecker} from "./reward";
import {COLLECTED, COLLECTION_ARRANGED, CONTACTED, NO_SHOW, REQUEST_RECEIVED} from "./redemption";


const style = {
    modal: {
        width: 500,
        bgcolor: "white",
        margin: 3,
    },
    header: {
        backgroundColor: "#F1F2F6",
        padding: 3,
    },
    datetime:{
        fieldset: { borderColor: "#686464" },
        width: 200,
        paddingBottom: 1,
        paddingTop: 1,
    }
};

export default class RedemptionModal extends Component {
    constructor(props) {
        super(props);
        const parsedData = {}
        for (const key in props.data) {
            parsedData[key] = props.data[key]
            if (key === "collectedon" && props.data[key] !== null) {
                const serverDate = new Date(props.data[key])
                const newDate = new Date(serverDate.getTime() + 8 * 60000 * 60) // MUI adds 8 hours back by itself,  though server -16 hours, only need add back 8
                parsedData[key] = newDate
                parsedData["collectedtime"] = newDate
            }
            if (key === "arrangedon" && props.data[key] !== null) {
                const serverDate = new Date(props.data[key])
                const newDate = new Date(serverDate.getTime() + 8 * 60000 * 60)
                parsedData[key] = newDate
                parsedData["arrangedtime"] = newDate
            }
        }
        this.state = parsedData
    }

    setArrDate = (date) =>{
        this.setState({arrangedon: new Date(date)})
    }
    setArrTime = (time) =>{
        this.setState({arrangedtime: new Date(time)})
    }
    setCollDate = (date) =>{
        this.setState({collectedon: new Date(date)})
    }
    setCollTime = (time) =>{
        this.setState({collectedtime: new Date(time)})
    }
    
    render() {
        return (
            <>
            <Grid item xs={12} sx={{...style.header,  fontWeight: 'bold', paddingBottom: 2 }}>Update Status</Grid>
            <DialogContent dividers={true} sx={{padding: 0}}>
            <Box sx={{...style.modal, borderBottom: 2, borderColor: "#F1F2F6", paddingBottom: 2}} >
                <Stack spacing={1}>
                    <Box style={{flexDirection: 'row', display: "flex"}} >
                        <Typography sx={{color: "#686464"}}>Email:</Typography><Typography sx={{marginLeft: 1}}>{this.state.useremail}</Typography>
                    </Box>
                    <Box style={{flexDirection: 'row', display: "flex"}} >
                        <Typography sx={{color: "#686464"}}>Item:</Typography><Typography sx={{marginLeft: 1}}>{this.state.name}</Typography>
                    </Box>
                    <Box style={{flexDirection: 'row', display: "flex"}} >
                        <Typography sx={{color: "#686464"}}>Date Requested:</Typography><Typography sx={{marginLeft: 1}}>{this.state.pretty_request_date}</Typography>
                    </Box>
                    <Box style={{flexDirection: 'row', display: "flex"}} >
                        <Typography sx={{color: "#686464"}}>Updated On:</Typography><Typography sx={{marginLeft: 1}}>{this.state.pretty_modifiedon}</Typography>
                    </Box>
                </Stack>
            </Box>
            <Box sx={style.modal}>
                <FormControl>
                <FormLabel sx={{color: "#000"}}>Status</FormLabel>
                <RadioGroup
                    value={this.state.status}
                    name="radio-buttons-group"
                    onChange={(event, value) => {
                        this.setState({status: parseInt(value)})
                    }}
                >
                    <FormControlLabel value={REQUEST_RECEIVED} control={<Radio />}
                    label={<Typography>Request Received</Typography>} />

                    <FormControlLabel value={CONTACTED} control={<Radio />}
                    label={<Typography>Contacted</Typography>} />

                    <FormControlLabel value={COLLECTION_ARRANGED} control={<Radio />}
                    label={<Typography>Collection Arranged</Typography>} />
                    {this.state.status === COLLECTION_ARRANGED ?
                    <Box display="flex">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={this.state.arrangedon}
                            onChange={this.setArrDate}
                            renderInput={(params) => <TextField sx={{...style.datetime, marginRight: 4, paddingLeft: 4}}  {...params} />}
                            />
                            <TimePicker
                                value={this.state.arrangedtime}
                                onChange={this.setArrTime}
                                renderInput={(params) => <TextField sx={style.datetime}  {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    : ""}
                    <FormControlLabel value={COLLECTED} control={<Radio />}
                    label={<Typography>Collected</Typography>} />
                    {this.state.status === COLLECTED ?
                    <Box display="flex">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={this.state.collectedon}
                            onChange={this.setCollDate}
                            renderInput={(params) => <TextField sx={{...style.datetime, marginRight: 4, paddingLeft: 4}}  {...params} />}
                            />
                            <TimePicker
                                value={this.state.collectedtime}
                                onChange={this.setCollTime}
                                renderInput={(params) => <TextField sx={style.datetime}  {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    : ""}
                    
                    <FormControlLabel value={NO_SHOW} control={<Radio />}
                    label={<Typography>No Show</Typography>} />
                    {this.state.status === NO_SHOW ?
                    <Box maxWidth={false} sx={{minWidth: 500}}>
                        <Typography>{"Remarks (optional)"}</Typography>
                        <TextField fullWidth multiline
                        minRows={4}></TextField>
                    </Box>
                    : ""}

                </RadioGroup>
                </FormControl>
            </Box>
            </DialogContent>
                <DialogActions sx={{padding: 2}}>
                <Button
                        variant="text"
                        sx={{
                        textTransform: "none",
                        color: "red"
                        }}
                        className="createBtn"
                        onClick={this.props.onClose}
                    >
                        Cancel
                    </Button><Button
                        variant="contained"
                        sx={{
                        textTransform: "none",
                        }}
                        className="createBtn"
                        onClick={() => this.props.submit(this.state)}
                    >
                        Update
                    </Button>
                </DialogActions>
                </>
        );
    }
}