import React, { Component } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default class MenuDrawer extends Component {
  constructor(props) {
    super(props);
    if (props.dropDownOpenedUser.status === 1) {
      this.state = {
        list: [
          // { value: 'activate', text: "Activate Account", status: "Activated" },
          {
            value: "deactivate",
            text: "Deactivate Account",
            status: "Unactivated",
          },
          { value: "suspended", text: "Suspend Account", status: "Suspended" },
          { value: "delete", text: "Delete Account", status: "" },
        ],
      };
    } else if (props.dropDownOpenedUser.status === 2) {
      this.state = {
        list: [
          { value: "activate", text: "Activate Account", status: "Activated" },
          { value: "delete", text: "Delete Account", status: "" },
        ],
      };
    } else if (props.dropDownOpenedUser.status === 0) {
      this.state = {
        list: [
          { value: "activate", text: "Activate Account", status: "Activated" },
          //{ value: 'deactivate', text: "Deactivate Account", status: "Unactivated" },
          { value: "suspended", text: "Suspend Account", status: "Suspended" },
          { value: "delete", text: "Delete Account", status: "" },
        ],
      };
    } else if (props.dropDownOpenedUser.status === 3) {
      this.state = {
        list: [
          { value: "activate", text: "Activate Account", status: "Activated" },
          {
            value: "deactivate",
            text: "Deactivate Account",
            status: "Unactivated",
          },
          { value: "suspended", text: "Suspend Account", status: "Suspended" },
        ],
      };
    }
  }

  render() {
    return (
      <Menu
        anchorEl={this.props.anchorEl}
        id="account-menu"
        open={this.props.open}
        onClose={this.props.onClose}
        onClick={this.props.onClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {this.state.list.map((item, key) => {
          return (
            <div>
              {item.value == "delete" ? (
                <MenuItem
                  sx={{ fontSize: 14 }}
                  style={{ color: "red" }}
                  key={key}
                  onClick={() => this.props.onSelect(item)}
                >
                  {item.text}
                </MenuItem>
              ) : (
                <MenuItem
                  sx={{ fontSize: 14 }}
                  key={key}
                  onClick={() => this.props.onSelect(item)}
                >
                  {item.text}
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    );
  }
}
