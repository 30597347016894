import BasePageComponent from "../../components/BasePageComponent";
import "./Notifications.scss";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

import NotificationIcon from "../../components/NotificationIcon";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import moment from "moment";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Api from "../../services/Api";
import Grid from "@mui/material/Grid";

export default class Notifications extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      name: null,
      type: null,
      userdata: null,
      notifications: [],
      allNotifications: [],
      loadMore: 10,
      isloading: false,
    };
  }

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    this.getNoti();
    this.setState({ isloading: true });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.notifications !== prevState.notifications) {
      this.setState({
        allNotifications: this.state.notifications,
        isloading: false,
      });
    }
  };

  getNoti = async () => {
    const res = await Api.post("/getNoti");
    if (res.data.message === "success") {
      let result = res.data.data;
      this.setState({ notifications: result });
    }
  };

  readNoti = async (id) => {
    const req = {
      id: id,
    };
    const res = await Api.post("/readNoti", req);
    if (res.data.message === "success") {
      let noti = this.state.allNotifications;
      let temp = [];
      for (let i of noti) {
        if (i.id === id) {
          i.status = 1;
        }
        temp.push(i);
      }
      this.setState({ allNotifications: temp, isloading: false });
    }

    window.localStorage.setItem(
      "@noti",
      JSON.stringify(
        this.state.allNotifications.filter((obj) => obj.status === 0).length
      )
    );

    window.location.assign("jobposts");
  };

  readAllNoti = async () => {
    const res = await Api.post("/readAllNoti");
    if (res.data.message === "success") {
      let noti = this.state.allNotifications;
      let temp = [];
      for (let i of noti) {
        i.status = 1;
        temp.push(i);
      }
      this.setState({ allNotifications: temp, isloading: false });
    }
  };

  clickNoti = async (d) => {
    await this.readNoti(d.id);
  };

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{ fontWeight: "bold" }}
                >
                  Notifications
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ margin: "auto 0" }}>
                <Typography
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{
                    color: "blue",
                    cursor: "pointer",
                    float: "right",
                    fontSize: 15,
                  }}
                  onClick={this.readAllNoti}
                >
                  Mark all as Read
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <div className="Notifications">
          <div className="container">
            {this.state.isloading ? (
              <CircularProgress className="loading" />
            ) : (
              <>
                {this.state.allNotifications.map((d, index) => {
                  if (index < this.state.loadMore) {
                    return (
                      <div
                        id={d.id}
                        onClick={() => this.clickNoti(d)}
                        className={d.status === 1 ? "card" : "card unread"}
                        key={index}
                      >
                        <div className="flex">
                          <div className="flexItemGroup">
                            <div className="">
                              <NotificationIcon />
                            </div>
                            <div className="flexItem">
                              <div className="notiTitle">
                                A Job post has been reported
                              </div>
                              <div className="notiInfo">
                                The job post "{d.jobtitle}" from "{d.compname}",
                                has been reported as "{d.message}".
                              </div>
                              <div className="time">
                                <AccessTimeOutlinedIcon />
                                <div className="timeText">
                                  <p className="time-text">
                                    {moment(d.createdon).fromNow()}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                {this.state.allNotifications.length > this.state.loadMore && (
                  <div className="viewMore">
                    <Button
                      className="viewMoreButton"
                      onClick={() => {
                        this.setState({ loadMore: this.state.loadMore + 10 });
                      }}
                    >
                      Load More
                    </Button>
                  </div>
                )}
                {this.state.allNotifications.length === 0 && (
                  <>
                    <div className="noNoti">
                      <img
                        src="./assets/images/notification@3x.png"
                        alt="No notifications illustration"
                        className="noNotiImage"
                      />
                      <p>You don’t have any notifications.</p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
