import React from "react";
import BasePageComponent from "../../components/BasePageComponent";
import "./JobPosts.scss";
import RequestType from "../../utils/RequestType";
import { initiateCall } from "../../utils/ApiHandler";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Active from "./JobPostsComponents/Active";
import Inactive from "./JobPostsComponents/Inactive";
import Reported from "./JobPostsComponents/Reported";
import AgainstPolicy from "./JobPostsComponents/AgainstPolicy";

import { exportAsExcel } from "../../utils/ExcelExporter";

export default class JobPosts extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,

      countActive: 0,
      countInactive: 0,
      countReported: 0,
      countAgainst: 0,

      color: "",

      exportExcel: [],
      searchValue: "",
    };

    this.changeTabIndex = this.changeTabIndex.bind(this);
  }

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    this.getCount();
  };

  getCount = async () => {
    const res = await initiateCall(RequestType.POST, `/getJobsCount`);

    await this.setState({
      countActive: res.data.data.active[0].count,
      countInactive: res.data.data.inactive[0].count,
      countReported: res.data.data.reported[0].count,
      countAgainst: res.data.data.against[0].count,
    });
  };

  changeTabIndex(e, newValue) {
    this.getCount();
    this.setState({ currentIndex: newValue });
    if (newValue === 2 || newValue === 3) {
      this.setState({ color: "#E5383B" });
    } else {
      this.setState({ color: "" });
    }
  }

  exportFunc = async () => {
    await exportAsExcel(
      "Jobpost",
      "jobposting",
      this.state.exportExcel,
      this.state.searchValue,
      this.state.currentIndex.toString()
    );
  };

  searchVal = (d) => {
    this.setState({ searchValue: d });
  };

  exportEx = (data) => {
    this.setState({ exportExcel: data });
  };

  refresh = (e) => {
    if (e === 1) {
      this.getCount();
    }
  };

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - 240px)`,
            ml: `240px`,
            backgroundColor: "white",
          }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  color="black"
                  variant="h6"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  Job Posts
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ margin: "auto 0" }}>
                <Typography
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{
                    color: "blue",
                    cursor: "pointer",
                    float: "right",
                    fontSize: 15,
                  }}
                  onClick={() => this.exportFunc()}
                >
                  Export as Excel
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Tabs
            onChange={this.changeTabIndex}
            value={this.state.currentIndex}
            className="tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: this.state.color,
              },
            }}
          >
            <Tab label={"Active (" + this.state.countActive + ")"} />(
            <Tab label={"Inactive (" + this.state.countInactive + ")"} />
            <Tab
              className="redTab"
              label={"Reported (" + this.state.countReported + ")"}
            />
            <Tab
              className="redTab"
              label={"Against Policy (" + this.state.countAgainst + ")"}
            />
          </Tabs>
        </AppBar>
        <div className="JobPosts">
          {this.state.currentIndex === 0 && (
            <Active
              refresh={this.refresh}
              exportExcel={this.exportEx}
              searchVal={this.searchVal}
            />
          )}
          {this.state.currentIndex === 1 && (
            <Inactive
              refresh={this.refresh}
              exportExcel={this.exportEx}
              searchVal={this.searchVal}
            />
          )}
          {this.state.currentIndex === 2 && (
            <Reported
              refresh={this.refresh}
              exportExcel={this.exportEx}
              searchVal={this.searchVal}
            />
          )}
          {this.state.currentIndex === 3 && (
            <AgainstPolicy
              refresh={this.refresh}
              exportExcel={this.exportEx}
              searchVal={this.searchVal}
            />
          )}
        </div>
      </>
    );
  }
}
