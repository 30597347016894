import React, { Component } from "react";

export default class NotificationIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {}

  render() {
    return (
      <>
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="45" height="45" rx="22.5" fill="#2964F5" />
          <path
            d="M30.5 18.5L22.5 23.5L14.5 18.5V16.5L22.5 21.5L30.5 16.5V18.5ZM30.5 14.5H14.5C13.39 14.5 12.5 15.39 12.5 16.5V28.5C12.5 29.0304 12.7107 29.5391 13.0858 29.9142C13.4609 30.2893 13.9696 30.5 14.5 30.5H30.5C31.0304 30.5 31.5391 30.2893 31.9142 29.9142C32.2893 29.5391 32.5 29.0304 32.5 28.5V16.5C32.5 15.39 31.6 14.5 30.5 14.5Z"
            fill="white"
          />
        </svg>
      </>
    );
  }
}
