import React from "react";
import BasePageComponent from "../../../components/BasePageComponent";
import "./Styles/Reported.scss";

import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";

import SearchBox from "./Components/SearchBox";
import AlertJobDialog from "./Components/AlertJobDialog";
import DropdownMenu from "./Components/DropdownReportedMenu";

import RequestType from "../../../utils/RequestType";
import { initiateCall } from "../../../utils/ApiHandler";
import Notifications from "../../../components/Notifications";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import RichTextEditor from "react-rte";

import DialogContentText from "@mui/material/DialogContentText";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    // "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    // "IMAGE_BUTTON",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading", style: "header-one" },
    //   {label: 'Heading Medium', style: 'header-two'},
    //   {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
export default class Reported extends BasePageComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
      selectedRow: [],

      searchValue: "",
      searchDisable: false,
      dataLoading: false,

      dataRows: [],
      dataTotal: 0,
      dataPage: 0,
      dataPageSize: 10,
      sorting: [{field: "companyname", sort: "asc"}],

      DialogTitle: "",
      DialogDeactivateOpen: false,
      DialogMessage: "",
      DialogSecondMessage: "",
      DialogMessageTargetEmail: "",
      DialogButtonText: "Button",
      DialogButtonColor: "primary",

      DialogEditOpen: false,
      DialogAgainstOpen: false,
      DialogActivateId: "",

      jobPostId: "",
      value: RichTextEditor.createEmptyValue(),
      qualities: [],
      allqualities: [],
      qualitieserror: "",
      skillsets: [],
      allskillsets: [],
      skillsetserror: "",
      jobTitle: "",
      errorJobTitle: "",
      jobRole: "",
      errorJobRole: "",
      yearsOfExperience: "",
      errorYearsOfExperience: "",
      country: "",
      errorCountry: "",
      state: "",
      errorState: "",
      address: "",
      errorAddress: "",
      currency: "",
      errorCurrency: "",
      minSalary: "",
      errorMinSalary: "",
      maxSalary: "",
      errorMaxSalary: "",
      salary_type: "",
      errorSalaryType: "",
      displaysalary: false,

      againstReason: "",
      errorAgainstReason: "",

      currentId: null,
      reportId: null,
    };
  }

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.searchValue, this.state.sorting);

    let userdata = this.getWithExpiry("@userdata");
    if (userdata) {
      this.setState({ currentId: userdata.id });
    }
  };

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  columns = [
    {
      field: "companyname",
      headerName: "Company",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "jobtitle",
      headerName: "Job Title",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "id",
      headerName: "ID",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.clickLink(params.value)}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "fdate",
      headerName: "Active From",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "tdate",
      headerName: "Active To",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "new_applicants",
      headerName: "New Application(s)",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "shortlisted",
      headerName: "Shortlisted",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "not_suitable",
      headerName: "Not Suitable",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "matches_count",
      headerName: "Match(es)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "applicant_count",
      headerName: "Total Applications",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "views",
      headerName: "Total Views",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "reporteddate",
      headerName: "Reported On",
      headerClassName: "dataHeader",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ color: "#E5383B" }}>
            {moment(params.value).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      field: "reportedmessage",
      headerName: "Reason for Reporting",
      headerClassName: "dataHeader",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return <div style={{ color: "#E5383B" }}>{params.value}</div>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <MoreHorizIcon
              onClick={(event) => this.dropDownMenuOpen(event, params.row)}
            />
          </div>
        );
      },
    },
  ];

  onChangeRTE = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleChangeNumber = (e) => {
    if (parseFloat(e.target.value) < 0) {
      this.setState({ [e.target.name]: 0 });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  qualitiesChange = (event, values) => {
    this.setState(
      {
        qualities: values,
      },
      () => {}
    );
  };

  skillsetsChange = (event, values) => {
    this.setState(
      {
        skillsets: values,
      },
      () => {}
    );
  };

  searchBoxChange = (event) => {
    this.setState({ searchValue: event.target.value });
    this.props.searchVal(this.state.searchValue);

    clearTimeout(this.searchFilterRef);
    this.searchFilterRef = setTimeout(
      () => this.fetchData(this.state.dataPage, this.state.dataPageSize, event.target.value, this.state.sorting),
      700
    );
  };

  setSortModel = async (sortModels) => {
    await this.setState({
      sorting: sortModels,
    });
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.searchValue, sortModels);
  };

  dropDownMenuOpen = async (event, row) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
      selectedRow: row,
    });
  };

  dropDownMenuClose = async (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: false,
    });
  };

  fetchData = async (
    page = null,
    pageSize = null,
    search = null,
    sort = null
  ) => {
    this.setState({ dataLoading: true, searchDisable: true });

    const res = await initiateCall(RequestType.POST, `/jobPostListing`, {
      tablename: "jobposting",
      page: page,
      pageSize: pageSize,
      search: search,
      sortModel: sort,
      status: 1,
      tabIndex: 2,
    });

    if (res) {
      this.setState({
        dataRows: res.data.data[0].rows,
        dataTotal: parseInt(res.data.data[1]),
      });
    } else {
      this.setState({ dataRows: [], dataTotal: 0 });
    }
    this.setState({ dataLoading: false, searchDisable: false });

    if (search != null) {
      document.getElementById("userSearchBox").focus();
    }
  };

  dropDownMenuSelected = async (item) => {
    let label = "Title";

    if (item.value === "edit") {
      label = "Edit Job Post";
      await this.setState({
        DialogButtonText: "Save",
        DialogEditOpen: true,
      });
      this.getData(this.state.selectedRow.id);
      this.getListing();
    } else if (item.value === "deactivate") {
      label = "Deactivate Job Post?";
      await this.setState({
        DialogButtonText: "Deactivate",
        DialogDeactivateOpen: true,
        DialogActivateId: this.state.selectedRow.id,
        DialogMessage:
          "Once this job post has been deactivated, it will no longer be accessible to Lexeekers.",
        DialogSecondMessage:
          "You may reactivate this job post again by heading to",
        DialogMessageTargetEmail:
          "'Inactive' Tab > Select job post > Reactivate",
      });
    } else if (item.value === "oktogo") {
      label = "Ok to Go?";
      this.setState({
        DialogButtonText: "Submit",
        DialogDeactivateOpen: true,
        DialogActivateId: this.state.selectedRow.id,
        DialogMessage:
          "This is to confirm that there is nothing wrong with the job post. No further action is needed.",
        DialogSecondMessage: "",
        DialogMessageTargetEmail: "",
      });
    } else if (item.value === "againstpolicy") {
      label = "Against Policy?";
      this.setState({
        DialogButtonText: "Submit",
        DialogAgainstOpen: true,
        DialogActivateId: this.state.selectedRow.id,
        reportId: this.state.selectedRow.jid,
        DialogMessage:
          "Job post will be deactivated and lexployer will be notified that their job post has been reported. They will be required to make amendments accordingly.",
        DialogSecondMessage: "",
        DialogMessageTargetEmail: "",
      });
    }

    this.setState({
      DialogButtonColor: item.value !== "deactivate" ? "primary" : "error",
      DialogTitle: label,
    });
  };

  alertDialogClose = (event) => {
    this.setState({
      DialogDeactivateOpen: event.target.value,
      DialogEditOpen: event.target.value,
      DialogAgainstOpen: event.target.value,
      againstReason: "",
      errorAgainstReason: "",
    });
  };

  getData = async (id) => {
    const res = await initiateCall(RequestType.POST, "/getjobpostbyid", {
      id: id,
    });
    let d = res.data.data;

    await this.setState({
      jobPostId: id,
      jobTitle: d.jobtitle,
      jobRole: d.role,
      yearsOfExperience: d.years,
      country: d.country,
      state: d.state,
      address: d.address,
      currency: d.currency,
      minSalary: d.salarymin,
      maxSalary: d.salarymax,
      salary_type: d.salary_type,
      displaysalary: d.displaysalary,
      value: RichTextEditor.createValueFromString(d.jobdesc, "html"),
    });

    if (d.skillsets !== null) {
      this.setState({ qualities: d.skillsets });
    }

    if (d.qualities !== null) {
      this.setState({ skillsets: d.qualities });
    } else {
      this.setState({ skillsets: [] });
    }
  };

  getListing = async () => {
    const res = await initiateCall(RequestType.GET, "/qualitiesListing");

    if (res.data.message === "success") {
      this.setState({
        allqualities: res.data.data.filter(function (e) {
          return e.type === "qualities";
        })[0]["list"],
        allskillsets: res.data.data.filter(function (e) {
          return e.type === "skillsets";
        })[0]["list"],
      });
    }
  };

  clickLink = async (value) => {
    let url = "";
    if (window.location.href.includes("dev-cms")) {
      url =
        "https://dev-web.lexisjobs.asia/LexployerViewJobPosting?id=" + value;
    } else if (window.location.href.includes("localhost")) {
      url =
        "https://dev-web.lexisjobs.asia/LexployerViewJobPosting?id=" + value;
    } else {
      url = "https://lexisjobs.asia/LexployerViewJobPosting?id=" + value;
    }

    window.open(url, "_newtab");
  };

  actionJobPost = async (event) => {
    const updateData = {
      tablename: "jobposting",
      jobId: this.state.DialogActivateId,
      idBy: this.state.currentId,
      againstReason: this.state.againstReason,
      reportId: this.state.reportId,
    };

    switch (this.state.DialogTitle) {
      case "Edit Job Post":
        this.submitPost();
        break;
      case "Deactivate Job Post?":
        let respondsDeac = await initiateCall(
          RequestType.POST,
          `/deactivateJobPost`,
          updateData
        );

        if (respondsDeac) {
          this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
        } else {
          this.flashError(this.state.DialogTitle + " failed!", 3000);
        }

        this.fetchData(
          this.state.dataPage,
          this.state.dataPageSize,
          this.state.searchValue,
            this.state.sorting
        );
        this.props.refresh(1);

        break;
      case "Ok to Go?":
        let respondsReac = await initiateCall(
          RequestType.POST,
          `/clearReportedJobPost`,
          updateData
        );

        if (respondsReac) {
          this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
        } else {
          this.flashError(this.state.DialogTitle + " failed!", 3000);
        }

        this.fetchData(
          this.state.dataPage,
          this.state.dataPageSize,
          this.state.searchValue,
            this.state.sorting
        );
        this.props.refresh(1);

        break;
      case "Against Policy?":
        let checks = this.checkEmpty(
          this.state.againstReason,
          "errorAgainstReason",
          "Please enter reason."
        );

        if (checks) {
          let againstReac = await initiateCall(
            RequestType.POST,
            `/againstJobPost`,
            updateData
          );

          if (againstReac) {
            this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
          } else {
            this.flashError(this.state.DialogTitle + " failed!", 3000);
          }

          this.fetchData(
            this.state.dataPage,
            this.state.dataPageSize,
            this.state.searchValue,
              this.state.sorting
          );
          this.props.refresh(1);
          this.setState({
            DialogAgainstOpen: false,
            againstReason: "",
            errorAgainstReason: "",
          });
        }
        break;
      default:
        break;
    }
  };

  checkEmpty = (state, error, errorMsg) => {
    if (!state) {
      if (
        error === "errorYearsOfExperience" ||
        error === "errorMinSalary" ||
        error === "errorMaxSalary"
      ) {
        if (state !== 0) {
          this.setState({ [error]: errorMsg });
          // return false;
        } else {
          this.setState({ [error]: "" });
          return true;
        }
      }

      this.setState({ [error]: errorMsg });
      return false;
    }
    this.setState({ [error]: "" });
    return true;
  };

  checkDigitNumber = (state, error) => {
    const regex = /^\d{0,7}(\.\d{1,2})?$/gm;
    if (regex.test(parseFloat(state)) === false) {
      this.setState({
        [error]: "Please input a number with no more than 7 digits.",
      });
      return false;
    } else if (parseFloat(state) < 1) {
      this.setState({
        [error]: "Please input a valid number.",
      });
      return false;
    }
    this.setState({ [error]: "" });
    return true;
  };

  checkMinMaxSalary = () => {
    if (parseFloat(this.state.minSalary) > parseFloat(this.state.maxSalary)) {
      this.setState({
        errorMinSalary: "Min. salary must be lesser than max. salary",
      });
      return false;
    }
    this.setState({ errorMinSalary: "" });
    return true;
  };

  checkEmptyRTE = () => {
    let isEmpty = !this.state.value
      .getEditorState()
      .getCurrentContent()
      .hasText();
    if (isEmpty) {
      this.setState({ errorRTE: "Please enter job description." });
      return false;
    }
    this.setState({ errorRTE: "" });
    return true;
  };

  qualitiesCheck(values) {
    if (values.length === 0) {
      this.setState({ qualitieserror: "Please select at least one quality." });
      return false;
    } else {
      this.setState({ qualitieserror: "" });
      return true;
    }
  }

  skillsetsCheck(values) {
    if (values.length === 0) {
      this.setState({ skillsetserror: "Please select at least one skillset." });
      return false;
    } else {
      this.setState({ skillsetserror: "" });
      return true;
    }
  }

  salaryTypeValidation() {
    if (this.state.salary_type === "") {
      this.setState({ errorSalaryType: "Please select a salary type." });
      return false;
    }
    this.setState({ errorSalaryType: "" });
    return true;
  }

  submitPost = async () => {
    this.setState({ isSaving: true });

    this.checkEmpty(
      this.state.jobTitle,
      "errorJobTitle",
      "Please enter job title."
    );

    this.checkEmpty(
      this.state.jobRole,
      "errorJobRole",
      "Please select job role."
    );

    this.checkEmpty(
      this.state.yearsOfExperience,
      "errorYearsOfExperience",
      "Please enter years of experience."
    );

    this.checkEmpty(
      this.state.country,
      "errorCountry",
      "Please select country."
    );

    this.checkEmpty(this.state.state, "errorState", "Please select location.");

    this.checkEmpty(
      this.state.address,
      "errorAddress",
      "Please enter address."
    );

    this.salaryTypeValidation();
    this.checkEmpty(
      this.state.currency,
      "errorCurrency",
      "Please enter currency"
    );
    this.checkEmpty(
      this.state.minSalary,
      "errorMinSalary",
      "Please enter min. salary."
    );
    if (
      this.checkEmpty(
        this.state.minSalary,
        "errorMinSalary",
        "Please enter min. salary."
      )
    ) {
      this.checkDigitNumber(this.state.minSalary, "errorMinSalary");
      if (this.checkDigitNumber(this.state.minSalary, "errorMinSalary")) {
        this.checkMinMaxSalary();
      }
    }
    this.checkEmpty(
      this.state.maxSalary,
      "errorMaxSalary",
      "Please enter max. salary."
    );
    if (
      this.checkEmpty(
        this.state.maxSalary,
        "errorMaxSalary",
        "Please enter max. salary."
      )
    ) {
      this.checkDigitNumber(this.state.maxSalary, "errorMaxSalary");
    }

    this.checkEmptyRTE();
    this.qualitiesCheck(this.state.qualities);
    this.skillsetsCheck(this.state.skillsets);

    setTimeout(() => {
      if (
        this.state.errorJobTitle === "" &&
        this.state.errorJobRole === "" &&
        this.state.errorYearsOfExperience === "" &&
        this.state.errorCountry === "" &&
        this.state.errorState === "" &&
        this.state.errorAddress === "" &&
        this.state.errorSalaryType === "" &&
        this.state.errorCurrency === "" &&
        this.state.errorMinSalary === "" &&
        this.state.errorMaxSalary === "" &&
        this.state.qualitieserror === "" &&
        this.state.errorRTE === ""
      ) {
      } else {
        return;
      }
      this.sendPost();
    }, 50);
  };

  sendPost = async () => {
    let data = {
      id: this.state.jobPostId,
      jobtitle: this.state.jobTitle,
      jobrole: this.state.jobRole,
      years: this.state.yearsOfExperience,
      country: this.state.country,
      state: this.state.state,
      address: this.state.address,
      salary_type: this.state.salary_type,
      currency: this.state.currency,
      salaryMin: this.state.minSalary,
      salaryMax: this.state.maxSalary,
      displaysalary: this.state.displaysalary,
      jobdesc: this.state.value.toString("html"),
      skillsets: this.state.qualities,
      qualities: this.state.skillsets,
    };

    const res = await initiateCall(RequestType.POST, `/updatejobpost`, data);

    if (res.data.message === "success") {
      this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
      this.endEdit();
    } else {
      this.flashError(this.state.DialogTitle + " failed!", 3000);
    }
  };

  endEdit = () => {
    this.setState({ DialogEditOpen: false });
    this.fetchData(
      this.state.dataPage,
      this.state.dataPageSize,
      this.state.searchValue,
        this.state.sorting
    );
  };

  render() {
    return (
      <div className="Reported">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SearchBox
              handleTextChange={this.searchBoxChange}
              disabled={this.state.searchDisable}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <DataGrid
              sx={{ bgcolor: "white" }}
              autoHeight={true}
              loading={this.state.dataLoading}
              rows={this.state.dataRows}
              getRowId={(row) => row.reporteddate}
              columns={this.columns}
              rowCount={this.state.dataTotal}
              pageSize={this.state.dataPageSize}
              page={this.state.dataPage}
              rowsPerPageOptions={[10, 50, 100]}
              checkboxSelection
              onSelectionModelChange={(newSelection) => {
                this.props.exportExcel(newSelection);
              }}
              sortModel={this.state.sorting}
              onSortModelChange={(newSortModel) => {
                this.setSortModel(newSortModel);
              }}
              paginationMode="server"
              onPageChange={(newPage) => {
                this.setState({ dataPage: newPage });
                this.fetchData(newPage, this.state.dataPageSize, this.state.searchValue, this.state.sorting);
              }}
              onPageSizeChange={(newPageSize) => {
                this.setState({ dataPageSize: newPageSize, dataPage: 0 });
                this.fetchData(0, newPageSize, this.state.searchValue, this.state.sorting);
              }}
              disableColumnSelector
              disableSelectionOnClick
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No results found
                  </Stack>
                ),
              }}
            ></DataGrid>
          </Grid>
        </Grid>

        <DropdownMenu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.dropDownMenuClose}
          onClick={this.dropDownMenuClose}
          onSelect={this.dropDownMenuSelected}
          dataFrom={this.state.selectedRow}
        />

        <AlertJobDialog
          title={this.state.DialogTitle}
          message={this.state.DialogMessage}
          secondMessage={this.state.DialogSecondMessage}
          messageBold={this.state.DialogMessageTargetEmail}
          buttonText={this.state.DialogButtonText}
          buttonColor={this.state.DialogButtonColor}
          DialogOpen={this.state.DialogDeactivateOpen}
          handleDialogClose={this.alertDialogClose}
          handleUserUpdate={(event) => {
            this.actionJobPost(event);
            this.alertDialogClose(event);
          }}
        />

        <Dialog
          id="AlertJobAgainstDialog"
          open={this.state.DialogAgainstOpen}
          onClose={this.alertDialogClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          handleUserUpdate={(event) => {
            this.actionJobPost(event);
            this.alertDialogClose(event);
          }}
        >
          <DialogTitle className="DialogTitle" id="scroll-dialog-title">
            {this.state.DialogTitle}
          </DialogTitle>

          <DialogContent dividers={true}>
            <div className="form">
              <div className="form-group">
                <label htmlFor="againstReason">Please state reason</label>
                <FormControl
                  className="form-control inputRoleExperience"
                  variant="outlined"
                >
                  <OutlinedInput
                    id="againstReason"
                    name="againstReason"
                    type="text"
                    className="inputarea"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows="3"
                    value={this.state.againstReason}
                    onChange={this.handleChange}
                  />
                  <div className="errorMessage">
                    {this.state.errorAgainstReason}
                  </div>
                </FormControl>
              </div>
            </div>
            <DialogContentText
              className="DialogContentText"
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              {this.state.DialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="Button"
              id="cancelBtn"
              onClick={this.alertDialogClose}
            >
              Cancel
            </Button>
            <Button
              className="Button"
              variant="contained"
              color={this.state.DialogButtonColor}
              onClick={this.actionJobPost}
            >
              {this.state.DialogButtonText}
            </Button>
          </DialogActions>
        </Dialog>

        <Notifications
          setFlashSuccess={(func) => (this.flashSuccess = func)}
          setFlashError={(func) => (this.flashError = func)}
        />

        <Dialog
          id="AlertJobEditDialog"
          open={this.state.DialogEditOpen}
          onClose={this.alertDialogClose}
          handleUserUpdate={(event) => {
            this.actionJobPost(event);
            this.alertDialogClose(event);
          }}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle className="DialogTitle" id="scroll-dialog-title">
            {this.state.DialogTitle}
          </DialogTitle>

          <DialogContent dividers={true}>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Job Details</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <label htmlFor="jobTitle">Title</label>
                    <FormControl className="form-control" variant="outlined">
                      <OutlinedInput
                        id="jobTitle"
                        name="jobTitle"
                        type="text"
                        className="input"
                        variant="outlined"
                        fullWidth
                        value={this.state.jobTitle}
                        onChange={this.handleChange}
                      />
                      <div className="errorMessage">
                        {this.state.errorJobTitle}
                      </div>
                    </FormControl>
                  </div>

                  <Grid container>
                    <Grid item xs={12} md={5.5}>
                      <label htmlFor="jobRole">Role</label>
                      <FormControl
                        fullWidth
                        size="small"
                        className="select inputRoleExperience mobileRole"
                      >
                        <Select
                          className="select-input"
                          labelId="jobRole"
                          id="jobRole"
                          name="jobRole"
                          value={this.state.jobRole}
                          onChange={this.handleChange}
                        >
                          <MenuItem value="Permanent">Permanent</MenuItem>
                          <MenuItem value="Full-time">Full-time</MenuItem>
                          <MenuItem value="Part-time">Part-time</MenuItem>
                          <MenuItem value="Flexible">Flexible</MenuItem>
                          <MenuItem value="Contract">Contract</MenuItem>
                          <MenuItem value="Temporary">Temporary</MenuItem>
                          <MenuItem value="Internship">Internship</MenuItem>
                        </Select>
                        <div className="errorMessage">
                          {this.state.errorJobRole}
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1}></Grid>
                    <Grid item xs={12} md={5.5}>
                      <label htmlFor="yearsOfExperience">
                        Years of Experience
                      </label>
                      <FormControl
                        className="form-control inputRoleExperience"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="yearsOfExperience"
                          name="yearsOfExperience"
                          type="number"
                          className="input"
                          inputprops={{ inputProps: { min: 0 } }}
                          variant="outlined"
                          fullWidth
                          onKeyPress={(event) => {
                            if (event.key === "-" || event.key === "+") {
                              event.preventDefault();
                            }
                          }}
                          value={this.state.yearsOfExperience}
                          onChange={this.handleChangeNumber}
                          onWheel={(event) => {
                            event.target.blur();
                          }}
                        />
                        <div className="errorMessage">
                          {this.state.errorYearsOfExperience}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Location</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <Grid container>
                      <Grid item xs={12} md={5.5}>
                        <label htmlFor="country">Country</label>
                        <FormControl fullWidth size="small" className="select">
                          <Select
                            className="select-input"
                            labelId="country"
                            id="country"
                            name="country"
                            onChange={this.handleChange}
                            value={this.state.country}
                          >
                            <MenuItem value="Singapore">Singapore</MenuItem>
                            <MenuItem value="Malaysia">Malaysia</MenuItem>
                            <MenuItem value="Indonesia">Indonesia</MenuItem>
                          </Select>
                          <div className="errorMessage">
                            {this.state.errorCountry}
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={12} md={5.5}>
                        <label htmlFor="state">Location</label>
                        <FormControl fullWidth size="small" className="select">
                          <Select
                            className="select-input"
                            labelId="state"
                            id="state"
                            name="state"
                            onChange={this.handleChange}
                            value={this.state.state}
                          >
                            <MenuItem value="All Locations">
                              All Locations
                            </MenuItem>
                            <MenuItem value="Central">Central</MenuItem>
                            <MenuItem value="North">North</MenuItem>
                            <MenuItem value="South">South</MenuItem>
                            <MenuItem value="East">East</MenuItem>
                            <MenuItem value="West">West</MenuItem>
                            <MenuItem value="North-east">North-east</MenuItem>
                            <MenuItem value="North-west">North-west</MenuItem>
                            <MenuItem value="South-east">South-east</MenuItem>
                            <MenuItem value="South-west">South-west</MenuItem>
                          </Select>
                          <div className="errorMessage">
                            {this.state.errorState}
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <FormControl
                      className="form-control inputRoleExperience"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="address"
                        name="address"
                        type="text"
                        className="inputarea"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows="3"
                        value={this.state.address}
                        onChange={this.handleChange}
                      />
                      <div className="errorMessage">
                        {this.state.errorAddress}
                      </div>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Salary Range</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <RadioGroup
                      row
                      // aria-labelledby="demo-row-radio-buttons-group-label"
                      value={this.state.salary_type}
                      name="salary_type"
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="Monthly"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Hourly"
                        control={<Radio />}
                        label="Hourly"
                      />
                    </RadioGroup>
                    <div className="errorMessage">
                      {this.state.errorSalaryType}
                    </div>
                  </div>
                </div>
                <div className="form currency">
                  <div className="form-group">
                    <Grid container>
                      <Grid item xs={12} md={3.3}>
                        <label htmlFor="currency">Currency</label>
                        <FormControl fullWidth size="small" className="select">
                          <Select
                            labelId="currency"
                            id="currency"
                            name="currency"
                            onChange={this.handleChange}
                            value={this.state.currency}
                          >
                            <MenuItem value="SGD">SGD</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                          </Select>
                          <div className="errorMessage">
                            {this.state.errorCurrency}
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={12} md={3.3}>
                        <label htmlFor="minSalary">Minimum</label>
                        <FormControl
                          className="form-control"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="minSalary"
                            name="minSalary"
                            type="number"
                            className="input"
                            variant="outlined"
                            fullWidth
                            onKeyPress={(event) => {
                              if (event.key === "-" || event.key === "+") {
                                event.preventDefault();
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={this.state.minSalary}
                            onChange={this.handleChangeNumber}
                            onWheel={(event) => {
                              event.target.blur();
                            }}
                          />
                          <div className="errorMessage">
                            {this.state.errorMinSalary}
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={12} md={3.3}>
                        <label htmlFor="maxSalary">Maximum</label>
                        <FormControl
                          className="form-control"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="maxSalary"
                            name="maxSalary"
                            type="number"
                            className="input"
                            variant="outlined"
                            fullWidth
                            onKeyPress={(event) => {
                              if (event.key === "-" || event.key === "+") {
                                event.preventDefault();
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={this.state.maxSalary}
                            onChange={this.handleChangeNumber}
                            onWheel={(event) => {
                              event.target.blur();
                            }}
                          />
                          <div className="errorMessage">
                            {this.state.errorMaxSalary}
                          </div>
                        </FormControl>
                      </Grid>
                      <FormControl className="checkBox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.displaysalary}
                              name="displaysalary"
                              onChange={this.handleCheckBox}
                            />
                          }
                          label="I do not wish to display salary in job posting."
                          className="checkBoxLabel"
                        />
                      </FormControl>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Job Description</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <RichTextEditor
                      value={this.state.value}
                      onChange={this.onChangeRTE}
                      editorClassName="rte-editor"
                      toolbarStyle={{
                        background: "#f1f2f6",
                        margin: 0,
                        paddingLeft: 16,
                      }}
                      toolbarConfig={toolbarConfig}
                    />
                    <div className="errorMessage">{this.state.errorRTE}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-card">
              <div className="card-body">
                <div className="form">
                  <div className="form-group">
                    <label htmlFor="qualities" className="qualities">
                      Share the qualities that an ideal candidate should possess
                    </label>
                    <Typography className="DialogContentText">
                      You may add as many as you like.
                    </Typography>
                    <Autocomplete
                      multiple
                      id="qualities"
                      className="autoQualities"
                      options={this.state.allqualities}
                      disableCloseOnSelect
                      onChange={this.qualitiesChange}
                      value={this.state.qualities}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type Something..."
                        />
                      )}
                    />
                    <div id="qualitiesError" className="errorMessage">
                      {this.state.qualitieserror}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-card">
              <div className="card-body">
                <div className="form">
                  <div className="form-group">
                    <label htmlFor="skillsets" className="skillsets">
                      Share the skillsets that an ideal candidate should possess
                    </label>
                    <Typography className="DialogContentText">
                      You may add as many as you like.
                    </Typography>
                    <Autocomplete
                      multiple
                      id="skillsets"
                      className="autoSkillsets"
                      options={this.state.allskillsets}
                      disableCloseOnSelect
                      onChange={this.skillsetsChange}
                      value={this.state.skillsets}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type Something..."
                        />
                      )}
                    />
                    <div id="skillsetError" className="errorMessage">
                      {this.state.skillsetserror}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="Button"
              id="cancelBtn"
              onClick={this.alertDialogClose}
            >
              Cancel
            </Button>
            <Button
              className="Button"
              variant="contained"
              color={this.state.DialogButtonColor}
              onClick={this.actionJobPost}
            >
              {this.state.DialogButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
