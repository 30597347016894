import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import "./Styles/JobPost.scss";
import Notifications from "../../../components/Notifications";
import RequestType from "../../../utils/RequestType";
import { initiateCall } from "../../../utils/ApiHandler";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ActivePost from "./JobPostComponents/ActivePost";
import moment from "moment";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Autocomplete from "@mui/material/Autocomplete";
import DialogContentText from "@mui/material/DialogContentText";

import RichTextEditor from "react-rte";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    // "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    // "IMAGE_BUTTON",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading", style: "header-one" },
    //   {label: 'Heading Medium', style: 'header-two'},
    //   {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const ListGrid = styled(
  Grid,
  {}
)({
  padding: "3px",
  paddingLeft: "10px",
  paddingRight: "10px",
  borderRadius: "20px",
  fontSize: "12px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
});

const CustomGrid = styled(
  Grid,
  {}
)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
});

const CustomContainerGrid = styled(
  Grid,
  {}
)({
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#d5d8de",
  padding: "15px",
  width: "100%",
  borderRadius: "5px",
});

const statusList = ["Active", "Inactive", "Reported", "Against Policy"];

const colorList = {
  primary: {
    color: "blue",
    borderWidth: "1px",
    borderColor: "blue",
    borderStyle: "solid",
    backgroundColor: "white",
    cursor: "pointer",
  },
  warning: {
    color: "red",
    borderWidth: "1px",
    borderColor: "red",
    borderStyle: "solid",
    backgroundColor: "white",
    cursor: "pointer",
  },
};

export default class JobPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      dialogHeader: "",
      dialogContent: "",
      buttonText: "",
      buttonStyle: "",
      openDropDown: false,
      openDialog: false,
      openEditDialog: false,
      anchorEl: null,
      selectedUser: [],
      showDialogTextInput: false,
      searchValue: "",
      searchDisable: false,
      dataLoading: false,

      dataRows: [],
      dataTotal: 0,
      dataPage: 0,
      dataPageSize: 10,
      sorting: [],
      userid: "",
      Activerows: [],
      Inactiverows: [],
      Reportedrows: [],
      AgaintsPolicyrows: [],
      jobTitle: "",
      errorJobTitle: "",
      jobRole: "",
      errorJobRole: "",
      yearsOfExperience: "",
      errorYearsOfExperience: "",
      country: "",
      errorCountry: "",
      state: "",
      errorState: "",
      address: "",
      errorAddress: "",
      currency: "",
      errorCurrency: "",
      minSalary: "",
      errorMinSalary: "",
      maxSalary: "",
      errorMaxSalary: "",
      salary_type: "",
      errorSalaryType: "",
      displaysalary: false,
      jobPostId: "",
      value: RichTextEditor.createEmptyValue(),
      qualities: [],
      allqualities: [],
      qualitieserror: "",
      skillsets: [],
      allskillsets: [],
      skillsetserror: "",
      againstReason: "",
      errorAgainstReason: "",

      currentId: null,
      countActive: 0,
      countInactive: 0,
      countReported: 0,
      countAgainst: 0,
    };
  }

  Activecolumns = [
    { field: "jobtitle", headerName: "Job Title", sortable: false, width: 170 },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 350,
      renderCell: (cellValues) => {
        return (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.clickLink(cellValues.value)}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "fdate",
      headerName: "Active From",
      sortable: false,
      width: 150,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "tdate",
      headerName: "Active To",
      sortable: false,
      width: 150,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "new_applicants",
      headerName: "New Application's",
      sortable: false,
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "shortlisted",
      headerName: "Shortlisted",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "not_suitable",
      headerName: "Not Suitable",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "matches_count",
      headerName: "Match(es)",
      sortable: true,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "applicant_count",
      headerName: "Total Application",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "views",
      headerName: "Total Views",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <MoreHorizIcon
            onClick={(event) => this.dropDownMenuOpen(event, params.row)}
          />
        );
      },
    },
  ];

  //   Activerows = [
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000},
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000},
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000},
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000},
  //  ]

  Inactivecolumns = [
    { field: "jobtitle", headerName: "Job Title", sortable: true, width: 150 },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.clickLink(cellValues.value)}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "activated",
      headerName: "Deactivated On",
      width: 150,
      renderCell: (params) => {
        if (params.value === null) {
          return <div>No Date</div>;
        } else {
          return <div>{moment(params.value).format("DD-MM-YYYY")}</div>;
        }
      },
    },
    {
      field: "new_applicants",
      headerName: "New Application(s)",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "shortlisted",
      headerName: "Shortlisted",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "not_suitable",
      headerName: "Not Suitable",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "matches_count",
      headerName: "Match(es)",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "applicant_count",
      headerName: "Total Applications",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "views",
      headerName: "Total Views",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => {
        return (
          <MoreHorizIcon
            onClick={(event) => this.dropDownMenuOpen(event, params.row)}
          />
        );
      },
    },
  ];

  // Inactiverows=[
  // //     {jobtitle:"FullStack Developer",id:"02908e430948",deactivatedon:"02-09-2022",newapplications:23,shortlisted:20,notsuitable:9,matches:23,totalapplications:45,totalviews:203}
  //  ]

  Reportedcolumns = [
    { field: "jobtitle", headerName: "Job Title", sortable: true, width: 150 },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.clickLink(cellValues.value)}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "fdate",
      headerName: "Active From ",
      width: 150,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "tdate",
      headerName: "Active To",
      width: 150,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "new_applicants",
      headerName: "New Application(s)",
      headerAlign: "center",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "shortlisted",
      headerName: "Shortlisted",
      headerAlign: "center",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "not_suitable",
      headerName: "Not Suitable",
      headerAlign: "center",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "matches_count",
      headerName: "Matche(s)",
      headerAlign: "center",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "applicant_count",
      headerName: "Total Applications",
      headerAlign: "center",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "views",
      headerName: "Total Views",
      headerAlign: "center",
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "reporteddate",
      headerName: "Reported On",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 150,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
      renderCell: (cellValues) => {
        return <div className="highlightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "reportedmessage",
      headerName: "Reason for Reporting",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 200,
      renderCell: (cellValues) => {
        return <div className="highlightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => {
        return (
          <MoreHorizIcon
            onClick={(event) => this.dropDownMenuOpen(event, params.row)}
          />
        );
      },
    },
  ];

  // Reportedrows=[
  // //     {jobtitle:"FullStack Developer",id:"02908e430948",activefrom:"02-09-022",activeto:"03-30-2022",newapplications:23,shortlisted:20,notsuitable:9,matches:23,totalapplications:45,totalviews:203,reportedon:"02 22 2022",reasonofreporting:"Reported as a spam"}
  // ]

  AgaintPolicycolumns = [
    { field: "jobtitle", headerName: "Job Title", sortable: false, width: 170 },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 350,
      renderCell: (cellValues) => {
        return (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.clickLink(cellValues.value)}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "fdate",
      headerName: "Active From",
      sortable: false,
      width: 150,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "tdate",
      headerName: "Active To",
      sortable: false,
      width: 150,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "new_applicants",
      headerName: "New Application's",
      sortable: false,
      width: 150,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "shortlisted",
      headerName: "Shortlisted",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "not_suitable",
      headerName: "Not Suitable",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "matches_count",
      headerName: "Match(es)",
      sortable: true,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "applicant_count",
      headerName: "Total Application",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "views",
      headerName: "Total Views",
      sortable: false,
      width: 120,
      renderCell: (cellValues) => {
        return <div className="alignrightrows">{cellValues.value}</div>;
      },
    },
    {
      field: "reporteddate",
      headerName: "Reported On",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 120,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
      renderCell: (cellValues) => {
        return (
          <div className="super-app-theme--header">{cellValues.value}</div>
        );
      },
    },
    {
      field: "reportedmessage",
      headerName: "Reasons of Reporting",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div className="super-app-theme--header">{cellValues.value}</div>
        );
      },
    },
    {
      field: "againstdate",
      headerName: "Updated On",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 120,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
      renderCell: (cellValues) => {
        return (
          <div className="super-app-theme--header">{cellValues.value}</div>
        );
      },
    },
    {
      field: "againstmessage",
      headerName: "Remarks",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 350,
      renderCell: (cellValues) => {
        return (
          <div className="super-app-theme--header">{cellValues.value}</div>
        );
      },
    },

    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <MoreHorizIcon
            onClick={(event) => this.dropDownMenuOpen(event, params.row)}
          />
        );
      },
    },
  ];

  //   AgaintsPolicyrows = [
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000,reportedon:"02-02-2022",reasonofreporting:"Reported as spam/scam",updatedon:"02-02-2022",remarks:"lorem impusum this is a static text"},
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000,reportedon:"02-02-2022",reasonofreporting:"Reported as spam/scam",updatedon:"02-02-2022",remarks:"lorem impusum this is a static text"},
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000,reportedon:"02-02-2022",reasonofreporting:"Reported as spam/scam",updatedon:"02-02-2022",remarks:"lorem impusum this is a static text"},
  // //     {jobtitle:"FullStack Developer",id:"283989243832473478",activefrom:"02-09-2022",activeto:"04-29-2022",newapplication:23,shortlisted:9,notsuitable:7,matches:30,totalapplication:50,totalviews:2000,reportedon:"02-02-2022",reasonofreporting:"Reported as spam/scam",updatedon:"02-02-2022",remarks:"lorem impusum this is a static text"},
  //  ]

  dropDownMenuOpen = (event, params) => {
    this.setState({
      openDropDown: Boolean(event.currentTarget),
      anchorEl: event.currentTarget,
      selectedUser: params,
    });
  };

  closeDropDown = (event) => {
    this.setState({ anchorEl: event.currentTarget, openDropDown: false });
  };

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onselectDropDownDialog = (data) => {
    if (data.value == "Edit") {
      this.getListing();
      this.getData(this.state.selectedUser.id);

      this.setState({ openEditDialog: true, openDialog: false });

      return;
    }

    let buttonColor = "error";

    if (
      data.value == "Ok to Go" ||
      data.value == "Against Policy" ||
      data.value == "Send Reminder"
    ) {
      buttonColor = "primary";
    }

    if (data.value == "Against Policy") {
      this.setState({ showDialogTextInput: true });
    }
    console.log(data);
    this.setState({
      dialogHeader: data.dialogHeader,
      dialogContent: data.dialogContent,
      buttonText: data.buttonText,
      buttonStyle: buttonColor,
      openDialog: true,
    });
  };
  getData = async (id) => {
    const res = await initiateCall(RequestType.POST, "/getjobpostbyid", {
      id: id,
    });
    let d = res.data.data;

    await this.setState({
      jobPostId: id,
      jobTitle: d.jobtitle,
      jobRole: d.role,
      yearsOfExperience: d.years,
      country: d.country,
      state: d.state,
      address: d.address,
      currency: d.currency,
      minSalary: d.salarymin,
      maxSalary: d.salarymax,
      salary_type: d.salary_type,
      displaysalary: d.displaysalary,
      value: RichTextEditor.createValueFromString(d.jobdesc, "html"),
    });

    if (d.skillsets !== null) {
      this.setState({ qualities: d.skillsets });
    }

    if (d.qualities !== null) {
      this.setState({ skillsets: d.qualities });
    } else {
      this.setState({ skillsets: [] });
    }
  };

  checkEmpty = (state, error, errorMsg) => {
    if (!state) {
      if (
        error === "errorYearsOfExperience" ||
        error === "errorMinSalary" ||
        error === "errorMaxSalary"
      ) {
        if (state !== 0) {
          this.setState({ [error]: errorMsg });
          // return false;
        } else {
          this.setState({ [error]: "" });
          return true;
        }
      }

      this.setState({ [error]: errorMsg });
      return false;
    }
    this.setState({ [error]: "" });
    return true;
  };

  checkDigitNumber = (state, error) => {
    const regex = /^\d{0,7}(\.\d{1,2})?$/gm;
    if (regex.test(parseFloat(state)) === false) {
      this.setState({
        [error]: "Please input a number with no more than 7 digits.",
      });
      return false;
    } else if (parseFloat(state) < 1) {
      this.setState({
        [error]: "Please input a valid number.",
      });
      return false;
    }
    // if (Math.trunc(state).toString().length > 7) {
    //   this.setState({ [error]: "Please input a number with no more than 7 digits."});
    //   return false;
    // }
    this.setState({ [error]: "" });
    return true;
  };
  checkMinMaxSalary = () => {
    if (parseFloat(this.state.minSalary) > parseFloat(this.state.maxSalary)) {
      this.setState({
        errorMinSalary: "Min. salary must be lesser than max. salary",
      });
      return false;
    }
    this.setState({ errorMinSalary: "" });
    return true;
  };

  checkEmptyRTE = () => {
    let isEmpty = !this.state.value
      .getEditorState()
      .getCurrentContent()
      .hasText();
    if (isEmpty) {
      this.setState({ errorRTE: "Please enter job description." });
      return false;
    }
    this.setState({ errorRTE: "" });
    return true;
  };

  qualitiesCheck(values) {
    if (values.length === 0) {
      this.setState({ qualitieserror: "Please select at least one quality." });
      return false;
    } else {
      this.setState({ qualitieserror: "" });
      return true;
    }
  }

  skillsetsCheck(values) {
    if (values.length === 0) {
      this.setState({ skillsetserror: "Please select at least one skillset." });
      return false;
    } else {
      this.setState({ skillsetserror: "" });
      return true;
    }
  }

  qualitiesChange = (event, values) => {
    this.setState(
      {
        qualities: values,
      },
      () => {}
    );
  };

  skillsetsChange = (event, values) => {
    this.setState(
      {
        skillsets: values,
      },
      () => {}
    );
  };

  salaryTypeValidation() {
    if (this.state.salary_type === "") {
      this.setState({ errorSalaryType: "Please select a salary type." });
      return false;
    }
    this.setState({ errorSalaryType: "" });
    return true;
  }

  getListing = async () => {
    const res = await initiateCall(RequestType.GET, "/qualitiesListing");

    if (res.data.message === "success") {
      this.setState({
        allqualities: res.data.data.filter(function (e) {
          return e.type === "qualities";
        })[0]["list"],
        allskillsets: res.data.data.filter(function (e) {
          return e.type === "skillsets";
        })[0]["list"],
      });
    }
  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
      openEditDialog: false,
      showDialogTextInput: false,
    });
  };

  setCurrentTab = (index) => {
    this.setState({ currentTab: index });
    setTimeout(() => {
      this.fetchData();
    }, 100);
  };
  onChangeRTE = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  };

  clickLink = async (value) => {
    let url = "";
    if (window.location.href.includes("dev-cms")) {
      url =
        "https://dev-web.lexisjobs.asia/LexployerViewJobPosting?id=" + value;
    } else if (window.location.href.includes("localhost")) {
      url =
        "https://dev-web.lexisjobs.asia/LexployerViewJobPosting?id=" + value;
    } else {
      url = "https://lexisjobs.asia/LexployerViewJobPosting?id=" + value;
    }

    window.open(url, "_newtab");
    /*
          const res = await initiateCall(RequestType.POST, `/clickJob`, {
            paramid: value,
          });
          */
  };
  handleChangeNumber = (e) => {
    if (parseFloat(e.target.value) < 0) {
      this.setState({ [e.target.name]: 0 });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  componentDidMount = async () => {
    this.setState({ userid: this.props.userid });

    setTimeout(() => {
      this.fetchData();
    }, 100);

    let userdata = this.getWithExpiry("@userdata");
    if (userdata) {
      this.setState({ currentId: userdata.id });
    }

    setTimeout(() => {
      this.getCount();
    }, 100);
  };

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  getCount = async () => {
    const res = await initiateCall(RequestType.POST, `/getJobsCount`, {
      userid: this.state.userid,
    });

    await this.setState({
      countActive: res.data.data.active[0].count,
      countInactive: res.data.data.inactive[0].count,
      countReported: res.data.data.reported[0].count,
      countAgainst: res.data.data.against[0].count,
    });
  };

  fetchData = async (
    page = null,
    pageSize = null,
    search = null,
    sort = null
  ) => {
    //   this.setState({ dataLoading: true, searchDisable: true });
    const status =
      this.state.currentTab == 0 || this.state.currentTab == 2 ? 1 : -1;
    const newPage =
      search != null ? 0 : page != null ? page : this.state.dataPage;
    const newPageSize =
      search != null
        ? 10
        : pageSize != null
        ? pageSize
        : this.state.dataPageSize;
    const newSort = sort != null ? sort : this.state.sorting;

    const res = await initiateCall(RequestType.POST, `/jobPostListing`, {
      tablename: "jobposting",
      page: newPage,
      pageSize: newPageSize,
      search: search,
      sortModel: newSort,
      status: status,
      tabIndex: this.state.currentTab,
      userid: this.state.userid,
    });

    if (res) {
      if (this.state.currentTab == 0) {
        this.setState({
          Activerows: res.data.data[0].rows,
          dataTotal: parseInt(res.data.data[1]),
        });
      } else if (this.state.currentTab == 1) {
        this.setState({
          Inactiverows: res.data.data[0].rows,
          dataTotal: parseInt(res.data.data[1]),
        });
      } else if (this.state.currentTab == 2) {
        this.setState({
          Reportedrows: res.data.data[0].rows,
          dataTotal: parseInt(res.data.data[1]),
        });
      } else if (this.state.currentTab == 3) {
        this.setState({
          AgaintsPolicyrows: res.data.data[0].rows,
          dataTotal: parseInt(res.data.data[1]),
        });
      }
    } else {
      this.setState({ dataRows: [], dataTotal: 0 });
    }
    this.setState({ dataLoading: false, searchDisable: false });
  };

  submitPost = async () => {
    this.setState({ isSaving: true });

    this.checkEmpty(
      this.state.jobTitle,
      "errorJobTitle",
      "Please enter job title."
    );

    this.checkEmpty(
      this.state.jobRole,
      "errorJobRole",
      "Please select job role."
    );

    this.checkEmpty(
      this.state.yearsOfExperience,
      "errorYearsOfExperience",
      "Please enter years of experience."
    );

    this.checkEmpty(
      this.state.country,
      "errorCountry",
      "Please select country."
    );

    this.checkEmpty(this.state.state, "errorState", "Please select location.");

    this.checkEmpty(
      this.state.address,
      "errorAddress",
      "Please enter address."
    );

    this.salaryTypeValidation();
    this.checkEmpty(
      this.state.currency,
      "errorCurrency",
      "Please enter currency"
    );
    this.checkEmpty(
      this.state.minSalary,
      "errorMinSalary",
      "Please enter min. salary."
    );
    if (
      this.checkEmpty(
        this.state.minSalary,
        "errorMinSalary",
        "Please enter min. salary."
      )
    ) {
      this.checkDigitNumber(this.state.minSalary, "errorMinSalary");
      if (this.checkDigitNumber(this.state.minSalary, "errorMinSalary")) {
        this.checkMinMaxSalary();
      }
    }
    this.checkEmpty(
      this.state.maxSalary,
      "errorMaxSalary",
      "Please enter max. salary."
    );
    if (
      this.checkEmpty(
        this.state.maxSalary,
        "errorMaxSalary",
        "Please enter max. salary."
      )
    ) {
      this.checkDigitNumber(this.state.maxSalary, "errorMaxSalary");
    }

    this.checkEmptyRTE();
    this.qualitiesCheck(this.state.qualities);
    this.skillsetsCheck(this.state.skillsets);

    setTimeout(() => {
      if (
        this.state.errorJobTitle === "" &&
        this.state.errorJobRole === "" &&
        this.state.errorYearsOfExperience === "" &&
        this.state.errorCountry === "" &&
        this.state.errorState === "" &&
        this.state.errorAddress === "" &&
        this.state.errorSalaryType === "" &&
        this.state.errorCurrency === "" &&
        this.state.errorMinSalary === "" &&
        this.state.errorMaxSalary === "" &&
        this.state.qualitieserror === "" &&
        this.state.errorRTE === ""
      ) {
      } else {
        return;
      }
      this.sendPost();
    }, 50);
  };

  confirmDialog = async () => {
    var updateData = {
      tablename: "jobposting",
      jobId: this.state.selectedUser.id,
    };
    if (this.state.buttonText == "Deactivate") {
      let responds = await initiateCall(
        RequestType.POST,
        `/deactivateJobPost`,
        updateData
      );

      if (responds) {
        this.flashSuccess("Job Deactivated successful!", 3000);
        this.closeDialog();
      } else {
        this.flashError("Job Deactivated  failed!", 3000);
      }
    } else if (this.state.buttonText == "Reactivate") {
      let responds = await initiateCall(
        RequestType.POST,
        `/reactivateJobPost`,
        updateData
      );

      if (responds) {
        this.flashSuccess(" Job Reactivated successful!", 3000);
        this.closeDialog();
      } else {
        this.flashError("Job Reactivated failed!", 3000);
      }
    } else if (this.state.dialogHeader == "Ok to Go?") {
      updateData = {
        tablename: "jobposting",
        jobId: this.state.selectedUser.id,
        idBy: this.state.currentId,
      };
      let respondsReac = await initiateCall(
        RequestType.POST,
        `/clearReportedJobPost`,
        updateData
      );

      if (respondsReac) {
        this.flashSuccess("Job approved successful!", 3000);
        this.closeDialog();
      } else {
        this.flashError("Job approved failed!", 3000);
      }
    } else if (this.state.dialogHeader == "Against Policy") {
      const updateData = {
        tablename: "jobposting",
        jobId: this.state.selectedUser.id,
        idBy: this.state.currentId,
        againstReason: this.state.againstReason,
        reportId: this.state.selectedUser.jid,
      };
      let checks = this.checkEmpty(
        this.state.againstReason,
        "errorAgainstReason",
        "Please enter reason."
      );

      if (checks) {
        let againstReac = await initiateCall(
          RequestType.POST,
          `/againstJobPost`,
          updateData
        );

        if (againstReac) {
          this.flashSuccess("update successful!", 3000);
          this.closeDialog();
        } else {
          this.flashError("update failed!", 3000);
        }
      }
    } else if (this.state.buttonText == "Reminder") {
      let responds = await initiateCall(
        RequestType.POST,
        `/sendAgainstReminder`,
        updateData
      );
      if (responds) {
        this.closeDialog();
        this.flashSuccess("Reminder sent successful!", 3000);
      } else {
        this.flashError("Reminder sent failed!", 3000);
      }
    }

    this.fetchData(
      this.state.datapage,
      this.state.dataPageSize,
      this.state.searchValue
    );

    this.getCount();
  };

  searchBoxChange = (event) => {
    this.setState({ searchValue: event.target.value });
    //this.props.searchVal(this.state.searchValue);

    setTimeout(() => this.fetchData(null, null, event.target.value), 100);
  };

  keyPress = async (e) => {
    if (e.keyCode == 13) {
      setTimeout(() => {
        this.fetchData(null, null, this.state.searchValue);
      }, 30);
    }
  };

  sendPost = async () => {
    let data = {
      id: this.state.jobPostId,
      jobtitle: this.state.jobTitle,
      jobrole: this.state.jobRole,
      years: this.state.yearsOfExperience,
      country: this.state.country,
      state: this.state.state,
      address: this.state.address,
      salary_type: this.state.salary_type,
      currency: this.state.currency,
      salaryMin: this.state.minSalary,
      salaryMax: this.state.maxSalary,
      displaysalary: this.state.displaysalary,
      jobdesc: this.state.value.toString("html"),
      skillsets: this.state.qualities,
      qualities: this.state.skillsets,
    };

    const res = await initiateCall(RequestType.POST, `/updatejobpost`, data);

    if (res.data.message === "success") {
      this.flashSuccess("Update successful!", 3000);
      this.closeDialog();

      this.fetchData(
        this.state.datapage,
        this.state.dataPageSize,
        this.state.searchValue
      );
    } else {
      this.flashError("Update failed!", 3000);
    }
  };

  render() {
    return (
      <>
        <Grid container md={12} sx={{ marginTop: "90px" }}>
          <Grid md={9} container sx={{ height: "auto" }}>
            <Grid xs={12} sx={{ paddingLeft: "10px", fontSize: "12px" }}>
              Status
            </Grid>

            <Grid xs={12} container sx={{ padding: "10px" }}>
              {statusList.map((list, index) => {
                return (
                  <ListGrid
                    key={index}
                    sx={
                      list == "Active" || list == "Inactive"
                        ? colorList.primary
                        : colorList.warning
                    }
                    style={
                      this.state.currentTab === index && list === "Active"
                        ? { backgroundColor: "blue", color: "white" }
                        : this.state.currentTab === index && list === "Inactive"
                        ? { backgroundColor: "blue", color: "white" }
                        : this.state.currentTab === index && list === "Reported"
                        ? { backgroundColor: "red", color: "white" }
                        : this.state.currentTab === index &&
                          list === "Against Policy"
                        ? { backgroundColor: "red", color: "white" }
                        : null
                    }
                    onClick={() => this.setCurrentTab(index)}
                  >
                    {list === "Active"
                      ? list + " (" + this.state.countActive + ")"
                      : list === "Inactive"
                      ? list + " (" + this.state.countInactive + ")"
                      : list === "Reported"
                      ? list + " (" + this.state.countReported + ")"
                      : list === "Against Policy"
                      ? list + " (" + this.state.countAgainst + ")"
                      : list}
                  </ListGrid>
                );
              })}
            </Grid>
          </Grid>

          <Grid md={3} container>
            <Box id="searchBox">
              <Typography className="title">Search</Typography>
              <TextField
                className="textField"
                disabled={this.props.disabled}
                onKeyDown={this.keyPress}
                onBlur={this.searchBoxChange}
                hiddenLabel
                onChange={(e) => this.setState({ searchValue: e.target.value })}
                value={this.state.searchValue}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {this.state.currentTab == 0 && (
          <ActivePost
            rows={this.state.Activerows}
            columns={this.Activecolumns}
            open={this.state.openDropDown}
            anchorEl={this.state.anchorEl}
            dropDownMenuOpen={this.dropDownMenuOpen}
            closeDropDown={this.closeDropDown}
            onselectDropDownDialog={this.onselectDropDownDialog}
            closeDialog={this.closeDialog}
            selectStatus={"Deactivate"}
            dialogHeader={this.state.dialogHeader}
            dialogContent={this.state.dialogContent}
            buttonText={this.state.buttonText}
            buttonStyle={this.state.buttonStyle}
            openEditDialog={this.state.openEditDialog}
            showDialogTextInput={this.state.showDialogTextInput}
          />
        )}

        {this.state.currentTab == 1 && (
          <ActivePost
            rows={this.state.Inactiverows}
            columns={this.Inactivecolumns}
            open={this.state.openDropDown}
            anchorEl={this.state.anchorEl}
            dropDownMenuOpen={this.dropDownMenuOpen}
            closeDropDown={this.closeDropDown}
            onselectDropDownDialog={this.onselectDropDownDialog}
            closeDialog={this.closeDialog}
            selectStatus={"Reactivate"}
            dialogHeader={this.state.dialogHeader}
            dialogContent={this.state.dialogContent}
            buttonText={this.state.buttonText}
            buttonStyle={this.state.buttonStyle}
            openEditDialog={this.state.openEditDialog}
            showDialogTextInput={this.state.showDialogTextInput}
          />
        )}

        {this.state.currentTab == 2 && (
          <ActivePost
            rows={this.state.Reportedrows}
            columns={this.Reportedcolumns}
            open={this.state.openDropDown}
            anchorEl={this.state.anchorEl}
            dropDownMenuOpen={this.dropDownMenuOpen}
            closeDropDown={this.closeDropDown}
            onselectDropDownDialog={this.onselectDropDownDialog}
            closeDialog={this.closeDialog}
            selectStatus={"Reported"}
            dialogHeader={this.state.dialogHeader}
            dialogContent={this.state.dialogContent}
            buttonText={this.state.buttonText}
            buttonStyle={this.state.buttonStyle}
            openEditDialog={this.state.openEditDialog}
            showDialogTextInput={this.state.showDialogTextInput}
          />
        )}

        {this.state.currentTab == 3 && (
          <ActivePost
            rows={this.state.AgaintsPolicyrows}
            columns={this.AgaintPolicycolumns}
            open={this.state.openDropDown}
            anchorEl={this.state.anchorEl}
            dropDownMenuOpen={this.dropDownMenuOpen}
            closeDropDown={this.closeDropDown}
            onselectDropDownDialog={this.onselectDropDownDialog}
            closeDialog={this.closeDialog}
            selectStatus={"Against Policy"}
            dialogHeader={this.state.dialogHeader}
            dialogContent={this.state.dialogContent}
            buttonText={this.state.buttonText}
            buttonStyle={this.state.buttonStyle}
            openEditDialog={this.state.openEditDialog}
            showDialogTextInput={this.state.showDialogTextInput}
          />
        )}

        <Dialog
          id="AlertJobEditDialog"
          open={this.state.openEditDialog}
          onClose={this.closeDialog}
          // handleUserUpdate={(event) => {
          //   this.actionJobPost(event);
          //   this.closeDialog(event);
          // }}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle className="DialogTitle" id="scroll-dialog-title">
            Edit Job Post
          </DialogTitle>

          <DialogContent dividers={true}>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Job Details</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <label htmlFor="jobTitle">Title</label>
                    <FormControl className="form-control" variant="outlined">
                      <OutlinedInput
                        id="jobTitle"
                        name="jobTitle"
                        type="text"
                        className="input"
                        variant="outlined"
                        fullWidth
                        value={this.state.jobTitle}
                        onChange={(e) =>
                          this.setState({ jobTitle: e.target.value })
                        }
                      />
                      <div className="errorMessage">
                        {this.state.errorJobTitle}
                      </div>
                    </FormControl>
                  </div>

                  <Grid container>
                    <Grid item xs={12} md={5.5}>
                      <label htmlFor="jobRole">Role</label>
                      <FormControl
                        fullWidth
                        size="small"
                        className="select inputRoleExperience mobileRole"
                      >
                        <Select
                          className="select-input"
                          labelId="jobRole"
                          id="jobRole"
                          name="jobRole"
                          value={this.state.jobRole}
                          onChange={(e) =>
                            this.setState({ jobRole: e.target.value })
                          }
                        >
                          <MenuItem value="Permanent">Permanent</MenuItem>
                          <MenuItem value="Full-time">Full-time</MenuItem>
                          <MenuItem value="Part-time">Part-time</MenuItem>
                          <MenuItem value="Flexible">Flexible</MenuItem>
                          <MenuItem value="Contract">Contract</MenuItem>
                          <MenuItem value="Temporary">Temporary</MenuItem>
                          <MenuItem value="Internship">Internship</MenuItem>
                        </Select>
                        <div className="errorMessage">
                          {this.state.errorJobRole}
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1}></Grid>
                    <Grid item xs={12} md={5.5}>
                      <label htmlFor="yearsOfExperience">
                        Years of Experience
                      </label>
                      <FormControl
                        className="form-control inputRoleExperience"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="yearsOfExperience"
                          name="yearsOfExperience"
                          type="number"
                          className="input"
                          inputprops={{ inputProps: { min: 0 } }}
                          variant="outlined"
                          fullWidth
                          onKeyPress={(event) => {
                            if (event.key === "-" || event.key === "+") {
                              event.preventDefault();
                            }
                          }}
                          value={this.state.yearsOfExperience}
                          onChange={this.handleChangeNumber}
                          onWheel={(event) => {
                            event.target.blur();
                          }}
                        />
                        <div className="errorMessage">
                          {this.state.errorYearsOfExperience}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Location</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <Grid container>
                      <Grid item xs={12} md={5.5}>
                        <label htmlFor="country">Country</label>
                        <FormControl fullWidth size="small" className="select">
                          <Select
                            className="select-input"
                            labelId="country"
                            id="country"
                            name="country"
                            onChange={(e) =>
                              this.setState({ country: e.target.value })
                            }
                            value={this.state.country}
                          >
                            <MenuItem value="Singapore">Singapore</MenuItem>
                            <MenuItem value="Malaysia">Malaysia</MenuItem>
                            <MenuItem value="Indonesia">Indonesia</MenuItem>
                          </Select>
                          <div className="errorMessage">
                            {this.state.errorCountry}
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={12} md={5.5}>
                        <label htmlFor="state">Location</label>
                        <FormControl fullWidth size="small" className="select">
                          <Select
                            className="select-input"
                            labelId="state"
                            id="state"
                            name="state"
                            onChange={(e) =>
                              this.setState({ state: e.target.value })
                            }
                            value={this.state.state}
                          >
                            <MenuItem value="All Locations">
                              All Locations
                            </MenuItem>
                            <MenuItem value="Central">Central</MenuItem>
                            <MenuItem value="North">North</MenuItem>
                            <MenuItem value="South">South</MenuItem>
                            <MenuItem value="East">East</MenuItem>
                            <MenuItem value="West">West</MenuItem>
                            <MenuItem value="North-east">North-east</MenuItem>
                            <MenuItem value="North-west">North-west</MenuItem>
                            <MenuItem value="South-east">South-east</MenuItem>
                            <MenuItem value="South-west">South-west</MenuItem>
                          </Select>
                          <div className="errorMessage">
                            {this.state.errorState}
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <FormControl
                      className="form-control inputRoleExperience"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="address"
                        name="address"
                        type="text"
                        className="inputarea"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows="3"
                        value={this.state.address}
                        onChange={(e) =>
                          this.setState({ address: e.target.value })
                        }
                      />
                      <div className="errorMessage">
                        {this.state.errorAddress}
                      </div>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Salary Range</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <RadioGroup
                      row
                      // aria-labelledby="demo-row-radio-buttons-group-label"
                      value={this.state.salary_type}
                      name="salary_type"
                      onChange={(e) =>
                        this.setState({ salary_type: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="Monthly"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Hourly"
                        control={<Radio />}
                        label="Hourly"
                      />
                    </RadioGroup>
                    <div className="errorMessage">
                      {this.state.errorSalaryType}
                    </div>
                  </div>
                </div>
                <div className="form currency">
                  <div className="form-group">
                    <Grid container>
                      <Grid item xs={12} md={3.3}>
                        <label htmlFor="currency">Currency</label>
                        <FormControl fullWidth size="small" className="select">
                          <Select
                            labelId="currency"
                            id="currency"
                            name="currency"
                            onChange={(e) =>
                              this.setState({ currency: e.target.value })
                            }
                            value={this.state.currency}
                          >
                            <MenuItem value="SGD">SGD</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                          </Select>
                          <div className="errorMessage">
                            {this.state.errorCurrency}
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={12} md={3.3}>
                        <label htmlFor="minSalary">Minimum</label>
                        <FormControl
                          className="form-control"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="minSalary"
                            name="minSalary"
                            type="number"
                            className="input"
                            variant="outlined"
                            fullWidth
                            onKeyPress={(event) => {
                              if (event.key === "-" || event.key === "+") {
                                event.preventDefault();
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={this.state.minSalary}
                            onChange={this.handleChangeNumber}
                            onWheel={(event) => {
                              event.target.blur();
                            }}
                          />
                          <div className="errorMessage">
                            {this.state.errorMinSalary}
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={12} md={3.3}>
                        <label htmlFor="maxSalary">Maximum</label>
                        <FormControl
                          className="form-control"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="maxSalary"
                            name="maxSalary"
                            type="number"
                            className="input"
                            variant="outlined"
                            fullWidth
                            onKeyPress={(event) => {
                              if (event.key === "-" || event.key === "+") {
                                event.preventDefault();
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={this.state.maxSalary}
                            onChange={this.handleChangeNumber}
                            onWheel={(event) => {
                              event.target.blur();
                            }}
                          />
                          <div className="errorMessage">
                            {this.state.errorMaxSalary}
                          </div>
                        </FormControl>
                      </Grid>
                      <FormControl className="checkBox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.displaysalary}
                              name="displaysalary"
                              onChange={this.handleCheckBox}
                            />
                          }
                          label="I do not wish to display salary in job posting."
                          className="checkBoxLabel"
                        />
                      </FormControl>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-card">
              <div className="card-body">
                <div className="card-title">
                  <Grid container>
                    <Grid item className="start">
                      <div className="title-text">Job Description</div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form">
                  <div className="form-group">
                    <RichTextEditor
                      value={this.state.value}
                      onChange={this.onChangeRTE}
                      editorClassName="rte-editor"
                      toolbarStyle={{
                        background: "#f1f2f6",
                        margin: 0,
                        paddingLeft: 16,
                      }}
                      toolbarConfig={toolbarConfig}
                    />
                    <div className="errorMessage">{this.state.errorRTE}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-card">
              <div className="card-body">
                <div className="form">
                  <div className="form-group">
                    <label htmlFor="qualities" className="qualities">
                      Share the qualities that an ideal candidate should possess
                    </label>
                    <Typography className="DialogContentText">
                      You may add as many as you like.
                    </Typography>
                    <Autocomplete
                      multiple
                      id="qualities"
                      className="autoQualities"
                      options={this.state.allqualities}
                      disableCloseOnSelect
                      onChange={this.qualitiesChange}
                      value={this.state.qualities}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type Something..."
                        />
                      )}
                    />
                    <div id="qualitiesError" className="errorMessage">
                      {this.state.qualitieserror}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-card">
              <div className="card-body">
                <div className="form">
                  <div className="form-group">
                    <label htmlFor="skillsets" className="skillsets">
                      Share the skillsets that an ideal candidate should possess
                    </label>
                    <Typography className="DialogContentText">
                      You may add as many as you like.
                    </Typography>
                    <Autocomplete
                      multiple
                      id="skillsets"
                      className="autoSkillsets"
                      options={this.state.allskillsets}
                      disableCloseOnSelect
                      onChange={this.skillsetsChange}
                      value={this.state.skillsets}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type Something..."
                        />
                      )}
                    />
                    <div id="skillsetError" className="errorMessage">
                      {this.state.skillsetserror}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="Button"
              id="cancelBtn"
              onClick={this.closeDialog}
            >
              Cancel
            </Button>
            <Button
              className="Button"
              variant="contained"
              color={this.state.DialogButtonColor}
              onClick={this.submitPost}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Notifications
          setFlashSuccess={(func) => (this.flashSuccess = func)}
          setFlashError={(func) => (this.flashError = func)}
        />

        <Dialog
          maxWidth="xs"
          fullWidth
          id="AlertDialog"
          open={this.state.openDialog}
          onClose={this.closeDialog}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle
            sx={{ backgroundColor: "#F1F2F6", fontWeight: "bold" }}
            className="DialogTitle"
            id="scroll-dialog-title"
          >
            {this.state.dialogHeader}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ paddingTop: "9px", fontSize: "14px" }}>
              {this.state.showDialogTextInput && (
                <div>
                  <label>Please State a reason</label>
                  <TextField
                    sx={{ marginBottom: "20px" }}
                    fullWidth
                    id="outlined-multiline-flexible"
                    size="small"
                    multiline
                    rows={3}
                    value={this.state.againstReason}
                    onChange={(e) =>
                      this.setState({ againstReason: e.target.value })
                    }
                  />
                  <div className="errorMessage">
                    {this.state.errorAgainstReason}
                  </div>
                </div>
              )}

              {this.state.dialogContent}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.closeDialog}>Close</Button>
            <Button
              color={this.state.buttonStyle}
              variant="contained"
              onClick={this.confirmDialog}
            >
              {this.state.buttonText}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
