const bankCodes = [
    {
        "code": "ANZBSGSXXXX",
        "bankName": "Australia and New Zealand Banking Group"
      },
      {
        "code": "BKKBSGSGXXX",
        "bankName": "Bangkok Bank Public Company Limited"
      },
      {
        "code": "BOFASG2XXXX",
        "bankName": "Bank of America, National Association"
      },
      {
        "code": "BKCHSGSGXXX",
        "bankName": "Bank of China Limited"
      },
      {
        "code": "BEASSGSGXXX",
        "bankName": "Bank of East Asia"
      },
      {
        "code": "BKIDSGSGXXX",
        "bankName": "Bank of India"
      },
      {
        "code": "BOTKSGSXXXX",
        "bankName": "Bank of Tokyo-Mitsubishi UFJ"
      },
      {
        "code": "BNPASGSGXXX",
        "bankName": "BNP Paribas"
      },
      {
        "code": "CTCBSGSGXXX",
        "bankName": "Chinatrust Commercial Bank Co. Ltd"
      },
      {
        "code": "CIBBSGSGXXX",
        "bankName": "CIMB Bank Berhad"
      },
      {
        "code": "CITISGSGXXX",
        "bankName": "Citibank NA Singapore Branch"
      },
      {
        "code": "CITISGSLXXX",
        "bankName": "Citibank Singapore Limited"
      },
      {
        "code": "COBASGSXXXX",
        "bankName": "Commerzbank Aktiengesellschaft"
      },
      {
        "code": "CRLYSGSGXXX",
        "bankName": "Crédit Agricole Corporate And Investment Bank"
      },
      {
        "code": "DBSSSGSGXXX",
        "bankName": "DBS Bank Ltd"
      },
      {
        "code": "DEUTSGSGXXX",
        "bankName": "Deutsche Bank AG"
      },
      {
        "code": "DNBASGSGXXX",
        "bankName": "DnB Bank ASA"
      },
      {
        "code": "FCBKSGSGXXX",
        "bankName": "First Commercial Bank"
      },
      {
        "code": "HLBBSGSGXXX",
        "bankName": "HL Bank"
      },
      {
        "code": "HSBCSGSGXXX",
        "bankName": "Hongkong and Shanghai Banking Corporation Limited, Singapore Branch"
      },
      {
        "code": "HSBCSGS2XXX",
        "bankName": "HSBC Bank (Singapore) Ltd"
      },
      {
        "code": "ICICSGSGXXX",
        "bankName": "ICICI Bank Limited"
      },
      {
        "code": "IDIBSGSGXXX",
        "bankName": "Indian Bank"
      },
      {
        "code": "IOBASGSGXXX",
        "bankName": "Indian Overseas Bank"
      },
      {
        "code": "ICBKSGSGXXX",
        "bankName": "Industrial & Commercial Bank Of China"
      },
      {
        "code": "BCITSGSGXXX",
        "bankName": "Intesa Sanpaolo SpA"
      },
      {
        "code": "CHASSGSGXXX",
        "bankName": "JPMorgan Chase Bank, N.A."
      },
      {
        "code": "KOEXSGSGXXX",
        "bankName": "Korea Exchange Bank"
      },
      {
        "code": "MBBESGSGXXX",
        "bankName": "Malayan Banking Berhad"
      },
      {
        "code": "MBBESGS2XXX",
        "bankName": "Maybank Singapore Limited"
      },
      {
        "code": "MHCBSGSGXXX",
        "bankName": "Mizuho Bank Limited"
      },
      {
        "code": "NATASGSGXXX",
        "bankName": "National Australia Bank Ltd"
      },
      {
        "code": "NDEASGSGXXX",
        "bankName": "Nordea Bank AB"
      },
      {
        "code": "OCBCSGSGXXX",
        "bankName": "Oversea-Chinese Banking Corporation Ltd"
      },
      {
        "code": "BNINSGSGXXX",
        "bankName": "PT Bank Negara Indonesia (Persero) TBK"
      },
      {
        "code": "QNBASGSGXXX",
        "bankName": "Qatar National Bank SAQ"
      },
      {
        "code": "RHBBSGSGXXX",
        "bankName": "RHB Bank Berhad"
      },
      {
        "code": "ESSESGSGXXX",
        "bankName": "Skandinaviska Enskilda Banken AB"
      },
      {
        "code": "SOGESGSGXXX",
        "bankName": "Societe Generale"
      },
      {
        "code": "SCBLSGSGXXX",
        "bankName": "Standard Chartered Bank, Singapore Branch"
      },
      {
        "code": "SCBLSG22XXX",
        "bankName": "Standard Chartered Bank, (Singapore) Limited"
      },
      {
        "code": "SBINSGSGXXX",
        "bankName": "State Bank Of India"
      },
      {
        "code": "SMBCSGSGXXX",
        "bankName": "Sumitomo Mitsui Banking Corporation"
      },
      {
        "code": "HANDSGSGXXX",
        "bankName": "Svenska Handelsbanken AB"
      },
      {
        "code": "UBSWSGSGXXX",
        "bankName": "UBS AG"
      },
      {
        "code": "UCBASGSGXXX",
        "bankName": "UCO Bank"
      },
      {
        "code": "BVBESGSGXXX",
        "bankName": "UniCredit Bank AG"
      },
      {
        "code": "UOVBSGSGXXX",
        "bankName": "United Overseas Bank Ltd"
      }
];

export default bankCodes;