import React from "react";
import BasePageComponent from "../../components/BasePageComponent";
import "./LexisCardGiftList.scss";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import SearchBox from "./LexisCardGiftComponents/SearchBox";
import InputField from "./LexisCardGiftComponents/InputField";
import DropdownMenu from "./LexisCardGiftComponents/DropdownMenu";
import DropdownStatus from "./LexisCardGiftComponents/DropDownStatus";
import DropdownFilter from "./LexisCardGiftComponents/DropdownFilter";
import AlertDialog from "./LexisCardGiftComponents/AlertDialog";
import Notifications from "../../components/Notifications";
import RequestType from "../../utils/RequestType";
import { initiateCall } from "../../utils/ApiHandler";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem, Fade } from "@mui/material";
import moment from "moment";

import { exportAsExcel } from "../../utils/ExcelExporter";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

export default class LexisCardGiftList extends BasePageComponent {
    constructor(props) {
        super(props);
        this.searchFilterRef = React.createRef();
        this.state = {
            anchorEl: null,
            open: false,
            selectedUser: [],
            selectedUserOriginalData: [],

            searchValue: "",
            searchDisable: false,
            dataLoading: false,
            dataRows: [],
            dataTotal: 0,
            dataPage: 0,
            dataPageSize: 10,

            DialogOpen: false,
            DialogTitle: "Title",
            DialogMessage: "Deactivate the following account?",
            DialogMessageTargetEmail: "",
            DialogButtonText: "Button",
            DialogButtonColor: "primary",

            sorting: [{ field: "createdon", sort: "desc" }],

            selectedUsers: [],

            dropDownAnchorEl: null,
            dropdownOpen: false,

            openGiftDialog: false,
            isEditShipment: false,
            sortStatus: 'Enroute',
            errorCourierName: false,
            errorTrackingNumber: false
        };
    }

    componentDidMount = async (...args) => {
        super.componentDidMount.apply(this, args);
        this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.searchValue, this.state.sorting, this.state.sortStatus);
    };

    fetchData = async (
        page = null,
        pageSize = null,
        search = null,
        sort = null,
        status = 'Enroute'
    ) => {

        this.setState({ dataLoading: true, searchDisable: true });

        const res = await initiateCall(RequestType.POST, `/lexisCardGiftLift`, {
            page: page,
            pageSize: pageSize,
            search: search,
            sortModel: sort,
            status: status
        });

        if (res) {
            this.setState({
                dataRows: res.data.data,
                dataTotal: parseInt(res.data.total),
            });
        } else {
            this.setState({ dataRows: [], dataTotal: 0 });
        }
            this.setState({ dataLoading: false, searchDisable: false });

        if (search) {
            document.getElementById("userSearchBox").focus();
        }
    };

    columns = [
        {
            field: "useremail",
            headerName: "Email",
            minWidth: 300,
            flex: 1,
            renderCell: (params) => {
                return (
                <div
                    style={{
                    color: "blue",
                    wordBreak: "break-word",
                    whiteSpace: "initial",
                    }}
                >
                    {params.value}
                </div>
                );
            },
        },
        {
            field: "createdon",
            headerName: "Date Joined",
            flex: 1,
            valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
        },
        // {
        //     field: "createdon",
        //     headerName: "Subscribed Date",
        //     flex: 1,
        //     valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
        // },
        {
            field: "subscription",
            headerName: "Subscription",
            flex: 1,
            renderCell: (params) => {
                if (params.value === "proUnli") {
                    return (
                        <div style={{ textTransform: "capitalize" }}>Pro Unlimited</div>
                    );
                } else if (params.value === "cPrem") {
                    return (
                        <div style={{ textTransform: "capitalize" }}>Corporate Premium</div>
                    );
                } else {
                    return (
                        <div style={{ textTransform: "capitalize" }}>{params.value}</div>
                    );
                }
            },
        },
        {
            field: "subscription_exp",
            headerName: "Subscription Expiry",
            flex: 1,
            valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
        },
        {
            field: "action",
            headerName: "Actions",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: "pointer" }}>
                        <MoreHorizIcon
                            onClick={(event) => this.dropDownMenuOpen(event, params.row)}
                        />
                    </div>
                );
            },
        }
    ];

    dropDownMenuOpen = (event, row) => {
        this.setState({
            anchorEl: event.currentTarget,
            open: Boolean(event.currentTarget),
            selectedUser: row,
            selectedUserOriginalData: row
        });
    };

    dropDownMenuClose = (event) => {
        this.setState({ anchorEl: event.currentTarget, open: false });
    };

    dropDownMenuSelected = (item) => {
        this.setState({ openGiftDialog: true });
    };

    alertDialogOpen = (event) => {
        this.setState({ DialogOpen: event.target.value });
    };
    alertDialogClose = (event) => {
        this.setState({ DialogOpen: event.target.value });
    };

    renderStatus = (params) => {
        if (params.value === 0) {
            return <div>Active</div>;
        } else if (params.value === -1) {
            return <div>Suspended</div>;
        } else if (params.value === -2) {
            return <div>Deleted</div>;
        }
    };

    dateFormatter = (params) => {
        return params.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3.$2.$1");
    };

    searchBoxChange = (event) => {
        this.setState({ searchValue: event.target.value });
        clearTimeout(this.searchFilterRef);

        this.searchFilterRef = setTimeout(
            () => this.fetchData(this.state.dataPage, this.state.dataPageSize, event.target.value, this.state.sorting, this.state.sortStatus),
            700
        );
    };

    updateUser = async (event) => {
        const updateData = {
            tablename: "namecards",
            useremail: this.state.DialogMessageTargetEmail,
        };
        let res = null;
        switch (this.state.DialogButtonText) {
            case "Activate":
                res = await initiateCall(
                    RequestType.POST,
                    `/activateLexisCard`,
                    updateData
                );
                break;
            case "Suspend":
                res = await initiateCall(
                    RequestType.POST,
                    `/suspendLexisCard`,
                    updateData
                );
                break;
            case "Delete":
                res = await initiateCall(
                    RequestType.POST,
                    `/deleteLexisCard`,
                    updateData
                );
                break;
            case "Deactivate":
                res = await initiateCall(
                    RequestType.POST,
                    `/deactivateLexisCard`,
                    updateData
                );
                break;
            default:
                break;
        }
        if (res) {
            this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
        } else {
            this.flashError(this.state.DialogTitle + " failed!", 3000);
        }

        this.fetchData(
            this.state.dataPage,
            this.state.dataPageSize,
            this.state.searchValue,
            this.state.sorting,
            this.state.sortStatus
        );
    };

    setSortModel = async (sortModels) => {
        await this.setState({
            sorting: sortModels,
        });
        this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.searchValue, sortModels, this.state.sortStatus);
    };

  
    handleSelect = (selected) => {
        this.setState({
            dropdownOpen: false
        });

        if(selected === 'all'){
            exportAsExcel(
                "Lexiscard",
                "namecards",
                this.state.selectedUsers,
                this.state.searchValue,
                this.state.status
            )
        } else {
            exportAsExcel(
                "Lexiscard Sales",
                "namecards",
                this.state.selectedUsers,
                this.state.searchValue,
                'sales'
            )
        }
    }

    onDropDown = (event) => {
        this.setState({
            dropdownOpen: true,
            dropDownAnchorEl: event.currentTarget
        });
    }
  
    handleClose = () => {
        this.setState({
            dropdownOpen: false,
        });
    }

    closeGiftDialog = () => {
        this.setState({
            selectedUser: [],
            selectedUserOriginalData: [],
            openGiftDialog: false
        })
    };

    saveGiftDialog = async () => {

        const data = this.state.selectedUser

        if(
            data?.gift?.tracking_number || 
            data?.gift?.courier_name || 
            data?.gift?.tracking_number?.trim() !== "" || 
            data?.gift?.courier_name?.trim() !== ""
        ){

            data.gift.courier_name = data?.gift?.courier_name?.trim()
            data.gift.tracking_number = data?.gift?.tracking_number?.trim()

            const res = await initiateCall(RequestType.POST, `/updateLexisCardGift`, {user_id: data.id, useremail: data.useremail, name: data?.meta?.name, data: data.gift, status: data.gift.status});

            if(res.status == 200){
                this.setState({
                    isEditShipment: false
                })

                this.setState({ 
                    selectedUserOriginalData: this.state.selectedUser,
                    errorCourierName: false,
                    errorTrackingNumber: false 
                })
                this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.searchValue, this.state.sorting, this.state.sortStatus);
            }else{
                this.setState({
                    isEditShipment: false,
                    selectedUsers: this.state.selectedUserOriginalData,
                    errorCourierName: false,
                    errorTrackingNumber: false
                })
            }
        }else{
            this.setState({
                errorCourierName: true,
                errorTrackingNumber: true
            })
        }
        
    };

    handleEditShipment = () => {

        if(this.state.isEditShipment){
            this.setState({ selectedUsers: this.state.selectedUserOriginalData })
        }

        this.setState({ isEditShipment: !this.state.isEditShipment });
    }

    handleInputChange = (value, field) => {
        const { selectedUser } = this.state;
    
        const updatedGift = { ...selectedUser.gift };
    
        updatedGift[field] = value;
    
        this.setState({
            selectedUser: {
                ...selectedUser,
                gift: updatedGift,
            }
        });
    };
    

    dropdownStatusChange = (event) => {
        const newStatus = event.target.value;
    
        this.setState(prevState => ({
            selectedUser: {
                ...prevState.selectedUser,
                gift: {
                    ...prevState.selectedUser.gift,
                    status: newStatus
                }
            }
        }));
    };

    dropdownSortChange = (event) => {
        const newSortStatus = event.target.value;
      
        this.setState({ sortStatus: newSortStatus });

        this.searchFilterRef = setTimeout(
            () => this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.searchValue, this.state.sorting, newSortStatus),
            700
        );
    };
    

    render() {
        return super.render(
            <>
                <AppBar
                    position="fixed"
                    sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
                >
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    color="#000000"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    LexisCard Gift Reciever List ({this.state.dataTotal})
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Toolbar />

                <div className="LexisCard">
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <DropdownFilter
                            sortStatus={this.state.sortStatus}
                            handleSortChange={this.dropdownSortChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SearchBox
                            handleTextChange={this.searchBoxChange}
                            disabled={this.state.searchDisable}
                        />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                    <DataGrid
                        sx={{ bgcolor: "white" }}
                        autoHeight={true}
                        loading={this.state.dataLoading}
                        rows={this.state.dataRows}
                        columns={this.columns}
                        rowCount={this.state.dataTotal}
                        pageSize={this.state.dataPageSize}
                        page={this.state.dataPage}
                        rowsPerPageOptions={[10, 50, 100]}
                        onSelectionModelChange={(newSelection) => {
                            this.setState({ selectedUsers: newSelection });
                        }}
                        sortModel={this.state.sorting}
                        onSortModelChange={(newSortModel) => {
                            this.setSortModel(newSortModel);
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={(newPage) => {
                            this.setState({ dataPage: newPage });
                            this.fetchData(newPage, this.state.dataPageSize, this.state.searchValue, this.state.sorting, this.state.sortStatus);
                        }}
                        onPageSizeChange={(newPageSize) => {
                            this.setState({ dataPageSize: newPageSize, dataPage: 0 });
                            this.fetchData(0, newPageSize, this.state.searchValue, this.state.sorting, this.state.sortStatus);
                        }}
                        disableColumnSelector
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: () => (
                                <Stack
                                    height="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    No results found
                                </Stack>
                            ),
                        }}
                    ></DataGrid>
                    </Grid>
                </Grid>
                    <DropdownMenu
                        anchorEl={this.state.anchorEl}
                        open={this.state.open}
                        onClose={this.dropDownMenuClose}
                        onClick={this.dropDownMenuClose}
                        onSelect={this.dropDownMenuSelected}
                        dataFrom={this.state.selectedUser}
                    />
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby' : 'fade-button',
                        }}
                        anchorEl={this.state.dropDownAnchorEl}
                        open={this.state.dropdownOpen}
                        elevation={2}
                        onClose={this.handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem 
                            onClick={() => this.handleSelect('all')} 
                            style={{ color: 'black', fontSize: 14 }}
                        >
                            Export as Excel
                        </MenuItem>
                        <MenuItem 
                            onClick={() => this.handleSelect('sales')} 
                            style={{ color: 'black', fontSize: 14 }}
                        >
                            Export Sales as Excel
                        </MenuItem>
                    </Menu>
                    <AlertDialog
                        title={this.state.DialogTitle}
                        message={this.state.DialogMessage}
                        messageTargetEmail={this.state.DialogMessageTargetEmail}
                        buttonText={this.state.DialogButtonText}
                        buttonColor={this.state.DialogButtonColor}
                        DialogOpen={this.state.DialogOpen}
                        handleDialogClose={this.alertDialogClose}
                        handleUserUpdate={(event) => {
                            this.updateUser(event);
                            this.alertDialogClose(event);
                        }}
                    />
                    <Notifications
                        setFlashSuccess={(func) => (this.flashSuccess = func)}
                        setFlashError={(func) => (this.flashError = func)}
                    />

                    <Dialog
                        className="adminDialogTitle"
                        onClose={this.closeGiftDialog}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.openGiftDialog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle className="dialogTitle">
                            <div className="dialogTitle">Subscription Gift</div>
                        </DialogTitle>
                        <DialogContent dividers className="dialogContent">
                            <div style={{ marginBottom: 40 }}>
                                <h4 style={{ marginBottom: 10 }}>User Information</h4>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'start', borderWidth: 2, borderColor: 'red' }}>
                                        <label>Name</label>
                                        {this?.state?.selectedUser?.meta?.name}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'start' }}>
                                        <label>Email</label>
                                        {this?.state?.selectedUser?.useremail}
                                    </div>
                                </div>
                            </div>
                            
                            <div style={{ marginBottom: 40 }}>
                                <h4 style={{ marginBottom: 10 }}>Shipment Information</h4>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'start', borderWidth: 2, borderColor: 'red' }}>
                                        <label>Name</label>
                                        {this?.state?.selectedUser?.gift?.first_name} {this?.state?.selectedUser?.gift?.last_name}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'start' }}>
                                        <label>Email</label>
                                        {this?.state?.selectedUser?.gift?.email_address}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'start' }}>
                                        <label>Contact No.</label>
                                        {this?.state?.selectedUser?.gift?.phone_number}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'start' }}>
                                        <label>Address</label>
                                        {this?.state?.selectedUser?.gift?.address}
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginBottom: 40 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h4 style={{ marginBottom: 10 }}>Shipment Information</h4> 
                                    <div>
                                    {
                                        this.state?.selectedUserOriginalData?.gift?.status === 'Enroute' ? (
                                            this.state.isEditShipment ? (
                                                <EditOffIcon color='gray' size='14' style={{ marginLeft: -90, marginTop: 10 }} onClick={this.handleEditShipment} />
                                            ) : (
                                                <EditIcon color='gray' size='14' style={{ marginLeft: -90, marginTop: 10 }} onClick={this.handleEditShipment} />
                                            )
                                        ) : null
                                    }
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '85%' }}>
                                            <label>Courier Name</label>
                                            {this.state.isEditShipment ? (
                                                <>
                                                    <InputField 
                                                        defaultValue={this?.state?.selectedUser?.gift?.courier_name}
                                                        field='courier_name'
                                                        onChange={this.handleInputChange}
                                                    />
                                                    <p style={{color: 'red', fontSize: 12, display: this.state.errorCourierName ? 'block' : 'none'}}>Invalid Input. Please check value before saving.</p>
                                                </>
                                            ) : (
                                                <>{this?.state?.selectedUser?.gift?.courier_name ? this?.state?.selectedUser?.gift?.courier_name : 'XXX XXX'}</>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '85%' }}>
                                            <label>Tracking Number</label>
                                            {this.state.isEditShipment ? (
                                                <>
                                                    <InputField 
                                                        defaultValue={this?.state?.selectedUser?.gift?.tracking_number}
                                                        field='tracking_number'
                                                        onChange={this.handleInputChange}
                                                    />
                                                    <p style={{color: 'red', fontSize: 12, display: this.state.errorTrackingNumber ? 'block' : 'none'}}>Invalid Input. Please check value before saving.</p>
                                                </>
                                            ) : (
                                                <>{this?.state?.selectedUser?.gift?.tracking_number ? this?.state?.selectedUser?.gift?.tracking_number : 'XXX XXX'}</>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '85%' }}>
                                            <label>Status</label>
                                            {this.state.isEditShipment ? (
                                                <DropdownStatus
                                                    status={this?.state?.selectedUser?.gift?.status}
                                                    handleChange={this.dropdownStatusChange}
                                                />
                                            ) : (
                                                <>{this?.state?.selectedUser?.gift?.status}</>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </DialogContent>
                        <div className="addAdmin">
                            <DialogActions>
                                {
                                    this.state.isEditShipment ? 
                                    <>
                                        <Button
                                            className="cancelButton"
                                            variant="text"
                                            onClick={this.handleEditShipment}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="addButton"
                                            variant="contained"
                                            onClick={this.saveGiftDialog}
                                        >
                                            Save
                                        </Button>
                                    </>
                                    :
                                    <Button
                                        className="cancelButton"
                                        variant="text"
                                        onClick={this.closeGiftDialog}
                                    >
                                        Close
                                    </Button>
                                }
                                
                            </DialogActions>
                        </div>
                    </Dialog>
                </div>
            </>
        );
    }
}
