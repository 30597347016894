import React, { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";

export default class AlertDialog extends Component {
  constructor(props) {
    super(props);

    //get all the props that we pass in this component
  }

  componentDidMount = async (...args) => {
    console.log(this.props.dialogTitle);
  };

  render() {
    const {
      DialogOpen,
      dialogTitle,
      DialogMessage,
      DialogMessageSub,
      DialogButtonText,
      DialogButtonColor,
      DialogCloser,
      deleteUser,
      DialogEmail,
    } = this.props;

    return (
      <div>
        <Dialog
          id="AlertDialog"
          open={DialogOpen}
          onClose={DialogCloser}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle
            sx={{ backgroundColor: "#F1F2F6" }}
            className="DialogTitle"
            id="scroll-dialog-title"
          >
            <Typography sx={{ color: "#E5383B" }}>{dialogTitle}</Typography>
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              className="DialogContentText"
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              {DialogMessage}
              <Typography className="Typography">{DialogMessageSub}</Typography>
              <Typography className="Typography">{DialogEmail}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#F1F2F6" }}>
            <Button
              className="Button"
              sx={{ color: "#E5383B" }}
              onClick={DialogCloser}
            >
              Cancel
            </Button>
            <Button
              className="Button"
              variant="contained"
              color={DialogButtonColor}
              onClick={deleteUser}
            >
              {DialogButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
