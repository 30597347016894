import React, { Component } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export default class SearchBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFocused: false
        }
    }

    enterToSearch = (e) => {
        if (e.key == 'Enter' && this.state.isFocused) {
            this.props.handleTextChange(e);
        }
    }

    async componentDidMount() {
        window.addEventListener('keydown', this.enterToSearch);
    }

    async componentWillUnmount() {
        window.removeEventListener('keydown', this.enterToSearch);
    }

    setFocused = () => {
        this.setState({isFocused: true});
    }

    setNotFocused = () => {
        this.setState({isFocused: false});
    }

    render() {
        return (
            <Box id="searchBox">
                <Typography className="title">Search</Typography>
                <TextField
                    className="textField"
                    disabled={this.props.disabled}
                    hiddenLabel
                    onFocus={this.setFocused}
                    onBlur={this.setNotFocused}
                    id="userSearchBox"
                    size="small"
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                    }}
                />
            </Box>
        )
    }
}