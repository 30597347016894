import BasePageComponent from "../../../components/BasePageComponent";
import "./LexSeekerProfile.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";

import AppBar from "@mui/material/AppBar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Toolbar from "@mui/material/Toolbar";

import moment from "moment";

import RequestType from "../../../utils/RequestType";
import { initiateCall } from "../../../utils/ApiHandler";

export default class LexSeekerProfile extends BasePageComponent {
  constructor(props) {
    super(props);

    if (this.props.history.location.state === undefined) {
      window.location.assign("/lexseeker");
    }

    this.prevState = this.props.history.location.state;
    this.state = {
      educationalData: { rows: [], columns: [] },
      jobData: { rows: [], columns: [] },
      qualitiesData: [],
      skillsData: [],
      cultureData: [],
      desiredRolesData: [],
      areaResidenceData: [],
      shareProfile: true,
      emailNotification: true,
    };
  }

  async componentDidMount() {
    // Process all necessary data to show on user profile
    const processedEducationData = this.processRowData(
      this.prevState.education
    );
    const processedJobData = this.processRowData(this.prevState.jobexperience);
    const processedQualitiesData = this.processListData(
      this.prevState.qualities
    );
    const processedSkillsData = this.processListData(this.prevState.skillsets);
    const processedCultureData = this.processListData(this.prevState.idealwork);
    const processedDesiredRolesData = this.processListData(
      this.prevState.desiredjob
    );
    const processedAreaResidenceData = this.processListData(
      this.prevState.arearesidence
    );
    this.setState({
      educationalData: {
        rows: processedEducationData,
        columns: this.educationColumns,
      },
    });
    this.setState({
      jobData: {
        rows: processedJobData,
        columns: this.jobColumns,
      },
    });
    this.setState({ qualitiesData: processedQualitiesData });
    this.setState({ skillsData: processedSkillsData });
    this.setState({ cultureData: processedCultureData });
    this.setState({ desiredRolesData: processedDesiredRolesData });
    this.setState({ areaResidenceData: processedAreaResidenceData });

    if (this.prevState.shareprofile !== 1) {
      this.setState({ shareProfile: false });
    }

    if (this.prevState.emailnotification !== 1) {
      this.setState({ emailNotification: false });
    }
  }

  /**
   * Processes row data (specifically education and job).
   * @param {JSON} dataObj
   */
  processRowData(dataObj) {
    let processedData = [];
    for (const key in dataObj) {
      const value = dataObj[key];
      value["id"] = key;
      processedData.push(value);
    }
    return processedData;
  }

  /**
   * Processes list data (specifically qualities, skillsets, ideal work culture and desired roles).
   * @param {JSON} dataObj
   */
  processListData(dataObj) {
    let qualitiesArr = [];
    for (const key in dataObj) {
      qualitiesArr.push(<div className="HighlightText">{dataObj[key]}</div>);
    }
    return qualitiesArr;
  }

  educationColumns = [
    {
      field: "school",
      headerName: "School",
      width: 150,
      flex: 1,
    },
    {
      field: "certification",
      headerName: "Certification",
      width: 150,
      flex: 1,
    },
    {
      field: "fieldOfStudy",
      headerName: "Field of Study",
      width: 110,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 110,
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 110,
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) {
          return <div>-</div>;
        } else {
          return <div>{moment(params.value).format("DD-MM-YYYY")}</div>;
        }
      },
    },
  ];

  jobColumns = [
    {
      field: "company",
      headerName: "Company",
      width: 150,
      flex: 1,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 150,
      flex: 1,
    },
    {
      field: "employmentType",
      headerName: "Employment Type",
      width: 110,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 110,
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 110,
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) {
          return <div>-</div>;
        } else {
          return <div>{moment(params.value).format("DD-MM-YYYY")}</div>;
        }
      },
    },
  ];

  onShareChange = async (event) => {
    this.setState({ shareProfile: event.target.checked });
    var status;

    if (event.target.checked === true) {
      status = 1;
    } else if (event.target.checked === false) {
      status = 0;
    }

    const data = {
      id: this.prevState.id,
      status: status,
      usertype: "lexseeker",
    };

    let res = await initiateCall(
      RequestType.POST,
      `/updateShareProfileById`,
      data
    );

    if (res.data.message === "success") {
      this.setState({ shareProfile: event.target.checked });
    }
  };

  onEmailChange = async (event) => {
    this.setState({ emailNotification: event.target.checked });
    var status;

    if (event.target.checked === true) {
      status = 1;
    } else if (event.target.checked === false) {
      status = 0;
    }

    const data = {
      id: this.prevState.id,
      status: status,
      usertype: "lexseeker",
    };

    let res = await initiateCall(
      RequestType.POST,
      `/updateEmailNotificationById`,
      data
    );

    if (res.data.message === "success") {
      this.setState({ emailNotification: event.target.checked });
    }
  };

  async downloadResume() {
    const id = this.prevState.id;

    const res = await initiateCall(RequestType.POST, "/downloadResume", {
      id: id,
    });

    if (res) {
      const linkSource = res.data.data;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      //downloadLink.href = "data:text/plain;base64," + linkSource; //for zip
      downloadLink.download = res.data.message;
      downloadLink.click();
    }
  }

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
              <Grid className="firstrow" md={12}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{ fontWeight: "bold" }}
                >
                  <a href="/lexseeker" className="breadLink">
                    Lexseeker List
                  </a>
                </Typography>
                <KeyboardArrowRightIcon
                  style={{ color: "grey" }}
                  sx={{ fontSize: "25px", fontWeight: "bold" }}
                />
                <Typography
                  color="black"
                  variant="h6"
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {this.prevState.fullname}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <div className="LexSeekerProfile">
          <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <div style={{ display: "flex" }}>
                {/*<text className="titleNumber">1</text>*/}
                <text className="title">Personal Information</text>
              </div>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={3}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Full Name</Typography>
                    <Typography>
                      {this.prevState.fullname
                        ? this.prevState.fullname
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Email</Typography>
                    <Typography>
                      {this.prevState.useremail
                        ? this.prevState.useremail
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Gender</Typography>
                    <Typography>
                      {this.prevState.gender ? this.prevState.gender : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Date of Birth</Typography>
                    <Typography>
                      {this.prevState.prettydate
                        ? this.prevState.prettydate
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Contact Number</Typography>
                    <Typography>
                      {this.prevState.contactnum
                        ? this.prevState.contactnum
                        : "N/A"}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <Typography className="label">Nationality</Typography>
                        <Typography>
                          {this.prevState.nationality
                            ? this.prevState.nationality
                            : "N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Citizenship</Typography>
                        <Typography>
                          {this.prevState.citizenship
                            ? this.prevState.citizenship
                            : "N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">
                          Currently Residing In
                        </Typography>
                        <Typography>
                          {this.prevState.currentresidence}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Type of Pass</Typography>
                        <Typography>
                          {this.prevState.typeofpass
                            ? this.prevState.typeofpass
                            : "N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Occupation</Typography>
                        <Typography>
                          {this.prevState.occupation
                            ? this.prevState.occupation
                            : "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="label">Address</Typography>
                        <Typography>
                          {this.prevState.address
                            ? this.prevState.address
                            : "123 Happy Street Happy Condo #01-01"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Postal Code</Typography>
                        <Typography>
                          {this.prevState.zipcode
                            ? this.prevState.zipcode
                            : "12345"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">
                          Area of Residence
                        </Typography>
                        <div class="HighlightContainer">
                          {this.state.areaResidenceData}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">
                      Desired Job Role(s)
                    </Typography>

                    <div class="HighlightContainer">
                      {this.state.desiredRolesData}
                    </div>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Expected Salary</Typography>
                    <Typography>
                      $
                      {this.prevState.idealsalarymax
                        ? this.prevState.idealsalarymin
                          ? this.prevState.idealsalarymin +
                            " - $" +
                            this.prevState.idealsalarymax
                          : this.prevState.idealsalarymax
                        : this.prevState.idealsalarymin
                        ? this.prevState.idealsalarymin
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Resume</Typography>
                    <Typography
                      onClick={() => this.downloadResume()}
                      sx={{ cursor: "pointer", color: "#2964F5" }}
                    >
                      View Resume
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">
                      Cover Letter (Optional)
                    </Typography>
                    <Typography>No file uploaded</Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ marginBottom: "40px" }} />

          <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <div style={{ display: "flex" }}>
                {/*<text className="titleNumber">2</text>*/}
                <text className="title">Education Qualifications</text>
              </div>
            </Grid>
            <Grid container xs={12}>
              <DataGrid
                disableColumnSelector
                sx={{ bgcolor: "white", borderRadius: 3 }}
                rows={this.state.educationalData.rows}
                columns={this.state.educationalData.columns}
                pageSize={this.state.educationalData.rows.length}
                rowsPerPageOptions={[this.state.educationalData.rows.length]}
                disableSelectionOnClick
                autoHeight={true}
                hideFooter={true}
              />
            </Grid>
          </Grid>

          <div style={{ marginBottom: "40px" }} />

          <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <div style={{ display: "flex" }}>
                {/*<text className="titleNumber">3</text>*/}
                <text className="title">Job Experiences</text>
              </div>
            </Grid>
            <Grid container xs={12}>
              <DataGrid
                disableColumnSelector
                sx={{ bgcolor: "white", borderRadius: 3 }}
                rows={this.state.jobData.rows}
                columns={this.state.jobData.columns}
                pageSize={this.state.jobData.rows.length}
                rowsPerPageOptions={[this.state.jobData.rows.length]}
                disableSelectionOnClick
                autoHeight={true}
                hideFooter={true}
              />
            </Grid>
          </Grid>

          <div style={{ marginBottom: "40px" }} />

          <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <div style={{ display: "flex" }}>
                {/*<text className="titleNumber">4</text>*/}
                <text className="title">
                  Qualities, Skillsets, Work Culture
                </text>
              </div>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={4}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Qualities</Typography>

                    <div class="HighlightContainer">
                      {this.state.qualitiesData}
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Skillsets</Typography>

                    <div class="HighlightContainer">
                      {this.state.skillsData}
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">
                      Ideal Work Culture
                    </Typography>

                    <div class="HighlightContainer">
                      {this.state.cultureData}
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ marginBottom: "40px" }} />
          <Divider />
          <div style={{ marginBottom: "40px" }} />

          <div style={{ display: "flex" }}>
            <Typography sx={{ flex: 1 }}>
              I would like my profile and its details to be searchable and
              accessible to 3rd party employers.
              <Typography>(You may change your mind any time)</Typography>
            </Typography>
            <Switch
              sx={{ float: "right" }}
              checked={this.state.shareProfile}
              onChange={this.onShareChange}
            />
          </div>

          <div style={{ marginBottom: "40px" }} />
          <Divider />
          <div style={{ marginBottom: "40px" }} />

          <div style={{ display: "flex" }}>
            <Typography sx={{ flex: 1 }}>
              Receive emails from LeXisJobs.
            </Typography>
            <Switch
              sx={{ float: "right" }}
              checked={this.state.emailNotification}
              onChange={this.onEmailChange}
            />
          </div>

          <div style={{ marginBottom: "40px" }} />
          <Divider />
          <div style={{ marginBottom: "40px" }} />
        </div>
      </>
    );
  }
}
