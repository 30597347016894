import React, { Component } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default class DropDown extends Component {
  constructor(props) {
    super(props);
  }

  //active
  deactivate = [
    {
      value: "Deactivate",
      dialogHeader: "Deactivate Job Post?",
      dialogContent: (
        <div style={{ fontSize: "14px" }}>
          Once this job post has been deactivated, it will no longer be
          accessible to Lexeekers <br />{" "}
          <p>
            You may reactivate this job post again by heading to:{" "}
            <b>'Inactive' Tab &gt; Select job post &gt; Reactivate</b>
          </p>
        </div>
      ),
      dialogInstructions: " ",
      buttonText: "Deactivate",
    },
    { value: "Edit" },
  ];

  //inactive
  reactivate = [
    {
      value: "Reactivate",
      dialogHeader: "Reactivate Job Post?",
      dialogContent: (
        <div style={{ fontSize: "14px" }}>
          Once this job post has been reactivated, it will be accessible to
          Lexeekers again. <br />
          This job post will be activate for the next 2 months before it will be
          deactivated automatically.
        </div>
      ),
      buttonText: "Reactivate",
    },
    { value: "Edit" },
  ];

  //reported
  reported = [
    {
      value: "Deactivate",
      dialogHeader: "Deactivate Job Post?",
      dialogContent: (
        <div style={{ fontSize: "14px" }}>
          Once this job post has been deactivated, it will no longer be
          accessible to Lexeekers <br />{" "}
          <p>
            You may reactivate this job post again by heading to:{" "}
            <b>'Inactive' Tab &gt; Select job post &gt; Reactivate</b>
          </p>
        </div>
      ),
      buttonText: "Deactivate",
    },
    { value: "Edit" },
    {
      value: "Ok to Go",
      dialogHeader: "Ok to Go?",
      dialogContent:
        "This is to confirm that there in nothing wrong with the job post. No further action in needed.",
      buttonText: "Submit",
    },
    {
      value: "Against Policy",
      dialogHeader: "Against Policy",
      showTextArea: true,
      dialogContent:
        "Job post will be deactivated and lexployer will be notified that their job post  has been reported. They will be required to make amendments accordingly.",
      buttonText: "Submit",
    },
  ];

  againstPolicy = [
    {
      value: "Send Reminder",
      dialogHeader: "Send Reminder ?",
      dialogContent: "This will send an Email reminder to Lexployer.",
      buttonText: "Reminder",
    },
    { value: "Edit" },
  ];

  render() {
    return (
      <div>
        <Menu
          anchorEl={this.props.anchorEl}
          id="account-menu"
          open={this.props.open}
          onClose={this.props.onClose}
          onClick={this.props.onClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {this.props.selectStatus == "Deactivate" &&
            this.deactivate.map((item) => {
              return (
                <MenuItem onClick={() => this.props.selectDialog(item)}>
                  {item.value}
                </MenuItem>
              );
            })}

          {this.props.selectStatus == "Reactivate" &&
            this.reactivate.map((item) => {
              return (
                <MenuItem onClick={() => this.props.selectDialog(item)}>
                  {item.value}
                </MenuItem>
              );
            })}
          {this.props.selectStatus == "Reported" &&
            this.reported.map((item) => {
              return (
                <>
                  {item.value == "Ok to Go" && (
                    <MenuItem
                      style={{ color: "blue" }}
                      onClick={() => this.props.selectDialog(item)}
                    >
                      {item.value}
                    </MenuItem>
                  )}
                  {item.value == "Against Policy" && (
                    <MenuItem
                      style={{ color: "red" }}
                      onClick={() => this.props.selectDialog(item)}
                    >
                      {item.value}
                    </MenuItem>
                  )}
                  {item.value != "Ok to Go" &&
                    item.value != "Against Policy" && (
                      <MenuItem onClick={() => this.props.selectDialog(item)}>
                        {item.value}
                      </MenuItem>
                    )}
                </>
              );
            })}

          {this.props.selectStatus == "Against Policy" &&
            this.againstPolicy.map((item) => {
              return (
                <MenuItem onClick={() => this.props.selectDialog(item)}>
                  {item.value}
                </MenuItem>
              );
            })}
        </Menu>
      </div>
    );
  }
}
