import BasePageComponent from "../../components/BasePageComponent";
import "./Dashboard.scss";
import {
  Chart,
  Legend,
  BarSeries,
  PieSeries,
  ArgumentAxis,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Palette, Stack } from "@devexpress/dx-react-chart";
import { initiateCall } from "../../utils/ApiHandler";
import RequestType from "../../utils/RequestType";
import BarChartDataMenu from "./BarChartDataMenu";
import BarChartYearMenu from "./BarChartYearMenu";

import Chip from "@mui/material/Chip";
import StackChip from "@mui/material/Stack";

import { exportAsDashboardExcel, exportEmail } from "../../utils/ExcelExporter";

import React from "react";
import {
  Button,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Tabs,
  Tab,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DropdownStatus from "./DropdownStatus";
import SearchBox from "./SearchBox";

const getUniqueYear = () => {
  const currentYear = new Date().getFullYear();
  const uniqueyear = [];
  for (let i = 3; i >= 0; i--) {
    uniqueyear.push(currentYear - i);
  }
  return uniqueyear;
};

export default class Dashboard extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,

      lexSeekerData: [
        { Status: "Activated", count: 0 },
        { Status: "Deactivated", count: 0 },
        { Status: "Suspended", count: 0 },
        { Status: "Deleted", count: 0 },
      ],
      lexPloyerData: [
        { Status: "Activated", count: 0 },
        { Status: "Deactivated", count: 0 },
        { Status: "Suspended", count: 0 },
        { Status: "Deleted", count: 0 },
      ],
      jobPostsData: [
        { Status: "Active", count: 0 },
        { Status: "Inactive", count: 0 },
        { Status: "Reported", count: 0 },
        { Status: "Against Policy", count: 0 },
      ],
      personalPlansData: [
        { Status: "Lite", count: 0 },
        { Status: "Pro", count: 0 },
        { Status: "Pro+", count: 0 },
        { Status: "Pro Unlimited", count: 0 },
      ],
      corporatePlansData: [
        { Status: "CPro", count: 0 },
        { Status: "CPremium", count: 0 },
      ],
      summaryData: [
        { Status: "Free Users", count: 0 },
        { Status: "Paid Users", count: 0 },
        { Status: "Corporate Plans", count: 0 },
        { Status: "Corporate Users", count: 0 },
      ],
      selectedBarChartTitle: "LexSeekers",
      selectedBarChartData: [],
      selectedBarChartPalette: this.lexSeekerPalette,
      selectedBarChartYear: new Date().getFullYear(),

      dropdownBarYear: getUniqueYear(),

      lexisCardBarChartData: [],
      allBarChartData: [],

      usertype: "",
      permission: {},
      currentIndex: 0,
      datagridCol: [],
      datagridRow: [],
      status: 1,
      dataPageSize: 10,
      dataPage: 0,
      dataTotal: 0,
      sort: "asc",
      field: "name",
      searchValue: "",
      dataLoading: false,
    };
  }

  lexSeekerPalette = ["#2964F5", "#ADC5FF", "#D6E2FF", "#E5383B"];
  lexPloyerPalette = ["#FF5666", "#FFBFC5", "#FFDCE0", "#E5383B"];
  jobPostsPalette = ["#383737", "#666666", "#C0C0C0", "#E5383B"];

  personalPlansPalette = ["#2964F5", "#ADC5FF", "#D6E2FF", "#ECF1FD"];
  corporatePlansPalette = ["#FF5666", "#FFBFC5", "#FFDCE0", "#FEEDEF"];

  LexisCardBarChartPalette = ["#2964F5", "#ADC5FF", "#D6E2FF", "#ECF1FD"];

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    //await this.updatePieChartData("signuplexseeker");
    //await this.updatePieChartData("signuplexployer");
    //await this.updatePieChartData("jobposting");
    //await this.updateBarChartData(this.state.selectedBarChartTitle);

    // consider to comment out for faster on load
    //await this.updatePieChartData("namecards", "pp");
    //await this.updatePieChartData("namecards", "cp");
    //await this.updateBarChartData("LexisCard");

    let userdata = JSON.parse(window.localStorage.getItem("@userdata"));

    await this.getPerm(userdata.value.usertype);
    this.checkDashboardPerm();
  };

  checkDashboardPerm = async () => {
    console.log(this.state.permission);
    let perm = this.state.permission;
    if (perm.lexisjobsdb) {
      await this.getLexisJobsData();
    } else if (perm.lexiscardsdb) {
      this.setState({ currentTab: 1 });
      await this.getLexisCardData();
    }
  };

  getPerm = async (type) => {
    const res = await initiateCall(RequestType.POST, "/getPermission", {
      type: type,
    });
    console.log(res.data);

    if (res && res.data.message === "success") {
      this.setState({ permission: res.data.data[0].permission.dashboard });
    }
  };

  labelComponent(elem, data, props) {
    if (elem == null) {
      return <Legend.Label {...props} text={props.text} />;
    }

    var item = data.find((item) => item.Status === props.text);

    return (
      <Legend.Label
        {...props}
        text={
          <div
            style={{
              width: elem.clientWidth - 65,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {props.text}
            <span style={{ fontWeight: "bold" }}>{item.count}</span>
          </div>
        }
      />
    );
  }

  handleBarChartDataChange = async (event) => {
    const type = event.target.value;
    await this.setState({
      selectedBarChartTitle: type,
      selectedBarChartYear: new Date().getFullYear(),
      selectedBarChartData: this.getSelectedBarChartData(
        this.state.allBarChartData,
        type,
        new Date().getFullYear()
      ),
    });

    if (type === "LexSeekers") {
      this.setState({ selectedBarChartPalette: this.lexSeekerPalette });
    } else if (type === "LexPloyers") {
      this.setState({ selectedBarChartPalette: this.lexPloyerPalette });
    } else if (type === "Job Posts") {
      this.setState({ selectedBarChartPalette: this.jobPostsPalette });
    }
  };

  handleBarChartYearChange = async (event) => {
    const year = event.target.value;
    if (this.state.currentTab === 0) {
      this.setState({
        selectedBarChartYear: year,
        selectedBarChartData: this.getSelectedBarChartData(
          this.state.allBarChartData,
          undefined,
          year
        ),
      });
    } else if (this.state.currentTab === 1) {
      this.setState({
        selectedBarChartYear: year,
        lexisCardBarChartData: this.state.allBarChartData.filter(
          (x) => x.year === year
        ),
      });
    }
  };

  getLexisCardData = async () => {
    const res = await initiateCall(
      RequestType.POST,
      "/dashboard-data-lexiscard"
    );
    const data = res.data.data;

    const barChartYear = data["barchartData"].filter(
      (x) => x.year === this.state.selectedBarChartYear
    );
    this.setState({
      allBarChartData: data["barchartData"],
      lexisCardBarChartData: barChartYear,
      // selectedBarChartData: data["barchartData"],
      personalPlansData: data["personalPlan"],
      corporatePlansData: data["corporatePlan"],
      summaryData: data["summaryData"],
    });
  };
  getSelectedBarChartData = (
    data,
    type = this.state.selectedBarChartTitle,
    year = this.state.selectedBarChartYear
  ) => {
    if (type === "LexSeekers") {
      return data["lexseeker"].filter((x) => x.year === year);
    } else if (type === "LexPloyers") {
      return data["lexployer"].filter((x) => x.year === year);
    } else if (type === "Job Posts") {
      return data["jobpost"].filter((x) => x.year === year);
    }
    return [];
  };
  getLexisJobsData = async () => {
    const res = await initiateCall(
      RequestType.POST,
      "/dashboard-data-lexisjobs"
    );
    const data = res.data.data;
    this.setState({
      allBarChartData: data["barchartData"],
      selectedBarChartData: this.getSelectedBarChartData(data["barchartData"]),
      lexSeekerData: data["totalLexseekers"],
      lexPloyerData: data["totalLexployers"],
      jobPostsData: data["totalJobPosts"],
    });
  };

  getBarChartCardWidth() {
    const elem = document.getElementById("barChart");
    if (elem == null) {
      return "85%";
    }
    return elem.clientWidth - 280;
  }

  setCurrentTab = async (num) => {
    if (this.state.currentTab !== num) {
      this.setState({ currentTab: this.state.currentTab ? 0 : 1 });
    }

    if (num === 1) {
      await this.getLexisCardData();
    } else {
      await this.setState({
        selectedBarChartTitle: "LexSeekers",
        selectedBarChartPalette: this.lexSeekerPalette,
        selectedBarChartYear: new Date().getFullYear(),
      });
      await this.getLexisJobsData();
    }
  };

  changeTabIndex = (value) => {
    this.setState({ currentIndex: value });
    if (value === 0) {
      this.getLexisCardData();
    } else if (value === 1) {
      this.getCorporateData(1);
    }
  };

  exportFunc = async () => {
    if (this.state.currentTab === 0) {
      await exportAsDashboardExcel(
        "Dashboard LexisJobs",
        this.state.currentTab
      );
    } else {
      await exportAsDashboardExcel(
        "Dashboard LexisCard",
        this.state.currentTab
      );
    }
  };

  exportEmailData = async () => {
    await exportEmail();
  };

  getCorporateData = async (
    status = this.state.status,
    search = this.state.searchValue,
    page = this.state.dataPage,
    pageSize = this.state.dataPageSize,
    field = this.state.field,
    sort = this.state.sort
  ) => {
    this.setState({
      dataLoading: true,
    });
    const res = await initiateCall(RequestType.POST, "/coporate-datagrid", {
      status: status,
      search: search,
      page: page,
      pageSize: pageSize,
      sort: sort,
      field: field,
    });
    const data = res.data.data;
    const count = res.data.count;
    // console.log(data)

    let columns = [
      { field: "name", headerName: "Name", width: 350 },
      { field: "email", headerName: "EMAIL", width: 250 },
      { field: "uen", headerName: "UEN", width: 120 },
      { field: "website", headerName: "Website", width: 200 },
      {
        field: "user_subscription",
        headerName: "Users Subscription",
        width: 100,
      },
      { field: "users", headerName: "No. of Users" },
      { field: "active_users", headerName: "Active Users" },
      { field: "status", headerName: "Status", width: 80 },
      { field: "subscription", headerName: "Subscription", width: 100 },
      { field: "createdon", headerName: "Signup Date", width: 100 },
      { field: "subscription_exp", headerName: "Expiry Date", width: 100 },
    ];

    let rows = data;

    this.setState({
      datagridCol: columns,
      datagridRow: rows,
      dataTotal: count,
      dataLoading: false,
    });
  };

  handleSortModelChange = (event) => {
    if (event[0]) {
      let field = event[0].field;
      let sort = event[0].sort;

      this.setState({
        sort: sort,
        field: field,
      });

      this.getCorporateData(
        undefined,
        undefined,
        undefined,
        undefined,
        field,
        sort
      );
    }
  };

  dropdownStatusChange = (event) => {
    this.getCorporateData(event.target.value, "", 0, 10);
    this.setState({
      status: event.target.value,
      dataPage: 0,
      dataPageSize: 10,
      searchValue: "",
    });
  };

  searchBoxChange = (event) => {
    this.setState({
      searchValue: event.target.value,
      dataPage: 0,
      dataPageSize: 10,
    });

    clearTimeout(this.searchFilterRef);
    this.searchFilterRef = setTimeout(
      () => this.getCorporateData(this.state.status, event.target.value, 0, 10),
      700
    );
  };
  render() {
    const lexSeekerData = this.state.lexSeekerData;
    const lexPloyerData = this.state.lexPloyerData;
    const jobPostsData = this.state.jobPostsData;
    const personalPlansData = this.state.personalPlansData;
    const corporatePlansData = this.state.corporatePlansData;

    const lexSeekerCount =
      Number(lexSeekerData[0].count) +
      Number(lexSeekerData[1].count) +
      Number(lexSeekerData[2].count) +
      Number(lexSeekerData[3].count);
    const lexPloyerCount =
      Number(lexPloyerData[0].count) +
      Number(lexPloyerData[1].count) +
      Number(lexPloyerData[2].count) +
      Number(lexPloyerData[3].count);
    const jobPostsCount =
      Number(jobPostsData[0].count) +
      Number(jobPostsData[1].count) +
      Number(jobPostsData[2].count) +
      Number(jobPostsData[3].count);
    const personalPlansCount =
      Number(personalPlansData[0].count) +
      Number(personalPlansData[1].count) +
      Number(personalPlansData[2].count) +
      Number(personalPlansData[3].count);
    const corporatePlansCount =
      Number(corporatePlansData[0].count) + Number(corporatePlansData[1].count);

    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={9} sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{ fontWeight: "bold" }}
                >
                  Dashboard
                </Typography>
                <StackChip direction="row" className="stackChip" spacing={1}>
                  {this.state.permission?.lexisjobsdb && (
                    <Chip
                      label="LexisJobs"
                      className={
                        this.state.currentTab === 0 ? "selectedChip" : "chip"
                      }
                      onClick={() => this.setCurrentTab(0)}
                    />
                  )}
                  {this.state.permission?.lexisjobsdb && (
                    <Chip
                      label="LexisCard"
                      variant="outlined"
                      className={
                        this.state.currentTab === 1 ? "selectedChip" : "chip"
                      }
                      onClick={() => this.setCurrentTab(1)}
                    />
                  )}
                </StackChip>
              </Grid>
              <Grid item xs={3} sx={{ margin: "auto 0" }}>
                <Typography
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{
                    color: "blue",
                    cursor: "pointer",
                    float: "right",
                    fontSize: 15,
                  }}
                  onClick={() => this.exportFunc()}
                >
                  Export as Excel
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        {this.state.currentTab === 0 && (
          <div className="Dashboard">
            <div className="bar">
              <div className="barItem">
                <Paper id="barChart">
                  <div className="cardTitle">
                    <div className="barTitle">
                      <p>Overview</p>
                    </div>
                    <div
                      style={{
                        width: this.getBarChartCardWidth(),
                        justifyContent: "space-between",
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                      <div className="dataMenu">
                        <BarChartDataMenu
                          selectedBarChartTitle={
                            this.state.selectedBarChartTitle
                          }
                          handleBarChartDataChange={
                            this.handleBarChartDataChange
                          }
                        ></BarChartDataMenu>
                      </div>
                      <div className="yearMenu">
                        <BarChartYearMenu
                          drpMenu={this.state.dropdownBarYear}
                          selectedType={this.state.selectedBarChartTitle}
                          selectedBarChartYear={this.state.selectedBarChartYear}
                          handleBarChartYearChange={
                            this.handleBarChartYearChange
                          }
                        ></BarChartYearMenu>
                      </div>
                    </div>
                  </div>
                  {(this.state.selectedBarChartTitle == "LexSeekers" ||
                    this.state.selectedBarChartTitle == "LexPloyers") && (
                    <Chart data={this.state.selectedBarChartData}>
                      <Palette scheme={this.state.selectedBarChartPalette} />
                      <ArgumentAxis />
                      <ValueAxis max={7} />
                      <BarSeries
                        name="Activated"
                        valueField="activated"
                        argumentField="month"
                      />
                      <BarSeries
                        name="Deactivated"
                        valueField="deactivated"
                        argumentField="month"
                      />
                      <BarSeries
                        name="Suspended"
                        valueField="suspended"
                        argumentField="month"
                      />
                      <BarSeries
                        name="Deleted"
                        valueField="deleted"
                        argumentField="month"
                      />
                      <Stack
                        stacks={[
                          {
                            series: [
                              "Activated",
                              "Deactivated",
                              "Suspended",
                              "Deleted",
                            ],
                          },
                        ]}
                      />
                      {/*<Animation />*/}
                      <Legend position="right" />
                    </Chart>
                  )}
                  {this.state.selectedBarChartTitle == "Job Posts" && (
                    <Chart data={this.state.selectedBarChartData}>
                      <Palette scheme={this.state.selectedBarChartPalette} />
                      <ArgumentAxis />
                      <ValueAxis max={7} />
                      <BarSeries
                        name="Active"
                        valueField="active"
                        argumentField="month"
                      />
                      <BarSeries
                        name="Inactive"
                        valueField="inactive"
                        argumentField="month"
                      />
                      <BarSeries
                        name="Reported"
                        valueField="reported"
                        argumentField="month"
                      />
                      <BarSeries
                        name="Against Policy"
                        valueField="against"
                        argumentField="month"
                      />
                      <Stack
                        stacks={[
                          {
                            series: [
                              "Active",
                              "Inactive",
                              "Reported",
                              "Against Policy",
                            ],
                          },
                        ]}
                      />
                      {/*<Animation />*/}
                      <Legend position="right" />
                    </Chart>
                  )}
                </Paper>
              </div>
            </div>
            <div className="pie">
              <div className="pieItem">
                <Paper style={{ borderRadius: 10 }} id="lexSeekerChart">
                  <div className="cardTitle">
                    <div className="">
                      <p className="noText">Total Number of LexSeekers</p>
                    </div>
                    <div className="">
                      <p className="no" style={{ fontWeight: "bold" }}>
                        {lexSeekerCount}
                      </p>
                    </div>
                  </div>
                  <Chart data={this.state.lexSeekerData}>
                    <Palette scheme={this.lexSeekerPalette} />
                    <PieSeries valueField="count" argumentField="Status" />
                    {/*<Animation />*/}
                    <Legend
                      position="bottom"
                      labelComponent={this.labelComponent.bind(
                        this,
                        document.getElementById("lexSeekerChart"),
                        this.state.lexSeekerData
                      )}
                    />
                  </Chart>
                </Paper>
              </div>

              <div className="pieItem">
                <Paper style={{ borderRadius: 10 }} id="lexPloyerChart">
                  <div className="cardTitle">
                    <div className="">
                      <p className="noText">Total Number of LexPloyers</p>
                    </div>
                    <div className="">
                      <p className="no" style={{ fontWeight: "bold" }}>
                        {lexPloyerCount}
                      </p>
                    </div>
                  </div>
                  <Chart data={this.state.lexPloyerData}>
                    <Palette scheme={this.lexPloyerPalette} />
                    <PieSeries valueField="count" argumentField="Status" />
                    {/*<Animation />*/}
                    <Legend
                      position="bottom"
                      labelComponent={this.labelComponent.bind(
                        this,
                        document.getElementById("lexPloyerChart"),
                        this.state.lexPloyerData
                      )}
                    />
                  </Chart>
                </Paper>
              </div>

              <div className="pieItem">
                <Paper style={{ borderRadius: 10 }} id="jobPostsChart">
                  <div className="cardTitle">
                    <div className="">
                      <p className="noText">Total Number of Job Posts</p>
                    </div>
                    <div className="">
                      <p className="no" style={{ fontWeight: "bold" }}>
                        {jobPostsCount}
                      </p>
                    </div>
                  </div>
                  <Chart data={this.state.jobPostsData}>
                    <Palette scheme={this.jobPostsPalette} />
                    <PieSeries valueField="count" argumentField="Status" />
                    {/*<Animation />*/}
                    <Legend
                      position="bottom"
                      labelComponent={this.labelComponent.bind(
                        this,
                        document.getElementById("jobPostsChart"),
                        this.state.jobPostsData
                      )}
                    />
                  </Chart>
                </Paper>
              </div>
            </div>
          </div>
        )}

        {this.state.currentTab === 1 && (
          <div className="DashboardLexisCard">
            <Paper>
              <Grid container>
                <Grid item xs={10}>
                  <Tabs
                    //onChange={() => this.changeTabIndex()}
                    value={this.state.currentIndex}
                    className="tabs"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: this.state.color,
                      },
                    }}
                  >
                    <Tab
                      onClick={() => this.changeTabIndex(0)}
                      label={"Overview"}
                    />
                    <Tab
                      onClick={() => this.changeTabIndex(1)}
                      label={"Corporate Signup"}
                    />
                  </Tabs>
                </Grid>
              </Grid>
            </Paper>
            {this.state.currentIndex === 0 && (
              <Button
                onClick={() => this.exportEmailData()}
                variant="contained"
                sx={{
                  marginTop: 0.75,
                  textTransform: "none",
                  borderRadius: 40,
                  backgroundColor: "#2964f5",
                }}
              >
                Export emails
              </Button>
            )}

            {this.state.currentIndex === 0 ? (
              <>
                <div className="pie">
                  <div className="pieItem">
                    <Paper style={{ borderRadius: 10 }} id="personalPlansChart">
                      <div className="cardTitle">
                        <div className="">
                          <p className="noText">Personal Plans</p>
                        </div>
                        <div className="">
                          <p className="no" style={{ fontWeight: "bold" }}>
                            {personalPlansCount}
                          </p>
                        </div>
                      </div>
                      <Chart data={this.state.personalPlansData}>
                        <Palette scheme={this.personalPlansPalette} />
                        <PieSeries valueField="count" argumentField="Status" />
                        {/*<Animation />*/}
                        <Legend
                          position="bottom"
                          labelComponent={this.labelComponent.bind(
                            this,
                            document.getElementById("personalPlansChart"),
                            this.state.personalPlansData
                          )}
                        />
                      </Chart>
                    </Paper>
                  </div>

                  <div className="pieItem">
                    <Paper
                      style={{ borderRadius: 10 }}
                      id="corporatePlansChart"
                    >
                      <div className="cardTitle">
                        <div className="">
                          <p className="noText">Corporate Plans</p>
                        </div>
                        <div className="">
                          <p className="no" style={{ fontWeight: "bold" }}>
                            {corporatePlansCount}
                          </p>
                        </div>
                      </div>
                      <Chart data={this.state.corporatePlansData}>
                        <Palette scheme={this.corporatePlansPalette} />
                        <PieSeries valueField="count" argumentField="Status" />
                        {/*<Animation />*/}
                        <Legend
                          position="bottom"
                          labelComponent={this.labelComponent.bind(
                            this,
                            document.getElementById("corporatePlansChart"),
                            this.state.corporatePlansData
                          )}
                        />
                      </Chart>
                    </Paper>
                  </div>
                  <div className="pieItem">
                    <Paper style={{ borderRadius: 10 }}>
                      <div className="cardTitle">
                        <div>
                          <p className="noText">Summary</p>
                        </div>
                      </div>
                      <Chart data={this.state.summaryData}>
                        <Palette scheme={this.personalPlansPalette} />
                        <PieSeries valueField="count" argumentField="Status" />
                        {/*<Animation />*/}
                        <Legend
                          position="bottom"
                          labelComponent={this.labelComponent.bind(
                            this,
                            document.getElementById("personalPlansChart"),
                            this.state.summaryData
                          )}
                        />
                      </Chart>
                    </Paper>
                  </div>
                </div>
                <div className="bar">
                  <div className="barItem">
                    {this.state.currentIndex === 0 && (
                      <Paper id="barChart">
                        <div className="cardTitle">
                          <div
                            style={{
                              width: this.getBarChartCardWidth(),
                              justifyContent: "end",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <div className="yearMenu">
                              <BarChartYearMenu
                                drpMenu={this.state.dropdownBarYear}
                                selectedType={this.state.selectedBarChartTitle}
                                selectedBarChartYear={
                                  this.state.selectedBarChartYear
                                }
                                handleBarChartYearChange={
                                  this.handleBarChartYearChange
                                }
                              ></BarChartYearMenu>
                            </div>
                          </div>
                        </div>

                        <Chart data={this.state.lexisCardBarChartData}>
                          <Palette scheme={this.LexisCardBarChartPalette} />
                          <ArgumentAxis />
                          <ValueAxis max={7} />
                          <BarSeries
                            name="Personal Plans"
                            valueField="personal"
                            argumentField="month"
                          />
                          <BarSeries
                            name="Corporate Plans"
                            valueField="corporate"
                            argumentField="month"
                          />
                          <Stack
                            stacks={[
                              {
                                series: ["Personal Plans", "Corporate Plans"],
                              },
                            ]}
                          />
                          {/*<Animation />*/}
                          <Legend position="right" />
                        </Chart>
                      </Paper>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {this.state.currentIndex === 1 && (
              <Grid container spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={6}>
                  <DropdownStatus
                    status={this.state.status}
                    handleChange={this.dropdownStatusChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SearchBox
                    handleTextChange={this.searchBoxChange}
                    disabled={this.state.searchDisable}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataGrid
                    rows={this.state.datagridRow}
                    columns={this.state.datagridCol}
                    sx={{ bgcolor: "white" }}
                    loading={this.state.dataLoading}
                    autoHeight={true}
                    sortingMode="server"
                    onSortModelChange={this.handleSortModelChange}
                    disableColumnSelector
                    disableSelectionOnClick
                    rowsPerPageOptions={[10, 50, 100]}
                    rowCount={this.state.dataTotal}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                      this.setState({ dataPage: newPage });
                      this.getCorporateData(
                        this.state.status,
                        this.state.searchValue,
                        newPage
                      );
                    }}
                    onPageSizeChange={(newPageSize) => {
                      this.setState({ dataPageSize: newPageSize, dataPage: 0 });
                      this.getCorporateData(
                        this.state.status,
                        this.state.searchValue,
                        0,
                        newPageSize
                      );
                    }}
                    pageSize={this.state.dataPageSize}
                    page={this.state.dataPage}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </>
    );
  }
}
