/**
 * Contains list of allowable request types.
 */
class RequestType {
  static GET = Symbol("GET");
  static PUT = Symbol("PUT");
  static POST = Symbol("POST");
  static DELETE = Symbol("DELETE");
}

export default RequestType;