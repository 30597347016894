import React from "react";
import BasePageComponent from "../../components/BasePageComponent";
import "./LexisCard.scss";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import DropdownStatus from "./LexisCardComponents/DropdownStatus";
import SearchBox from "./LexisCardComponents/SearchBox";
import DropdownMenu from "./LexisCardComponents/DropdownMenu";
import AlertDialog from "./LexisCardComponents/AlertDialog";
import Notifications from "../../components/Notifications";
import RequestType from "../../utils/RequestType";
import { initiateCall } from "../../utils/ApiHandler";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem, Fade } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormLabel from "@mui/material/FormLabel";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from '@mui/material/CircularProgress';

import Select from '@mui/material/Select';

import { exportAsExcel } from "../../utils/ExcelExporter";
export default class LexisCard extends BasePageComponent {
  constructor(props) {
    super(props);
    this.searchFilterRef = React.createRef();
    this.state = {
      anchorEl: null,
      open: false,
      selectedUser: [],

      status: "-3",
      searchValue: "",
      searchDisable: false,
      dataLoading: false,
      dataRows: [],
      dataTotal: 0,
      dataPage: 0,
      dataPageSize: 10,

      DialogOpen: false,
      DialogTitle: "Title",
      DialogMessage: "Deactivate the following account?",
      DialogMessageTargetEmail: "",
      DialogButtonText: "Button",
      DialogButtonColor: "primary",

      sorting: [{ field: "createdon", sort: "desc" }],

      selectedUsers: [],

      dropDownAnchorEl: null,
      dropdownOpen: false,


      openAddDialog: false,
      addName: "",
      addEmail: "",
      addPlan: "lite",
      isAdding: false
    };
  }

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue, this.state.sorting);
  };

  fetchData = async (
    page = null,
    pageSize = null,
    status = null,
    search = null,
    sort = null
  ) => {
    this.setState({ dataLoading: true, searchDisable: true });

    const res = await initiateCall(RequestType.POST, `/lexisCardListing`, {
      tablename: "namecards",
      page: page,
      pageSize: pageSize,
      search: search,
      status: status,
      sortModel: sort,
    });

    if (res) {
      this.setState({
        dataRows: res.data.data,
        dataTotal: parseInt(res.data.total),
      });
    } else {
      this.setState({ dataRows: [], dataTotal: 0 });
    }
    this.setState({ dataLoading: false, searchDisable: false });

    if (search != null) {
      document.getElementById("userSearchBox").focus();
    }
  };

  columns = [
    {
      field: "useremail",
      headerName: "Email",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              color: "blue",
              wordBreak: "break-word",
              whiteSpace: "initial",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "createdon",
      headerName: "Date Joined",
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "refcode",
      headerName: "Referral Code",
      flex: 1,
    },
    {
      field: "redemption_code",
      headerName: "Redemption Code",
      flex: 1,
    },
    {
      field: "refby",
      headerName: "Referred By",
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) {
          return <div>-</div>;
        } else {
          return <div>{params.value}</div>;
        }
      },
    },
    {
      field: "refcount",
      headerName: "No. of Refers",
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) {
          return <div>0</div>;
        } else {
          return <div>{params.value}</div>;
        }
      },
    },
    {
      field: "subscription",
      headerName: "Subscription",
      flex: 1,
      renderCell: (params) => {
        if (params.value === "proUnli") {
          return (
            <div style={{ textTransform: "capitalize" }}>Pro Unlimited</div>
          );
        } else if (params.value === "cPrem") {
          return (
            <div style={{ textTransform: "capitalize" }}>Corporate Premium</div>
          );
        } else {
          return (
            <div style={{ textTransform: "capitalize" }}>{params.value}</div>
          );
        }
      },
    },
    {
      field: "subscription_exp",
      headerName: "Subscription Expiry",
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "subscription_meta",
      headerName: "Paid by",
      flex: 1,
      valueGetter: (params) => {
        let name = '';
        if(params.value){
          if(params.row?.subscription !== 'lite' && params.value?.isActive && params.value?.platform.toLowerCase() === 'huawei'){
            name = 'Huawei IAP';
          } else if(params.row?.subscription !== 'lite' && params.value?.isActive && (params.value?.platform.toLowerCase() === 'android' || params.value?.platform.toLowerCase() === 'ios')){
            name = 'RevenueCat';
          }
        } else if(params.row?.subscription !== 'lite' && params.row?.redemption_code === ''){
          name = 'RevenueCat';
        }
        return name
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return this.renderStatus(params);
    //   },
    // },
    {
      field: "status",
      headerName: "Subscription Status",
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.paymentlog?.subscription_period_type ? params?.row?.paymentlog?.subscription_period_type : ''
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <MoreHorizIcon
              onClick={(event) => this.dropDownMenuOpen(event, params.row)}
            />
          </div>
        );
      },
    },
  ];

  dropDownMenuOpen = (event, row) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
      selectedUser: row,
    });
  };

  dropDownMenuClose = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: false });
  };

  dropDownMenuSelected = (item) => {
    let label = "Button";
    if (item.value === "activate") {
      label = "Activate";
    } else if (item.value === "suspended") {
      label = "Suspend";
    } else if (item.value === "delete") {
      label = "Delete";
    } else if (item.value === "deactivate") {
      label = "Deactivate";
    }

    this.setState({
      DialogOpen: true,
      DialogTitle: item.text,
      DialogMessage: label + " the following account?",
      DialogMessageTargetEmail: this.state.selectedUser.useremail,
      DialogButtonColor: item.value === "activate" ? "primary" : "error",
      DialogButtonText: label,
    });
  };

  alertDialogOpen = (event) => {
    this.setState({ DialogOpen: event.target.value });
  };
  alertDialogClose = (event) => {
    this.setState({ DialogOpen: event.target.value });
  };

  renderStatus = (params) => {
    if (params.value === 0) {
      return <div>Active</div>;
    } else if (params.value === -1) {
      return <div>Suspended</div>;
    } else if (params.value === -2) {
      return <div>Deleted</div>;
    }
  };

  dateFormatter = (params) => {
    return params.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3.$2.$1");
  };

  dropdownStatusChange = (event) => {
    this.setState({ status: event.target.value });
    this.fetchData(this.state.dataPage, this.state.dataPageSize, event.target.value, this.state.searchValue, this.state.sorting);
  };

  searchBoxChange = (event) => {
    this.setState({ searchValue: event.target.value });
    clearTimeout(this.searchFilterRef);
    this.searchFilterRef = setTimeout(
      () => this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, event.target.value, this.state.sorting),
      700
    );
  };

  updateUser = async (event) => {
    const updateData = {
      tablename: "namecards",
      useremail: this.state.DialogMessageTargetEmail,
    };
    let res = null;
    switch (this.state.DialogButtonText) {
      case "Activate":
        res = await initiateCall(
          RequestType.POST,
          `/activateLexisCard`,
          updateData
        );
        break;
      case "Suspend":
        res = await initiateCall(
          RequestType.POST,
          `/suspendLexisCard`,
          updateData
        );
        break;
      case "Delete":
        res = await initiateCall(
          RequestType.POST,
          `/deleteLexisCard`,
          updateData
        );
        break;
      case "Deactivate":
        res = await initiateCall(
          RequestType.POST,
          `/deactivateLexisCard`,
          updateData
        );
        break;
      default:
        break;
    }
    if (res) {
      this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
    } else {
      this.flashError(this.state.DialogTitle + " failed!", 3000);
    }

    this.fetchData(
      this.state.dataPage,
      this.state.dataPageSize,
      this.state.status,
      this.state.searchValue,
      this.state.sorting
    );
  };

  setSortModel = async (sortModels) => {
    await this.setState({
      sorting: sortModels,
    });
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue, sortModels);
  };

  
  handleSelect = (selected) => {
    this.setState({
      dropdownOpen: false
    });

    if(selected === 'all'){
      exportAsExcel(
        "Lexiscard",
        "namecards",
        this.state.selectedUsers,
        this.state.searchValue,
        this.state.status
      )
    } else {
      exportAsExcel(
        "Lexiscard Sales",
        "namecards",
        this.state.selectedUsers,
        this.state.searchValue,
        'sales'
      )
    }
  }

  onDropDown = (event) => {
    this.setState({
      dropdownOpen: true,
      dropDownAnchorEl: event.currentTarget
    });
  }
  
  handleClose = () => {
    this.setState({
      dropdownOpen: false,
    });
  }

  openNewDialog = () => {
    this.setState({ openAddDialog: true });
  };

  closeNewDialog = () => {
    this.setState({ openAddDialog: false, addEmail: '', addName: '' });
  };

  addUser = async () => {

    this.setState({ isAdding: true });
    let cName = this.checkName();
    let cEmail = this.checkEmail();

    if(cName && cEmail){
      const check = await initiateCall(RequestType.POST, `/checkUserExist`, {email:this.state.addEmail});

      if(check.data.msg === 'success'){

        const now = new Date();
        const uuid = this.generateUUID();
        const metadata = [{
          name: this.state.addName,
          email: this.state.addEmail,
          company: '',
          position: '',
          direct: '',
          mobile: '',
          main: '',
          website: '',
          address: '',
          other_fields: [],
          ischopeshift: false,

        }];

        this.generateNamecard(this.state.addEmail, metadata[0], uuid)
        .then(async(namecards) => {

            const data = [{
              account_status: "main",
              captured_img_url: JSON.stringify([{img1:""},{img2:""}]),
              contacts_tag: "profile",
              date_added: now,
              default_preview_captured_img: true,
              id: 1,
              img_url: JSON.stringify(namecards),
              is_sync_online: false,
              metadata: [{"front": metadata}, {"back": []}],
              uuid: uuid
            }]
    
            const backupData = {
              backup_version: 1.5,
              data: data
            }
    
            const response = await initiateCall(RequestType.POST,
              `/addLexisUser`,
              {
                backupData: backupData,
                email: this.state.addEmail,
                password: this.state.addEmail.slice(0, 5),
                plan: this.state.addPlan
              });
    
            if(response.data.msg === 'success'){
              this.setState({ 
                openAddDialog: false,
                addEmail: '',
                addName: '',
                isAdding: false,
                addPlan: 'lite'
              });
            }
        })
        .catch(error => {
            console.error('Error fetching namecards:', error);
            this.setState({ 
              addEmail: '',
              addName: '',
              isAdding: false,
              addPlan: 'lite'
            });
        });
      }else{
        this.setState({ errorEmail: "Please try another email.", isAdding: false });
      }
    }
  }

  generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) | (c === 'x' ? 0 : 8);
      return r.toString(16);
    });
  }

  checkName = () => {
    if (this.state.addName === "") {
      this.setState({ errorName: "Please enter a name" });
      return false;
    } else {
      this.setState({ errorName: "" });
      return true;
    }
  };

  checkEmail = () => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (
      this.state.addEmail === "" ||
      regex.test(this.state.addEmail) === false
    ) {
      this.setState({ errorEmail: "Please enter a email" });
      return false;
    } else {
      this.setState({ errorEmail: "" });
      return true;
    }
  };

  generateNamecard = async (email, metadata, uuid) => {
    try{
      let generateUrl = 'https://dev-web.lexiscard.asia/api/personal-generate-namecard'

      // if (window.location.href.includes("localhost")) {
      //   generateUrl = 'http://localhost:3002/personal-generate-namecard'
      // }else{
      //     if(window.location.href.includes("dev-corp")){
      //       generateUrl = 'https://dev-web.lexiscard.asia/api/personal-generate-namecard'
      //     }else{
      //       generateUrl = 'https://www.lexiscard.asia/api/personal-generate-namecard'
      //     }
      // }

      const response = await initiateCall(RequestType.POST,
        `/generate-namecard`,
        {
          generateUrl: generateUrl,
          data: {
            email: email,
            metadata: metadata,
            uuid: uuid
          }
        });
      
      return response.data.data.img_url
    } catch (error) {
      console.log('generateNamcard : ',error)
    }
    
  }

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{ fontWeight: "bold" }}
                >
                  LexisCard User List ({this.state.dataTotal})
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ margin: "auto 0" }}>
                <Button
                  variant="contained"
                  className="cmsAdminBtn"
                  onClick={this.openNewDialog}
                >
                  Add User
                </Button>
              </Grid>
              <Grid item xs={1} sx={{ margin: "auto 0" }}>
                <Typography
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{
                    color: "blue",
                    cursor: "pointer",
                    float: "right",
                    fontSize: 15,
                  }}
                  onClick={this.onDropDown}
                >
                  Export as Excel
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <div className="LexisCard">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DropdownStatus
                status={this.state.status}
                handleChange={this.dropdownStatusChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchBox
                handleTextChange={this.searchBoxChange}
                disabled={this.state.searchDisable}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <DataGrid
                sx={{ bgcolor: "white" }}
                autoHeight={true}
                loading={this.state.dataLoading}
                rows={this.state.dataRows}
                columns={this.columns}
                rowCount={this.state.dataTotal}
                pageSize={this.state.dataPageSize}
                page={this.state.dataPage}
                rowsPerPageOptions={[10, 50, 100]}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  this.setState({ selectedUsers: newSelection });
                }}
                /*
                pagination
                onPageSizeChange={(newPageSize) => {
                  this.setState({ dataPageSize: newPageSize });
                }}
                */

                sortModel={this.state.sorting}
                onSortModelChange={(newSortModel) => {
                  this.setSortModel(newSortModel);
                }}
                paginationMode="server"
                sortingMode="server"
                onPageChange={(newPage) => {
                  this.setState({ dataPage: newPage });
                  this.fetchData(newPage, this.state.dataPageSize, this.state.status, this.state.searchValue, this.state.sorting);
                }}
                onPageSizeChange={(newPageSize) => {
                  this.setState({ dataPageSize: newPageSize, dataPage: 0 });
                  this.fetchData(0, newPageSize, this.state.status, this.state.searchValue, this.state.sorting);
                }}
                disableColumnSelector
                disableSelectionOnClick
                components={{
                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      No results found
                    </Stack>
                  ),
                }}
              ></DataGrid>
            </Grid>
          </Grid>
          <DropdownMenu
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.dropDownMenuClose}
            onClick={this.dropDownMenuClose}
            onSelect={this.dropDownMenuSelected}
            dataFrom={this.state.selectedUser}
          />
          <Menu
              id="fade-menu"
              MenuListProps={{
                  'aria-labelledby' : 'fade-button',
              }}
              anchorEl={this.state.dropDownAnchorEl}
              open={this.state.dropdownOpen}
              elevation={2}
              onClose={this.handleClose}
              TransitionComponent={Fade}
          >
              <MenuItem 
                  onClick={() => this.handleSelect('all')} 
                  style={{ color: 'black', fontSize: 14 }}
              >
                  Export as Excel
              </MenuItem>
              <MenuItem 
                  onClick={() => this.handleSelect('sales')} 
                  style={{ color: 'black', fontSize: 14 }}
              >
                  Export Sales as Excel
              </MenuItem>
          </Menu>
          <AlertDialog
            title={this.state.DialogTitle}
            message={this.state.DialogMessage}
            messageTargetEmail={this.state.DialogMessageTargetEmail}
            buttonText={this.state.DialogButtonText}
            buttonColor={this.state.DialogButtonColor}
            DialogOpen={this.state.DialogOpen}
            handleDialogClose={this.alertDialogClose}
            handleUserUpdate={(event) => {
              this.updateUser(event);
              this.alertDialogClose(event);
            }}
          />
          <Notifications
            setFlashSuccess={(func) => (this.flashSuccess = func)}
            setFlashError={(func) => (this.flashError = func)}
          />

          <Dialog
            className="adminDialogTitle"
            onClose={this.closeNewDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.openAddDialog}
            minWidth={"500"}
          >
            <DialogTitle className="dialogTitle">
              <div className="dialogTitle">Add User</div>
            </DialogTitle>
            <DialogContent dividers className="dialogContent">
              <div>
                <p className="headerText">
                  An email and temporary password will be sent to this user to
                  activate their account.
                </p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <FormControl className="form-control" variant="outlined" style={{marginRight: '20px'}}>
                    <label htmlFor="name">Name</label>
                    <OutlinedInput
                      id="name"
                      type="text"
                      className="input"
                      variant="outlined"
                      value={this.state.addName}
                      fullWidth
                      onChange={(e) =>
                        this.setState({ addName: e.target.value })
                      }
                    />
                    <div className="errorMessage">{this.state.errorName}</div>
                  </FormControl>

                  <FormControl className="form-control" variant="outlined">
                    <label htmlFor="name">Email</label>
                    <OutlinedInput
                      id="name"
                      type="text"
                      className="input"
                      variant="outlined"
                      value={this.state.addEmail}
                      fullWidth
                      onChange={(e) =>
                        this.setState({ addEmail: e.target.value })
                      }
                    />
                    <div className="errorMessage">{this.state.errorEmail}</div>
                  </FormControl>
                </div>
                <FormControl className="form-control" variant="outlined" style={{ marginTop: '-10px' }}>
                  <label htmlFor="userType">User Type</label>
                  <Select
                    id="userType"
                    value={this.state.addPlan}
                    onChange={(e) => this.setState({ addPlan: e.target.value })}
                    fullWidth
                  >
                    <MenuItem value="lite">Lite</MenuItem>
                    <MenuItem value="lexiscard_pro">Pro</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <div className="addAdmin">
              <DialogActions>
                <Button
                  className="cancelButton"
                  variant="text"
                  onClick={this.closeNewDialog}
                  disabled={this.state.isAdding}
                >
                  Cancel
                </Button>
                <Button
                  className="addButton"
                  variant="contained"
                  onClick={this.addUser}
                  disabled={this.state.isAdding}
                >
                  {this.state.isAdding ? <CircularProgress size={24} /> : 'Add'}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </>
    );
  }
}
