import React from "react";
import BasePageComponent from "../../../components/BasePageComponent";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import CompanyProfile from "../LexployerComponents/CompanyProfile";
import AdminUser from "../LexployerComponents/AdminUsers";

import { withRouter } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import "./Lexprofile.scss";
import JobPost from "../LexployerComponents/JobPost";

class LexPloyer extends BasePageComponent {
  constructor(props) {
    super(props);

    if (this.props.history.location.state === undefined) {
      window.location.assign("/lexployer");
    }

    //this will set the current index value based on the params name that were getting in the path
    this.ParamsName = {
      companyprofile: 0,
      adminuser: 1,
      jobpost: 2,
    };

    //ari naman anf tabname is amo ni sa ang e set ta as sugpon sa aton nga route nga i push ta sa path
    this.indexTabName = {
      0: "companyprofile",
      1: "adminuser",
      2: "jobpost",
    };
    //amo ni sa ang params name and ma act as a key sa aton nga Paramsname object which corresponds the current value sang current index state
    this.name = this.props.match.params.name;
    console.log("this", this.props.history.location.state);
    this.state = {
      data: this.props.history.location.state,
      currentIndex: this.ParamsName[this.name],
      dataTotal: this.props.history.location.total,
    };

    this.changeTabIndex = this.changeTabIndex.bind(this);
  }

  changeTabIndex(e, newValue) {
    this.props.history.push(
      `/lexployer/profile/${this.indexTabName[newValue]}?id=${this.state.data.id}`
    );
    this.setState({ currentIndex: newValue });
  }

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - 240px)`,
            ml: `240px`,
            backgroundColor: "white",
          }}
          elevation="0"
          className="appbar"
        >
          <Grid className="firstrow" md={12}>
            <Typography
              color="black"
              variant="h6"
              sx={{ fontSize: "20px", fontWeight: "bold" }}
            >
              <a href="/lexployer" className="breadLink">
                Lexployer List ({this.state.dataTotal})
              </a>
            </Typography>
            <KeyboardArrowRightIcon
              style={{ color: "grey" }}
              sx={{ fontSize: "25px", fontWeight: "bold" }}
            />
            <Typography
              color="black"
              variant="h6"
              sx={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {this.state.data.compname}
            </Typography>

            <Typography></Typography>
          </Grid>
          <Tabs
            onChange={this.changeTabIndex}
            value={this.state.currentIndex}
            className="tabs"
          >
            <Tab label="Company Profile" />
            <Tab label="Admin Users" />
            <Tab label="Job Post" />
          </Tabs>
        </AppBar>

        {this.state.currentIndex == 0 && (
          <CompanyProfile data={this.state.data} />
        )}
        {this.state.currentIndex == 1 && (
          <AdminUser userid={this.state.data.id} />
        )}
        {this.state.currentIndex == 2 && (
          <JobPost userid={this.state.data.id} />
        )}
      </>
    );
  }
}

export default withRouter(LexPloyer);
