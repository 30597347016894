import React, { Component } from 'react';
import BasePageComponent from "../../../components/BasePageComponent";
import "./PayoutInformation.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";

import AppBar from "@mui/material/AppBar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Toolbar from "@mui/material/Toolbar";

import moment from "moment";

import RequestType from "../../../utils/RequestType";
import { initiateCall } from "../../../utils/ApiHandler";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import bankCodes from "./BankList";

import Notifications from "../../../components/Notifications";
import { blue } from "@mui/material/colors";

export default class PayoutInformations extends BasePageComponent {
  constructor(props) {
    super(props);
    if (this.props.history.location.state === undefined) {
        window.location.assign("/eventorganizer");
    }

    this.prevState = this.props.history.location.state;
    this.business_type = [
        "", "Sole Proprietorship", "Company", "Partnership", "Charity, Club or Non-profit"
    ]
    this.status_level_arr = [
        "Pending", "Approved", "Declined"
    ]
    this.status_level_color = [
        "gray", "green", "red"
    ]

    this.bankList = [];

    bankCodes.map((row) => {
      this.bankList[row.code] = row.bankName
    })

    this.state = {
      status_level: this.prevState.status_level,
      modalTitle: '',
      showModal: false,
      imageSrc: '',
      showDeclineModal: false,
      onHoldReason: '',
      onHoldRequired: 'This field is required'
    }
    this.handleKeyDown = this.handleKeyDown.bind(this);

    

    

  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const cursorPosition = event.target.selectionStart;
      const textBeforeCursor = this.state.onHoldReason.slice(0, cursorPosition);
      const textAfterCursor = this.state.onHoldReason.slice(cursorPosition);
      const newText = textBeforeCursor + '\n' + textAfterCursor;
      this.setState({ onHoldReason: newText });
    }
    console.log("test", this.state.onHoldReason)
  }

  handleChange = (event) => {
    this.setState({ onHoldReason: event.target.value });
  };

  async componentDidMount() {
    console.log('-- prevstate --', this.prevState);
  }

  async approvePayout (){
    const res = await initiateCall(RequestType.POST, `/approvePayoutInformation`, {
      id: this.prevState.id,
      status: 1
    });

    if(res.data.msg == "success"){
      this.setState({ status_level: 1 });
      this.flashSuccess("Payout information successfully approved!", 3000);
    } else {
      this.flashError("Something went wrong, Please try again.", 3000);
    }
  }

  
  async declinePayout (){
    if(this.state.onHoldReason){
      const res = await initiateCall(RequestType.POST, `/approvePayoutInformation`, {
        id: this.prevState.id,
        status: 2,
        reason: this.state.onHoldReason
      });

      if(res.data.msg == "success"){
        this.setState({showDeclineModal: false})
        this.setState({ status_level: 2 });
        this.flashSuccess("Payout information is declined!", 3000);
      } else {
        this.flashError("Something went wrong, Please try again.", 3000);
      }
    }
  }

  async showImage(imgSrc, title){

    if(imgSrc !== "" && imgSrc !== undefined){

      const imageSrc = await initiateCall(RequestType.POST, `/getImageUrl`, {
        imgUrl: imgSrc,
      });

      this.setState({
        modalTitle: title,
        imageSrc: imageSrc.data.imgSrc,
        showModal: true
      });
    }
    
  }

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
                <Grid className="firstrow" md={9}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        color="#000000"
                        sx={{ fontWeight: "bold" }}
                    >
                    <a href="/eventorganizer" className="breadLink">
                        Payout Information
                    </a>
                    </Typography>
                    <KeyboardArrowRightIcon
                        style={{ color: "grey" }}
                        sx={{ fontSize: "25px", fontWeight: "bold" }}
                    />
                    <Typography
                        color="black"
                        variant="h6"
                        sx={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                    {this.prevState.useremail}
                    </Typography>
                    <KeyboardArrowRightIcon
                        style={{ color: "grey" }}
                        sx={{ fontSize: "25px", fontWeight: "bold" }}
                    />
                    <div class="HighlightedContainer" style={{ color: this.status_level_color[this.state.status_level], fontSize: "12px" }}>
                        <div class="HighlightText">
                            {
                                this.status_level_arr[this.state.status_level]
                            }
                        </div>
                    </div>
                </Grid>

                <Grid container md={3} sx={{ marginTop: 0.5 }} justifyContent="flex-end">
                    { this.state.status_level == 0 ?
                        <>
                            <Chip
                                avatar={<Avatar>X</Avatar>}
                                label="Decline"
                                color="error" variant="elevated"
                                style={{marginRight: 14}}
                                onClick={() => {
                                  this.setState({
                                    showDeclineModal: true,
                                  })
                              }}
                            />
                            <Chip
                                avatar={<Avatar>✓</Avatar>}
                                label="Approve"
                                color="success" 
                                onClick={() => this.approvePayout()}
                            />
                        </>
                        : ""
                    }
                </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />

        

        <div className="PayoutInformation">
        <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <div style={{ display: "flex" }}>
                {/*<text className="titleNumber">1</text>*/}
                <text className="title">Personal Information</text>
              </div>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={3}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Full Name</Typography>
                    <Typography>
                      {this.prevState.firstname && this.prevState.lastname
                        ? this.prevState.firstname + " " + this.prevState.lastname
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Email</Typography>
                    <Typography>
                      {this.prevState.useremail
                        ? this.prevState.useremail
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Contact Number</Typography>
                    <Typography>
                      {this.prevState.contact
                        ? this.prevState.contact
                        : "N/A"}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Grid container xs={12} spacing={1}>
                      {/* <Grid item xs={6}>
                        <Typography className="label">Nationality</Typography>
                        <Typography>
                          {"N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Birthdate</Typography>
                        <Typography>
                          {"N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Type of Pass</Typography>
                        <Typography>
                          {"N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Occupation</Typography>
                        <Typography>
                          {"N/A"}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Typography className="label">Address</Typography>
                        <Typography>
                          {this.prevState.address_line1
                            ? this.prevState.address_line1 + " "
                            : " "}
                            {this.prevState.address_line2
                              ? this.prevState.address_line2
                              : " "}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Postal Code</Typography>
                        <Typography>
                          {this.prevState.postal_code
                            ? this.prevState.postal_code
                            : "N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              
              <Grid item xs={3}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label" style={{ marginBottom: "20px" }} >
                      Uploaded File
                    </Typography>
                    <div>
                      <Typography className="label">Identification Front</Typography>
                      {
                        this.prevState.identification_front !== null && this.prevState.identification_front !== '' ?
                          <Typography style={{ fontSize: 14, color: 'blue', cursor: 'pointer' }} 
                            onClick={() => this.showImage(this.prevState.identification_front, 'Identification Front')}>
                            View Image
                          </Typography>
                        : <Typography>No file uploaded</Typography>
                      }
                    </div>
                      <div style={{height: 15}} />
                    <div>
                      <Typography className="label">Identification Back</Typography>
                      {
                        this.prevState.identification_back !== null && this.prevState.identification_back !== '' ?
                          <Typography style={{ fontSize: 14, color: 'blue', cursor: 'pointer' }}
                            onClick={() => this.showImage(this.prevState.identification_back, 'Identification Back')}>
                            View Image
                          </Typography>
                        : <Typography>No file uploaded</Typography>
                      }
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
        </Grid>

        <div style={{ marginBottom: "40px" }} />
        
        <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <div style={{ display: "flex" }}>
                {/*<text className="titleNumber">1</text>*/}
                <text className="title">Company Information</text>
              </div>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Company Name</Typography>
                    <Typography>
                      {this.prevState.company_name
                        ? this.prevState.company_name
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Unique Entity Number (UEN)</Typography>
                    <Typography>
                      {this.prevState.uen
                        ? this.prevState.uen
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Company Email</Typography>
                    <Typography>
                      {this.prevState.company_email
                        ? this.prevState.company_email
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Company Contact Number</Typography>
                    <Typography>
                      {this.prevState.company_contact
                        ? this.prevState.company_contact
                        : "N/A"}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        {/* <Typography className="label">Business Name</Typography>
                        <Typography>
                          {"N/A"}
                        </Typography> */}
                        {/* <div style={{ marginBottom: "20px" }} /> */}
                        <Typography className="label">Business Type</Typography>
                        <Typography>
                          {
                            this.business_type[this.prevState.business_type]
                          }
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="label">Address</Typography>
                        <Typography>
                          {this.prevState.company_address1
                            ? this.prevState.company_address1 + " "
                            : " "}
                            {this.prevState.company_address2
                              ? this.prevState.company_address2
                              : " "}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                        <Typography className="label">Postal Code</Typography>
                        <Typography>
                          {this.prevState.company_postal_code
                            ? this.prevState.company_postal_code
                            : "N/A"}
                        </Typography>
                        <div style={{ marginBottom: "20px" }} />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            </Grid>
        </Grid>

        

        <div style={{ marginBottom: "40px" }} />
        
        <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <div style={{ display: "flex" }}>
                {/*<text className="titleNumber">1</text>*/}
                <text className="title">Bank Information</text>
              </div>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Bank Name</Typography>
                    <Typography>
                      {this.prevState.bank_code
                        ? this.bankList[this.prevState.bank_code]
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Account Number</Typography>
                    <Typography>
                      {this.prevState.bank_account_number
                        ? this.prevState.bank_account_number
                        : "N/A"}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
        </Grid>

          <div style={{ marginBottom: "40px" }} />
        </div>

        <Dialog
            open={this.state.showModal}
            scroll="paper"
            PaperProps={{
                style: { borderRadius: 12 }
              }}
            >
          <DialogTitle className="DialogTitle" id="scroll-dialog-title">
            {this.state.modalTitle}
          </DialogTitle>

          <DialogContent dividers={true}>
            <div className="form">
              <div className="form-group">
                <Grid container>
                  <img src={`${this.state.imageSrc}`}
                    width='100%' />
                </Grid>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              className="Button"
              id="cancelBtn"
              onClick={() => {
                  this.setState({
                    showModal: false,
                    imageSrc: ''
                  })
              }}
            >
              Close
            </Button>
            <Button
              className="Button"
              variant="contained"
              color={this.state.DialogButtonColor}
              onClick={() => {
                  this.setState({
                    showModal: false,
                    imageSrc: ''
                  })
              }}
            >
              Ok
            </Button>
          </DialogActions>
      </Dialog>

      <Dialog
            open={this.state.showDeclineModal}
            scroll="paper"
            PaperProps={{
                style: { borderRadius: 12 }
              }}
            >
          <DialogTitle className="DialogTitle" id="scroll-dialog-title">
            Are you sure you want to put this application On Hold?
          </DialogTitle>

          <DialogContent dividers={true}>
            <div className="form">
              <div className="form-group">
                <Grid container>
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                    // variant="outlined"
                    label="Enter text with bullet points"
                    value={this.state.onHoldReason}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    required
                  />
                  {!this.state.onHoldReason ? <Typography style={{fontSize: 12, color: 'red'}}>This field is reqiured*</Typography> : null }
                </Grid>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              className="Button"
              id="cancelBtn"
              onClick={() => {
                  this.setState({
                    showDeclineModal: false,
                    onHoldReason: ''
                  })
              }}
            >
              Cancel
            </Button>
            <Button
              className="Button"
              variant="contained"
              color={this.state.DialogButtonColor}
              onClick={() => this.declinePayout()}
            >
              Confirm
            </Button>
          </DialogActions>
      </Dialog>

        <Notifications
          setFlashSuccess={(func) => (this.flashSuccess = func)}
          setFlashError={(func) => (this.flashError = func)}
        />
      </>
    );
  }
}
