import React, { Component } from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import MultiAddInput from './MultiAddInput';
import JobDescription from './JobDescription';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import InputAdornment from '@mui/material/InputAdornment';

const CustomGrid = styled(Grid,{})({
        display:"flex",
        flexDirection:"column",
        height:"auto",
    })


const CustomContainerGrid = styled(Grid,{})({
    borderWidth:"1px",
    borderStyle:"solid",
    borderColor:"#d5d8de",
    padding:"15px",
    width:"100%",
    borderRadius:"5px"
})    

 //auto complete options
 const AutocompleteOptionsQualities = [
    { title: 'Indepentdent' },
    { title: 'Team Player'},
    { title: 'Punctual' },
    { title: 'Sense of ownership'},
  
 ]

 


export default class EditAlertDialog extends Component {
                
        constructor(props){
            super(props)

            this.state = {
                    currentRole:"permanent",
                    currentCountry:"singapore",
                    currentState:"central",
                    currentCurrency:"SGD",
                    selectedInputRadio:'Monthly',
                    qualitiesList:[{data:"Punctual"}],
                    skillsList:[{data:"bout"}],
                    yearofExperienceCount:0

                }

        }

    //add to skill
    addData = (value,name,id) =>{
            console.log(value,name)
  
           const newSkill = {
                id:Math.random(),
              data:value
          }

            if(name == "qualitiesList"){           
                this.setState({
                    qualitiesList:[...this.state.qualitiesList,newSkill]
                
                })


                
            }else if(name="skillsList"){
                this.setState({
                    skillsList:[...this.state.skillsList,newSkill]
                })
            }


        
    }

    removeData = (id,name) =>{

        console.log(id,name)
        if(name == "qualitiesList"){
            
          
            const filteredSkill = this.state.qualitiesList.filter((skill)=>{
                return skill.id !== id
             })

            this.setState({
                qualitiesList:filteredSkill
            })
        
        }else if(name="skillsList"){
            const filteredSkill = this.state.skillsList.filter((skill)=>{
                return skill.id !== id
             })
            
            this.setState({
                skillsList:filteredSkill
            })
        }
        
    }

    handleYearsCount = (e) =>{
              
            if(e.target.value <= 0){
                this.setState({yearofExperienceCount:0})
                return
            }            
              this.setState({yearofExperienceCount:Number(e.target.value)})

            
            
    } 

    
    currentRoleMenu = [ {value: 'permanent', label: 'Permanent',},{value: 'fulltime',label: 'Full-time',},
        {value: 'Partime', label: 'Partime',},{value: 'Flexible',label: 'Flexible',},{value:'Contract',label:"Contract"},{value:"Temporary",label:"Temporary"},
        {value:'Internship',label:"Internship"}    
         ]

       countryMenu = [{value:"singapore",label:"Singapore"},{value:"ph",label:"Philippines"},{value:"korea",label:"Korea"},{value:"japan",label:"Japan"},]

       stateMenu = [{value:"central",label:"Central"},{value:"north",label:"North"},{value:"south",label:"South"},{value:"east",label:"East"},{value:"west",label:"West"},
       {value:"north-east",label:"North-east"},{value:"North-west",label:"North-west"},{value:"South-east",label:"South-east"},{value:"South-west",label:"South-west"},
        ]
  
        currencyMenu =[{value:"SGD",label:"SGD"},{value:"abc",label:"ABC"},{value:"ABc",label:"ABC"},{value:"aBC",label:"ABC"},]


    render() {
    return (
      <div>

            <Dialog
                 fullWidth
                 maxWidth="sm"
                 id='AlertDialog'
                 open={this.props.openEditDialog}
                 onClose={this.props.closeEditDialog}
                 scroll={'paper'}
                 aria-labelledby="scroll-dialog-title"
                 aria-describedby="scroll-dialog-description"
            >
                <DialogTitle>Edit Job Post</DialogTitle>
                
                <DialogContent dividers={true}>

                        <CustomContainerGrid className="first section" xs={12} >
                            <Grid sx={{fontWeight:"bold",marginBottom:"20px"}}>Job Details</Grid>
                            <CustomGrid>
                                <label>Title</label>
                                <TextField fullWidth={true} margin="none" size='small' id="outlined-basic" variant="outlined" />
                            </CustomGrid>

                            <Grid xs={12} style={{display:"flex",justifyContent:"space-between",marginTop:"20px"}}>
                               
                                <CustomGrid style={{width:"48%"}}>
                                    <label>Role</label>
                                    <TextField
                                    size='small'
                                    id="outlined-select-currency"
                                    select
                                    value={this.state.currentRole}
                                    onChange={(e) => this.setState({currentRole:e.target.value})}    
                                    >
                                    {this.currentRoleMenu.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                    ))}

                                    </TextField>

                                </CustomGrid>
                               
                                <CustomGrid style={{width:"48%"}}>
                                    <label>Years of Experience</label>
                                    <TextField fullWidth={true} margin="none"
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={this.handleYearsCount}
                                    value={this.state.yearofExperienceCount} type="number" size='small' id="outlined-basic" variant="outlined"/>
                                </CustomGrid>
                            </Grid>

                        </CustomContainerGrid>


                        <CustomContainerGrid xs={12} className="second Section" sx={{marginTop:"20px"}}>
                            <Grid sx={{fontWeight:"bold"}}>Location</Grid>

                        
                            <Grid xs={12} style={{display:"flex",justifyContent:"space-between",marginTop:"20px"}}>
                               
                                <CustomGrid style={{width:"48%"}}>
                                    <label>Country</label>
                                    <TextField
                                    size='small'
                                    id="outlined-select-currency"
                                    select
                                    value={this.state.currentCountry}
                                    onChange={(e) => this.setState({currentCountry:e.target.value})}    
                                    >
                                    {this.countryMenu.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                    ))}

                                    </TextField>
                                </CustomGrid>
                               
                                <CustomGrid style={{width:"48%"}}>
                                    <label>Location</label>
                                    <TextField
                                    size='small'
                                    id="outlined-select-currency"
                                    select
                                    value={this.state.currentState}
                                    onChange={(e) => this.setState({currentState:e.target.value})}    
                                    >
                                    {this.stateMenu.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                    ))}

                                    </TextField>

                                </CustomGrid>

                            </Grid>
                            <CustomGrid style={{marginTop:"20px"}}>
                                <label>Address</label>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    size='small'
                                    multiline
                                    rows={4}
                                    />
                            </CustomGrid>


                        </CustomContainerGrid>

                        <CustomContainerGrid xs={12} className="third-section" style={{marginTop:"20px"}}>
                                 <Grid sx={{fontWeight:"bold"}}>Salary Range</Grid>
                                 <Grid xs={9}>
                                 <FormControlLabel value="Monthly" onChange={(e)=>this.setState({selectedInputRadio:e.target.value})} checked={this.state.selectedInputRadio == "Monthly"}  control={<Radio />} label="Monthly" />
                                 <FormControlLabel value="Hourly" onChange={(e)=>this.setState({selectedInputRadio:e.target.value})} checked={this.state.selectedInputRadio == "Hourly"}  control={<Radio />} label="Hourly" />
                                 </Grid>

                                <Grid style={{display:"flex",justifyContent:"space-between",marginTop:'10px'}}>
                                    <CustomGrid style={{flex:".3"}}>
                                        <label>Currency</label>
                                        <TextField
                                    size='small'
                                    id="outlined-select-currency"
                                    select
                                    value={this.state.currentCurrency}
                                    onChange={(e) => this.setState({currentCurrency:e.target.value})}    
                                    >
                                    {this.currencyMenu.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                    ))}

                                    </TextField>
                                    </CustomGrid>
                                    <CustomGrid style={{flex:".3"}}>
                                        <label>Min.</label>
                                        <TextField fullWidth={true} margin="none"  size='small' id="outlined-basic" variant="outlined"
                                                  InputProps={{
                                                         startAdornment: (
                                                                      <InputAdornment position="start">
                                                                        <AttachMoneyOutlinedIcon />
                                                                      </InputAdornment>
                                                                    ),
                                                                }}
                                        
                                        />
                                    </CustomGrid>
                                    <CustomGrid style={{flex:".3"}}>
                                        <label>Max.</label>
                                        <TextField fullWidth={true} margin="none"  size='small' id="outlined-basic" variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                                 <InputAdornment position="start">
                                                                   <AttachMoneyOutlinedIcon />
                                                                 </InputAdornment>
                                                               ),
                                                           }}
                                        />
                                    </CustomGrid>
                                    
                                </Grid>

                                <Grid xs={12} style={{marginTop:"10px"}}>
                                <FormControlLabel control={<Checkbox  />} label="I do not wish to display salary in job posting" />
                                </Grid>  

                        </CustomContainerGrid>

                        <CustomContainerGrid xs={12} sx={{marginTop:'20px'}}>
                                <Grid>Job Desciption</Grid>
                                <JobDescription/>

                        </CustomContainerGrid>
                        
                      <CustomContainerGrid className="fourth-section" sx={12} sx={{marginTop:"20px"}}>
                                 <Grid><p style={{fontSize:"12px",marginBottom:"10px",fontWeight:"bold"}}>Share the qualities of an ideal candidate should possess</p></Grid>       
                                <CustomGrid>
                                   <label style={{fontSize:"12px",marginBottom:"5px"}}>Enter as many as you like</label> 
                                        
                                 <MultiAddInput addSkill={this.addData} name={"qualitiesList"} removeData={this.removeData} list={this.state.qualitiesList}/>

                                        
                                  </CustomGrid>     
                      
                      </CustomContainerGrid>                  

                      <CustomContainerGrid className="fifth-section" sx={12} style={{marginTop:"40px"}}>
                              
                                 <Grid><p style={{fontSize:"12px",marginBottom:"10px",fontWeight:"bold"}}>Share the skillset of an ideal candidate should possess</p></Grid>       
                                <CustomGrid>
                                   <label style={{fontSize:"12px",marginBottom:"5px"}}>Enter as many as you like</label> 
                                
                                 <MultiAddInput addSkill={this.addData} name={"skillsList"} removeData={this.removeData} list={this.state.skillsList}/>

                                </CustomGrid>
                      </CustomContainerGrid>         

                </DialogContent>
                                        
                <DialogActions>
                <Button className="Button" onClick={this.props.closeEditDialog}>Cancel</Button>
                <Button className="Button" variant="contained">Save Changes</Button>
                </DialogActions>

            </Dialog>

                    

      </div>
    )
  }
}
