import React, { Component } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";

import TextField from '@mui/material/TextField';

export default class AlertDialog extends Component {
  
  
    render() {
    return (
      <div>
          <Dialog
            maxWidth="xs"
            fullWidth
            id='AlertDialog'
            open={this.props.open}
            onClose={this.props.close}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
         <DialogTitle sx={{backgroundColor:"#f5f2f2",fontWeight:"bold"}} className="DialogTitle" id="scroll-dialog-title">{this.props.dialogHeader}</DialogTitle>
          <DialogContent>
              <DialogContentText sx={{paddingTop:"9px",fontSize:"14px"}}>

                                
                           {this.props.showDialogTextInput && <div>
                              <label>Please State a reason</label>
                              <TextField
                                    sx={{marginBottom:"20px"}}
                                    fullWidth
                                    id="outlined-multiline-flexible"
                                    size='small'
                                    multiline
                                    rows={3}
                                    />  </div>
                             }  

                            
                  {this.props.dialogContent}
                  
              </DialogContentText>
          </DialogContent>

        <DialogActions>
            <Button onClick={this.props.close}>Close</Button>
            <Button color={this.props.buttonStyle} variant="contained">{this.props.buttonText}</Button>
        </DialogActions>
          </Dialog>

      </div>
    )
  }
}
