import React, { Component } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";

export default class BarChartDataMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: ["LexSeekers", "LexPloyers", "Job Posts"]
        }
    }

    render() {
        return (
            <Box className="BarChartDataMenu">
                <FormControl className="filter" size="small">
                    <Select
                        className="Select"
                        value={this.props.selectedBarChartTitle}
                        onChange={this.props.handleBarChartDataChange}
                    >
                        {
                            this.state.list.map((item)=>{
                                return (
                                    <MenuItem sx={{fontSize: 14}} key={item} value={item}>{item}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        )
    }
}