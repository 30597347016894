import React from "react";
import BasePageComponent from "../../components/BasePageComponent";
import "./LexisEventOrganizer.scss";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import DropdownStatus from "./LexisEventOrganizerComponents/DropdownStatus";
import SearchBox from "./LexisEventOrganizerComponents/SearchBox";
import DropdownMenu from "./LexisEventOrganizerComponents/DropdownMenu";
import AlertDialog from "./LexisEventOrganizerComponents/AlertDialog";
import Notifications from "../../components/Notifications";
import RequestType from "../../utils/RequestType";
import { initiateCall } from "../../utils/ApiHandler";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";

import { exportAsExcel } from "../../utils/ExcelExporter";
export default class LexisEventOrganizer extends BasePageComponent {
  constructor(props) {
    super(props);
    this.searchFilterRef = React.createRef();
    this.state = {
      anchorEl: null,
      open: false,
      selectedUser: [],

      status: "-3",
      searchValue: "",
      searchDisable: false,
      dataLoading: false,
      dataRows: [],
      dataTotal: 0,
      dataPage: 0,
      dataPageSize: 10,

      DialogOpen: false,
      DialogTitle: "Title",
      DialogMessage: "Deactivate the following account?",
      DialogMessageTargetEmail: "",
      DialogButtonText: "Button",
      DialogButtonColor: "primary",

      sorting: [{ field: "created_on", sort: "desc" }],

      selectedUsers: [],
    };
  }

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue, this.state.sorting);
  };

  fetchData = async (
    page = null,
    pageSize = null,
    status = null,
    search = null,
    sort = null
  ) => {
    this.setState({ dataLoading: true, searchDisable: true });
    console.log('---- fetching data ----');

    const res = await initiateCall(RequestType.POST, `/PayoutInformation`, {
      tablename: "payout_information",
      page: page,
      pageSize: pageSize,
      search: search,
      status: status,
      sortModel: sort,
    });

    console.log(res);

    if (res) {
      this.setState({
        dataRows: res.data.data.rows,
        dataTotal: parseInt(res.data.total),
      });
    } else {
      this.setState({ dataRows: [], dataTotal: 0 });
    }
    this.setState({ dataLoading: false, searchDisable: false });

    if (search != null) {
      document.getElementById("userSearchBox").focus();
    }
  };

  columns = [
    {
      field: "useremail",
      headerName: "Email",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              color: "blue",
              wordBreak: "break-word",
              whiteSpace: "initial", 
              cursor: "pointer"
            }}
            onClick={() =>
              this.props.history.push({
                pathname: "/eventorganizer/payoutinformation",
                state: params.row,
              })
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "firstname",
      headerName: "Firstname",
      flex: 1,
      // valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "lastname",
      headerName: "Lastname",
      flex: 1,
    },
    {
      field: "company_name",
      headerName: "Company Name",
      flex: 1,
    },
    {
      field: "uen",
      headerName: "UEN",
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) {
          return <div>-</div>;
        } else {
          return <div>{params.value}</div>;
        }
      },
    },
    {
      field: "business_type",
      headerName: "Business Type",
      flex: 1,
      renderCell: (params) => {
        return this.renderBusinessType(params);
      },
    },
    {
      field: "status_level",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return this.renderStatus(params);
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: (params) => {
    //     return (
    //       <div style={{ cursor: "pointer" }}>
    //         <MoreHorizIcon
    //           onClick={(event) => this.dropDownMenuOpen(event, params.row)}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  dropDownMenuOpen = (event, row) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
      selectedUser: row,
    });
  };

  dropDownMenuClose = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: false });
  };

  dropDownMenuSelected = (item) => {
    let label = "Button";
    if (item.value === "view") {
      label = "View data";
    }
    
    this.setState({
      DialogOpen: true,
      DialogTitle: item.text,
      DialogMessage: label + " of the following account?",
      DialogMessageTargetEmail: this.state.selectedUser.useremail,
      DialogButtonColor: "primary",
      DialogButtonText: label,
    });
  };

  alertDialogOpen = (event) => {
    this.setState({ DialogOpen: event.target.value });
  };
  alertDialogClose = (event) => {
    this.setState({ DialogOpen: event.target.value });
  };

  renderStatus = (params) => {
    if (params.value === 0) {
      return <div>Pending</div>;
    } else if (params.value === 1) {
      return <div>Approved</div>;
    } else if (params.value === 2) {
      return <div>Declined</div>;
    }
  };

  renderBusinessType = (params) => {
    const business_type = [
      "",
      "Sole Proprietorship",
      "Company",
      "Partnership",
      "Charity, Club or Non-profit"
    ]

    return business_type[params.value];
    
  };

  dateFormatter = (params) => {
    return params.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3.$2.$1");
  };

  dropdownStatusChange = (event) => {
    this.setState({ status: event.target.value });
    this.fetchData(this.state.dataPage, this.state.dataPageSize, event.target.value, this.state.searchValue, this.state.sorting);
  };

  searchBoxChange = (event) => {
    this.setState({ searchValue: event.target.value });
    clearTimeout(this.searchFilterRef);
    this.searchFilterRef = setTimeout(
      () => this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, event.target.value, this.state.sorting),
      700
    );
  };

  updateUser = async (event) => {
    const updateData = {
      tablename: "payout_information",
      useremail: this.state.DialogMessageTargetEmail,
    };
    let res = null;
    switch (this.state.DialogButtonText) {
      case "Activate":
        res = await initiateCall(
          RequestType.POST,
          `/activateLexisEventOrganizer`,
          updateData
        );
        break;
      case "Suspend":
        res = await initiateCall(
          RequestType.POST,
          `/suspendLexisEventOrganizer`,
          updateData
        );
        break;
      case "Delete":
        res = await initiateCall(
          RequestType.POST,
          `/deleteLexisEventOrganizer`,
          updateData
        );
        break;
      case "Deactivate":
        res = await initiateCall(
          RequestType.POST,
          `/deactivateLexisEventOrganizer`,
          updateData
        );
        break;
      default:
        break;
    }
    if (res) {
      this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
    } else {
      this.flashError(this.state.DialogTitle + " failed!", 3000);
    }

    this.fetchData(
      this.state.dataPage,
      this.state.dataPageSize,
      this.state.status,
      this.state.searchValue,
        this.state.sorting
    );
  };

  setSortModel = async (sortModels) => {
    await this.setState({
      sorting: sortModels,
    });
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue, sortModels);
  };

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{ fontWeight: "bold" }}
                >
                  Lexis Event Organizer({this.state.dataTotal})
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ margin: "auto 0" }}>
                <Typography
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{
                    color: "blue",
                    cursor: "pointer",
                    float: "right",
                    fontSize: 15,
                  }}
                  onClick={() =>
                    exportAsExcel(
                      "LexisEventOrganizer",
                      "payout_information",
                      this.state.selectedUsers,
                      this.state.searchValue,
                      this.state.status
                    )
                  }
                >
                  Export as Excel
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <div className="LexisEventOrganizer">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DropdownStatus
                status={this.state.status}
                handleChange={this.dropdownStatusChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchBox
                handleTextChange={this.searchBoxChange}
                disabled={this.state.searchDisable}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <DataGrid
                sx={{ bgcolor: "white" }}
                autoHeight={true}
                loading={this.state.dataLoading}
                rows={this.state.dataRows}
                columns={this.columns}
                rowCount={this.state.dataTotal}
                pageSize={this.state.dataPageSize}
                page={this.state.dataPage}
                rowsPerPageOptions={[10, 50, 100]}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  this.setState({ selectedUsers: newSelection });
                }}
                /*
                pagination
                onPageSizeChange={(newPageSize) => {
                  this.setState({ dataPageSize: newPageSize });
                }}
                */

                sortModel={this.state.sorting}
                onSortModelChange={(newSortModel) => {
                  if(newSortModel.length > 0){
                    this.setSortModel(newSortModel);
                  }
                }}
                paginationMode="server"
                sortingMode="server"
                onPageChange={(newPage) => {
                  this.setState({ dataPage: newPage });
                  this.fetchData(newPage, this.state.dataPageSize, this.state.status, this.state.searchValue, this.state.sorting);
                }}
                onPageSizeChange={(newPageSize) => {
                  this.setState({ dataPageSize: newPageSize, dataPage: 0 });
                  this.fetchData(0, newPageSize, this.state.status, this.state.searchValue, this.state.sorting);
                }}
                disableColumnSelector
                disableSelectionOnClick
                components={{
                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      No results found
                    </Stack>
                  ),
                }}
              ></DataGrid>
            </Grid>
          </Grid>
          <DropdownMenu
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.dropDownMenuClose}
            onClick={this.dropDownMenuClose}
            onSelect={this.dropDownMenuSelected}
            dataFrom={this.state.selectedUser}
          />
          <AlertDialog
            title={this.state.DialogTitle}
            message={this.state.DialogMessage}
            messageTargetEmail={this.state.DialogMessageTargetEmail}
            buttonText={this.state.DialogButtonText}
            buttonColor={this.state.DialogButtonColor}
            DialogOpen={this.state.DialogOpen}
            handleDialogClose={this.alertDialogClose}
            handleUserUpdate={(event) => {
              this.updateUser(event);
              this.alertDialogClose(event);
            }}
          />
          <Notifications
            setFlashSuccess={(func) => (this.flashSuccess = func)}
            setFlashError={(func) => (this.flashError = func)}
          />
        </div>
      </>
    );
  }
}
