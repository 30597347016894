import React, { Component } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { initiateCall } from "../../utils/ApiHandler";
import RequestType from "../../utils/RequestType";

export default class BarChartYearMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // todo: refactor to self calculate
      //list: ["2021", "2022", "2023"],
    };
  }
  async componentDidMount() {
    /*
    let res = null;
    res = await initiateCall(RequestType.POST, "/dashboard-barchart-info", {
      type: this.props.selectedType,
      year: "2022",
    });
    this.setState({ list: res.data.year });
    */
  }

  render() {
    return (
      <Box className="BarChartYearMenu">
        <FormControl className="filter" size="small">
          <Select
            className="Select"
            value={this.props.selectedBarChartYear}
            onChange={this.props.handleBarChartYearChange}
          >
            {
              /*
              this.state.list.map((item) => {
              return (
                <MenuItem sx={{ fontSize: 14 }} key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })
            */

              this.props.drpMenu.map((item) => {
                return (
                  <MenuItem sx={{ fontSize: 14 }} key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
      </Box>
    );
  }
}
