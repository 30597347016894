import BasePageComponent from "../../components/BasePageComponent";
import "./LexployerList.scss";
import { DataGrid, GridApi } from "@mui/x-data-grid";
import DropdownMenu from "./DropdownMenu";
import DropdownStatus from "./DropdownStatus";
import SearchBox from "./SearchBox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import AlertDialog from "./AlertDialog";
import RequestType from "../../utils/RequestType";
import { initiateCall } from "../../utils/ApiHandler";
import Notifications from "../../components/Notifications";
import React from "react";
import { breadcrumbsClasses } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { exportAsExcel } from "../../utils/ExcelExporter";
import { CheckBox } from "../../components/CheckBox";
import moment from "moment";

export default class LexPloyer extends BasePageComponent {
  constructor(props) {
    super(props);
    this.searchFilterRef = React.createRef();
    this.state = {
      anchorEl: null,
      open: false,
      status: "-3",
      searchValue: "",
      DialogOpen: false,
      DialogTitle: "Title",
      DialogMessage: "Deactivate the following account?",
      DialogMessageTargetName: "",
      DialogMessageTargetContactNum: "",
      DialogMessageTargetEmail: "",
      DialogButtonText: "Button",
      DialogButtonColor: "primary",
      dropDownOpenedUser: [],
      selectedUsers: [],
      dataRows: [],
      dataTotal: 0,
      dataPage: 0,
      dataPageSize: 10,
      dataLoading: false,
      dataError: false,
      searchDisable: false,
      sort: "asc",
      field: "compname"
    };
  }

  componentDidMount = async (...args) => {
    super.componentDidMount.apply(this, args);
    this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue);
  };

  fetchData = async (
    page = null,
    pageSize = null,
    status = null,
    search = null,
    field = null,
    sort = null
  ) => {
    this.setState({ dataLoading: true, searchDisable: true });

    if (field == null) {
      field = this.state.field;
    }

    if (sort == null) {
      sort = this.state.sort;
    }
    const res = await initiateCall(RequestType.POST, `/listing`, {
      tablename: "signuplexployer",
      page: page,
      pageSize: pageSize,
      search: search,
      status: status,
      field: field,
      sort: sort,
    });

    if (res) {
      this.setState({
        dataRows: res.data.data.rows,
        dataTotal: parseInt(res.data.total),
      });
    } else {
      this.setState({ dataRows: [], dataTotal: 0 });
    }

    this.setState({ dataLoading: false, searchDisable: false });

    // Auto focus on searchbox if this was a search
    if (search != null) {
      document.getElementById("userSearchBox").focus();
    }
  };

  columns = [
    {
      field: "compname",
      headerName: "Company Name",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() =>
              this.props.history.push({
                pathname: "/lexployer/profile/companyprofile",
                state: params.row,
                total: this.state.dataTotal,
              })
            }
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "compuen",
      headerName: "UEN No.",
      width: 150,
      flex: 1,
    },
    {
      field: "useremail",
      headerName: "Email",
      width: 110,
      flex: 1,
      hide: true,
    },
    {
      field: "createdon",
      headerName: "Date Joined",
      width: 110,
      flex: 1,
      valueGetter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      flex: 1,
      renderCell: (params) => {
        return this.renderStatus(params);
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <MoreHorizIcon
            onClick={(event) => this.dropDownMenuOpen(event, params.row)}
          />
        );
      },
    },
  ];

  renderStatus = (params) => {
    if (params.value === 0) {
      return <div>Inactive</div>;
    } else if (params.value === 1) {
      return <div>Active</div>;
    } else if (params.value === 2) {
      return <div>Suspended</div>;
    } else if (params.value === 3) {
      return <div>Deleted</div>;
    }
  };

  dropDownMenuOpen = (event, row) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
      dropDownOpenedUser: row,
    });
  };
  dropDownMenuClose = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: false });
  };
  dropDownMenuSelected = (item) => {
    let label = "Button";
    if (item.value === "activate") {
      label = "Activate";
    } else if (item.value === "suspended") {
      label = "Suspend";
    } else if (item.value === "delete") {
      label = "Delete";
    } else if (item.value === "deactivate") {
      label = "Deactivate";
    }

    // todo: consider checking/informing user if current status == selected status

    this.setState({
      DialogOpen: true,
      DialogTitle: item.text,
      DialogMessage: label + " the following account?",
      DialogMessageTargetName: this.state.dropDownOpenedUser.contactname,
      DialogMessageTargetContactNum:
        this.state.dropDownOpenedUser.contactnumber,
      DialogMessageTargetEmail: this.state.dropDownOpenedUser.useremail,
      DialogButtonColor: item.value == "activate" ? "primary" : "error",
      DialogButtonText: label,
    });
  };

  dropdownStatusChange = (event) => {
    this.setState({ status: event.target.value });
    this.fetchData(this.state.dataPage, this.state.dataPageSize, event.target.value, this.state.searchValue);
  };
  alertDialogOpen = (event) => {
    this.setState({ DialogOpen: event.target.value });
  };
  alertDialogClose = (event) => {
    this.setState({ DialogOpen: event.target.value });
  };

  handleSortModelChange = (event) => {
    if (event[0]) {
      let field = event[0].field;
      let sort = event[0].sort;
      this.setState({
        sort: sort,
        field: field,
      });

      this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue, field, sort);
    }
  };

  updateUser = async (event) => {
    const updateData = {
      tablename: "signuplexployer",
      useremail: this.state.DialogMessageTargetEmail,
    };
    let res = null;
    switch (this.state.DialogButtonText) {
      case "Activate":
        res = await initiateCall(RequestType.POST, `/activate`, updateData);
        break;
      case "Suspend":
        res = await initiateCall(RequestType.POST, `/suspend`, updateData);
        break;
      case "Delete":
        res = await initiateCall(RequestType.POST, `/delete`, updateData);
        break;
      case "Deactivate":
        res = await initiateCall(RequestType.POST, `/deactivate`, updateData);
        break;
      default:
        break;
    }
    if (res) {
      this.flashSuccess(this.state.DialogTitle + " successful!", 3000);
    } else {
      this.flashError(this.state.DialogTitle + " failed!", 3000);
    }
    // todo: consider refactoring this to directly update table instead of doing an API call?
    this.fetchData(
      this.state.dataPage,
      this.state.dataPageSize,
      this.state.status,
      this.state.searchValue
    );
  };

  searchBoxChange = (event) => {
    this.setState({ searchValue: event.target.value });

    clearTimeout(this.searchFilterRef);
    this.searchFilterRef = setTimeout(
      () => this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, event.target.value),
      700
    );
  };

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{ fontWeight: "bold" }}
                >
                  Lexployer List ({this.state.dataTotal})
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ margin: "auto 0" }}>
                <Typography
                  noWrap
                  component="div"
                  color="#000000"
                  sx={{
                    color: "blue",
                    cursor: "pointer",
                    float: "right",
                    fontSize: 15,
                  }}
                  onClick={() =>
                    exportAsExcel(
                      "LexPloyer",
                      "signuplexployer",
                      this.state.selectedUsers,
                      this.state.searchValue,
                      this.state.status
                    )
                  }
                >
                  Export as Excel
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <div className="LexployerList">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DropdownStatus
                status={this.state.status}
                handleChange={this.dropdownStatusChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchBox
                handleTextChange={this.searchBoxChange}
                disabled={this.state.searchDisable}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <DataGrid
                sx={{ bgcolor: "white" }}
                autoHeight={true}
                loading={this.state.dataLoading}
                rows={this.state.dataRows}
                columns={this.columns}
                rowCount={this.state.dataTotal}
                pageSize={this.state.dataPageSize}
                page={this.state.dataPage}
                rowsPerPageOptions={[10, 50, 100]}
                paginationMode="server"
                onPageChange={(newPage) => {
                  this.setState({ dataPage: newPage });
                  this.fetchData(newPage, this.state.dataPageSize, this.state.status, this.state.searchValue);
                }}
                onPageSizeChange={(newPageSize) => {
                  this.setState({ dataPageSize: newPageSize , dataPage: 0});
                  this.fetchData(0, newPageSize, this.state.status, this.state.searchValue);
                }}
                disableColumnSelector
                disableSelectionOnClick
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  this.setState({ selectedUsers: newSelection });
                }}
                components={{
                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      No result found
                    </Stack>
                  ),

                  Checkbox: CheckBox,
                }}
                sortingMode="server"
                onSortModelChange={this.handleSortModelChange}
              />
            </Grid>
          </Grid>
          {this.state.open && (
            <DropdownMenu
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.dropDownMenuClose}
              onClick={this.dropDownMenuClose}
              onSelect={this.dropDownMenuSelected}
              dropDownOpenedUser={this.state.dropDownOpenedUser}
            />
          )}

          {this.state.DialogOpen && (
            <AlertDialog
              title={this.state.DialogTitle}
              message={this.state.DialogMessage}
              messageTargetName={this.state.DialogMessageTargetName}
              messageTargetPhoneNum={this.state.DialogMessageTargetContactNum}
              messageTargetEmail={this.state.DialogMessageTargetEmail}
              buttonText={this.state.DialogButtonText}
              buttonColor={this.state.DialogButtonColor}
              DialogOpen={this.state.DialogOpen}
              handleDialogClose={this.alertDialogClose}
              handleUserUpdate={(event) => {
                this.updateUser(event);
                this.alertDialogClose(event);
              }}
            />
          )}

          <Notifications
            setFlashSuccess={(func) => (this.flashSuccess = func)}
            setFlashError={(func) => (this.flashError = func)}
          />
        </div>
      </>
    );
  }
}
