const ENV = {
    dev: {
      env: 'development',
      apiUrl: 'https://dev-cms.lexisjobs.asia/api',
    },
    devlocal:{
      env: 'local',
      apiUrl: 'http://localhost:3000',
    },
    prod: {
      env: 'production',
      apiUrl: 'https://cms.lexisjobs.asia/api',
    },
  };
  
  export const getEnvVars = () => {
    let apiEndpoint = ENV.devlocal
    if (window.location.href.includes("localhost")) {
      apiEndpoint = ENV.devlocal
    }else{
        if (window.location.href.includes("dev-cms")){
            apiEndpoint = ENV.dev
        }else{
            apiEndpoint = ENV.prod
        }
    }
    return apiEndpoint;
  };
  
  export const isMaintenance = false;