import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import LexSeekerList from "./pages/LexSeekerList";
import LexSeekerProfile from "./pages/LexSeekerList/LexSeekerProfile";
import JobPosts from "./pages/JobPosts";
import LexployerList from "./pages/LexployerList";
import Notifications from "./pages/Notifications";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import LexPloyer from "./pages/LexployerList/LexployerProfile";
import PayoutInformation from "./pages/LexisEventOrganizer/PayoutInformation";
import AdminManagement from "./pages/AdminManagement";
import ChangePassword from "./pages/ChangePassword";
import ResetPassword from "./pages/ResetPassword";
import LexisCard from "./pages/LexisCardList";
import LexisEventOrganizer from "./pages/LexisEventOrganizer"
import LexisEventList from "./pages/LexisEventList";
import LexisEventDetail from "./pages/LexisEventList/LexisEventDetail";
import LexisPayoutList from "./pages/LexisPayoutList";
import LexisPayoutDetails from "./pages/LexisPayoutList/PayoutDetails";
import LexisCardReward from "./pages/LexisCardReward";
import LexisCardGiftList from "./pages/LexisCardGiftList";

export default function Routes() {
  const history = useHistory();

  return (
    <Switch>
      <Route exact path="/">
        <Login history={history} />
      </Route>
      <Route exact path="/forgotpassword">
        <ForgotPassword history={history} />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard history={history} />
      </Route>
      <Route exact path="/lexseeker">
        <LexSeekerList history={history} />
      </Route>
      <Route exact path="/lexseeker/profile/">
        <LexSeekerProfile history={history} />
      </Route>
      <Route path="/lexployer/profile/:name">
        <LexPloyer history={history} />
      </Route>
      {/* <Redirect
        axact
        from="/lexployer/profile"
        to="/lexployer/profile/companyprofile"
      /> */}
      <Route exact path="/jobposts">
        <JobPosts history={history} />
      </Route>
      <Route exact path="/lexployer">
        <LexployerList history={history} />
      </Route>
      <Route exact path="/notifications">
        <Notifications history={history} />
      </Route>
      <Route exact path="/adminmanagement">
        <AdminManagement history={history} />
      </Route>
      <Route exact path="/changepassword">
        <ChangePassword history={history} />
      </Route>
      <Route exact path="/resetpassword">
        <ResetPassword history={history} />
      </Route>
      <Route exact path="/lexiscard">
        <LexisCard history={history} />
      </Route>
      <Route exact path="/lexiscardgifts">
        <LexisCardGiftList history={history} />
      </Route>
      <Route exact path="/eventorganizer">
        <LexisEventOrganizer history={history} />
      </Route>
      <Route path="/eventorganizer/payoutinformation">
        <PayoutInformation history={history} />
      </Route>
      <Route path="/lexiseventlist">
        <LexisEventList history={history} />
      </Route>
      <Route path="/lexisevents/eventdetails">
        <LexisEventDetail history={history} />
      </Route>
      <Route path="/lexispayoutlist">
        <LexisPayoutList history={history} />
      </Route>
      <Route path="/payoutlist/payoutdetails">
        <LexisPayoutDetails history={history} />
      </Route>
      {/*
      <Route exact path="/lexiscardreward">
        <LexisCardReward history={history} />
      </Route>
      */}
      <Route render={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
}
