import BasePageComponent from "../../components/BasePageComponent";
import "./ChangePassword.scss";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

import RequestType from "../../utils/RequestType";
import { initiateCall } from "../../utils/ApiHandler";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Notifications from "../../components/Notifications";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

export default class ChangePassword extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      showOldPassword: false,
      showNewPassword: false,
      showCfmPassword: false,

      isLoading: false,

      oldPasswordSubmittedEmpty: false,
      newPasswordSubmittedEmpty: false,
      cfmPasswordSubmittedEmpty: false,

      oldPassword: "",
      newPassword: "",
      cfmPassword: "",

      errorNewPassword: "",
      errorCfmPassword: "",
    };
  }

  oldPasswordChange = (event) => {
    this.setState({ oldPassword: event.target.value });
  };

  newPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  cfmPasswordChange = (event) => {
    this.setState({ cfmPassword: event.target.value });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowOldPassword = () => {
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  };

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleClickShowCfmPassword = () => {
    this.setState({
      showCfmPassword: !this.state.showCfmPassword,
    });
  };

  hasEmptyInput() {
    let hasEmpty = false;

    if (this.state.oldPassword === "") {
      hasEmpty = true;
      this.setState({ oldPasswordSubmittedEmpty: true });
    } else {
      this.setState({ oldPasswordSubmittedEmpty: false });
    }

    if (this.state.newPassword === "") {
      hasEmpty = true;
      this.setState({ newPasswordSubmittedEmpty: true });
    } else {
      this.setState({ newPasswordSubmittedEmpty: false });
    }

    if (this.state.cfmPassword === "") {
      hasEmpty = true;
      this.setState({ cfmPasswordSubmittedEmpty: true });
    } else {
      this.setState({ cfmPasswordSubmittedEmpty: false });
    }
    return hasEmpty;
  }

  newPasswordValidation() {
    // 1 upper, 1 lower, 1 special, 1 number, minimum 8 characters
    const regex =
      /^(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (
      !this.state.newPassword ||
      regex.test(this.state.newPassword) === false
    ) {
      this.setState({
        errorNewPassword:
          "Password is not valid. Ensure password has a minimum of 8 Characters, with at least a symbol, upper and lower case letters and a number.",
      });
      return false;
    }
    this.setState({
      errorNewPassword: "",
    });
    return true;
  }

  cfmPasswordValidation() {
    if (
      !this.state.cfmPassword ||
      this.state.newPassword !== this.state.cfmPassword
    ) {
      this.setState({
        errorCfmPassword: "Passwords do not match.",
      });
      return false;
    }
    this.setState({
      errorCfmPassword: "",
    });
    return true;
  }

  handleSubmit = async () => {
    if (this.hasEmptyInput()) {
      return;
    }

    if (!this.newPasswordValidation()) {
      return;
    }

    if (!this.cfmPasswordValidation()) {
      return;
    }

    this.setState({ isLoading: true });
    const res = await initiateCall(RequestType.POST, `/updatePassword`, {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      userId: JSON.parse(localStorage.getItem("@userdata")).value.id,
    });
    if (res) {
      if (res.data.status === 404) {
        this.flashError(
          "Failed to change password, old password mismatched.",
          3000
        );
      } else {
        this.flashSuccess("Change successful!", 3000);
      }
    } else {
      this.flashError("Failed to change password. Please try again.", 3000);
    }
    this.setState({ isLoading: false });
  };

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="#000000"
              sx={{ fontWeight: "bold" }}
            >
              Change Password
            </Typography>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <div className="ChangePassword">
          <Grid container justifyContent="center">
            <Paper
              elevation={0}
              sx={{
                paddingLeft: 3,
                paddingRight: 3,
                paddingTop: 2,
                paddingBottom: 2,
                width: "40%",
              }}
            >
              <Typography class="SubLabel">Old Password</Typography>
              <Box
                sx={{
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.oldPassword}
                  id="standard-adornment-password"
                  onChange={this.oldPasswordChange}
                  type={this.state.showOldPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowOldPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showOldPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={this.state.oldPasswordSubmittedEmpty}
                  helperText={
                    this.state.oldPasswordSubmittedEmpty
                      ? "Old password cannot be empty!"
                      : ""
                  }
                />
              </Box>

              <Typography class="SubLabel">New Password</Typography>
              <Box
                sx={{
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.NewPassword}
                  id="standard-adornment-password"
                  onChange={this.newPasswordChange}
                  type={this.state.showNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowNewPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={this.state.newPasswordSubmittedEmpty}
                  helperText={
                    this.state.newPasswordSubmittedEmpty
                      ? "New password cannot be empty!"
                      : ""
                  }
                />
              </Box>
              <div className="errorMessage">{this.state.errorNewPassword}</div>
              <Typography class="SubLabel">Confirm New Password</Typography>
              <Box
                sx={{
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.cfmPassword}
                  id="standard-adornment-password"
                  onChange={this.cfmPasswordChange}
                  type={this.state.showCfmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowCfmPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showCfmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={this.state.cfmPasswordSubmittedEmpty}
                  helperText={
                    this.state.cfmPasswordSubmittedEmpty
                      ? "Confirm New password cannot be empty!"
                      : ""
                  }
                />
              </Box>
              <div className="errorMessage">{this.state.errorCfmPassword}</div>
              {!this.state.isLoading && (
                <div className="submitContainer">
                  <Button class="submitButton" onClick={this.handleSubmit}>
                    Confirm and Submit
                  </Button>
                </div>
              )}
              {this.state.isLoading && (
                <div className="submitContainer">
                  <LoadingButton
                    class="submitButton"
                    loading={true}
                    loadingPosition="start"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    Confirm and Submit
                  </LoadingButton>
                </div>
              )}
            </Paper>
          </Grid>
          <Notifications
            setFlashSuccess={(func) => (this.flashSuccess = func)}
            setFlashError={(func) => (this.flashError = func)}
          />
        </div>
      </>
    );
  }
}
