import React, { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";

export default class AlertJobDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Dialog
        id="AlertJobDialog"
        open={this.props.DialogOpen}
        onClose={this.props.handleDialogClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {this.props.title === "Delete Account" ? (
          <DialogTitle
            className="DialogTitleRed"
            color="red"
            id="scroll-dialog-title"
          >
            {this.props.title}
          </DialogTitle>
        ) : (
          <DialogTitle className="DialogTitle" id="scroll-dialog-title">
            {this.props.title}
          </DialogTitle>
        )}
        <DialogContent dividers={true}>
          <DialogContentText
            className="DialogContentText"
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {this.props.message}
          </DialogContentText>
          <DialogContentText className="DialogContentText">
            {this.props.secondMessage}
            <Typography className="Typography">
              {this.props.messageBold}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="Button"
            id="cancelBtn"
            onClick={this.props.handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            className="Button"
            variant="contained"
            color={this.props.buttonColor}
            onClick={this.props.handleUserUpdate}
          >
            {this.props.buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
