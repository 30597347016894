import RequestType from "./RequestType.js";
import Api from "../services/Api.js";

/**
 * Serves as the helper for initiating API calls and error-handling.
 * @param {RequestType} requestType type of request
 * @param {string} endpoint request endpoint
 * @param {JSON} params request parameters
 */
async function initiateCall(requestType, endpoint, params = null) {
  let res = null;
  try {
    switch (requestType) {
    case RequestType.GET:
      res = await Api.get(endpoint)
      break;
    case RequestType.PUT:
      res = await Api.put(endpoint, params);
      break;
    case RequestType.POST:
      res = await Api.post(endpoint, params);
      break;
    case RequestType.DELETE:
      res = await Api.delete(endpoint, params);
      break;
    }
  } catch (err) {
    console.log(err.message);
    return undefined
  } finally {
    return res;
  }
}

export {
  initiateCall
}