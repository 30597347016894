import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";

import TextField from '@mui/material/TextField';


export default class EditDetailsDialog extends Component{

    constructor(props){
        super(props)

      this.state = {
        name:'',
        email:''

      }

    }

    componentDidMount = async (...args) =>
    {

    this.setState({name:this.props.userdata.name,email:this.props.userdata.email})
    console.log(this.props.userdata);
     console.log("user",this.state.name);
    }
    
    render(){

            const {editOpen,close} = this.props
           

        return(
            <div>
            <Dialog
                open={editOpen}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="xs"
                onClose={close}
            >
                
                    <DialogTitle sx={{backgroundColor:"#f5f2f2",fontWeight:"bold"}} className="DialogTitle" id="scroll-dialog-title">
                                Edit Details
                    </DialogTitle>
    
                    <DialogContent>
                            <DialogContentText style={{padding:"5px"}}>
    
                                    <div style={{display:"flex",flexDirection:"column",padding:"10px"}}>
                                        <label style={{marginBottom:"5px"}}>Name</label>
                                        <TextField id="outlined-basic" value={this.props.userdata.name}  onChange={(e) => this.props.userdata.name = e.target.value} inputProps={{ style: {padding:9}}} variant="outlined" />
                                        
                                    </div>
                                            
                
    
                                    <div style={{display:"flex",flexDirection:"column",padding:"10px"}}>
                                        <label style={{marginBottom:"5px"}}>Email</label>
                                        <TextField id="outlined-basic" value={this.props.userdata.email} onChange={(e) => this.props.userdata.email = e.target.value} inputProps={{ style: {padding:9}}} variant="outlined" />
                                        
                                    </div>
                                        
                            </DialogContentText>
    
                    </DialogContent>
    
                    <DialogActions sx={{backgroundColor:"#f5f2f2"}}>
                        <Button className="Button" onClick={close}>Close </Button>
                        <Button className="Button" variant="contained"> Save Changes</Button>
                    </DialogActions >
    
            </Dialog>
        </div>
        )
    }

}


