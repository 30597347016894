import BasePageComponent from "../../../components/BasePageComponent";
import "./PayoutInformation.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import { MapContainer, TileLayer,Marker,Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import "leaflet-defaulticon-compatibility";

import AppBar from "@mui/material/AppBar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Toolbar from "@mui/material/Toolbar";

import moment from "moment";

import RequestType from "../../../utils/RequestType";
import { initiateCall } from "../../../utils/ApiHandler";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import Notifications from "../../../components/Notifications";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

export default class LexisEventDetail extends BasePageComponent {
  constructor(props) {
    super(props);
    if (this.props.history.location.state === undefined) {
        window.location.assign("/eventorganizer");
    }

    this.prevState = this.props.history.location.state;
    this.business_type = [
        "", "Sole Proprietorship", "Company", "Partnership", "Charity, Club or Non-profit"
    ]
    
    this.status_level_color = {"Active": "green", "Inactive": "red"}
    

    this.state = {
      status_level: this.prevState.status,
      modalTitle: '',
      showModal: false,
      imageSrc: '',
    }

    this.slideImages = [
      {
        url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
        caption: 'Slide 1'
      },
      {
        url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
        caption: 'Slide 2'
      },
      {
        url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
        caption: 'Slide 3'
      },
    ];

    this.spanStyle = {
      padding: '20px',
      background: '#efefef',
      color: '#000000'
    }
    
    this.divStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundSize: 'cover',
      height: '400px'
    }

  }

  async componentDidMount() {
    console.log('-- prevstate --', this.prevState);
  }

  async activateEvent (){
    const res = await initiateCall(RequestType.POST, `/activateLexisEvent`, {
      id: this.prevState.id,
      status: 'Active'
    });

    if(res.data.msg == "success"){
      this.setState({ status_level: 'Active' });
      this.flashSuccess("Lexis Event successfully activated!", 3000);
    } else {
      this.flashError("Something went wrong, Please try again.", 3000);
    }
  }

  
  async deactivateEvent (){
    const res = await initiateCall(RequestType.POST, `/activateLexisEvent`, {
      id: this.prevState.id,
      status: 'Inactive'
    });

    if(res.data.msg == "success"){
      this.setState({ status_level: 'Inactive' });
      this.flashSuccess("Lexis Event is deactivated", 3000);
    } else {
      this.flashError("Something went wrong, Please try again.", 3000);
    }
  }

  async showImage(imgSrc, title){
    this.setState({
      modalTitle: title,
      imageSrc: imgSrc,
      showModal: true
    });
  }

  render() {
    return super.render(
      <>
        <AppBar
          position="fixed"
          sx={{ width: `calc(100% - 240px)`, ml: `240px`, bgcolor: "white" }}
        >
          <Toolbar>
            <Grid container>
                <Grid className="firstrow" md={9}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        color="#000000"
                        sx={{ fontWeight: "bold" }}
                    >
                    <a href="/lexiseventlist" className="breadLink">
                      Event Details
                    </a>
                    </Typography>
                    <KeyboardArrowRightIcon
                        style={{ color: "grey" }}
                        sx={{ fontSize: "25px", fontWeight: "bold" }}
                    />
                    <Typography
                        color="black"
                        variant="h6"
                        sx={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                    {this.prevState.title}
                    </Typography>
                    <KeyboardArrowRightIcon
                        style={{ color: "grey" }}
                        sx={{ fontSize: "25px", fontWeight: "bold" }}
                    />
                    <div className="HighlightedContainer" style={{ color: this.status_level_color[this.state.status_level], fontSize: "12px" }}>
                        <div className="HighlightText">
                            {
                                this.state.status_level
                            }
                        </div>
                    </div>
                </Grid>

                <Grid container md={3} sx={{ marginTop: 0.5 }} justifyContent="flex-end">
                    { this.state.status_level == "Active" ?
                        <Chip
                            avatar={<Avatar>X</Avatar>}
                            label="Deactivate"
                            color="error" variant="elevated"
                            style={{marginRight: 14}}
                            onClick={() => this.deactivateEvent()}
                        />
                        : ""
                    }

                    { this.state.status_level == "Inactive" ?
                      <Chip
                        avatar={<Avatar>✓</Avatar>}
                        label="Activate"
                        color="success" 
                        onClick={() => this.activateEvent()}
                        />
                      : ""
                    }
                </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />

        

        <div className="PayoutInformation">
        <Grid container>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={4}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Event Title</Typography>
                    <Typography>
                      {this.prevState.title
                        ? this.prevState.title
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Description</Typography>
                    <Typography>
                      { this.prevState.description
                        ? <>
                          { (this.prevState.description).substring(0, 125)}
                          { this.prevState.description.length > 125 ?
                            <span style={{ color: 'gray', cursor: 'pointer' }}> ... Read more</span> : ''
                          }
                          </>
                        : ""}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Date Start</Typography>
                    <Typography>
                      {this.prevState.date_start
                        ? moment(this.prevState.date_start).format("DD-MM-YYYY HH:mm:ss")
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Date End</Typography>
                    <Typography>
                      {this.prevState.date_end
                        ? moment(this.prevState.date_end).format("DD-MM-YYYY HH:mm:ss")
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Publish Date</Typography>
                    <Typography>
                      {this.prevState.publish_date
                        ? moment(this.prevState.publish_date).format("DD-MM-YYYY HH:mm:ss")
                        : "N/A"}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={12}>
                        <Typography className="label">Location</Typography>
                        <Typography>
                          {this.prevState.venue
                            ? <>
                                { this.prevState.venue?.description }
                                <div style={{ marginBottom: "20px" }} />
                                <Grid container>
                                  <MapContainer center={[this.prevState.venue?.lat_lng.lat, this.prevState.venue?.lat_lng.lng]} zoom={13} style={{ height:"300px"}}>
                                    <TileLayer
                                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={[this.prevState.venue?.lat_lng.lat, this.prevState.venue?.lat_lng.lng]}>
                                      <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                      </Popup>
                                    </Marker>
                                  </MapContainer>
                                </Grid>
                              </>
                            : <Typography style={{ color: 'gray', cursor: 'pointer' }}>No Location Specified</Typography>
                            }
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            </Grid>
        </Grid>

        <div style={{ marginBottom: "40px" }} />
        
        <Grid container>
          <Grid container xs={12} spacing={2}>
              <Grid item xs={4}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Event Link</Typography>
                    <Typography style={{ color: 'blue', cursor: 'pointer' }}>
                      {this.prevState.event_link
                        ? this.prevState.event_link
                        : ""}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Event Type</Typography>
                    <Typography>
                      { this.prevState.event_type_value
                        ? this.prevState.event_type_value
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Event Category</Typography>
                    <Typography>
                      { this.prevState.event_category_value
                        ? this.prevState.event_category_value
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                    <Typography className="label">Type of events (SGD)</Typography>
                    <Typography>
                      {this.prevState.is_free
                        ? this.prevState.is_free ? "FREE" : "PAID"
                        : "N/A"}
                    </Typography>
                    <div style={{ marginBottom: "20px" }} />
                  </div>
                </Paper>
              </Grid>

              
              <Grid item xs={8}>
                <Paper sx={{ borderRadius: 3, height: "100%" }}>
                  <div style={{ padding: "18px" }}>
                    <Typography className="label">Event Images</Typography>
                    <div style={{ marginBottom: "20px" }} />
                    {
                      this.prevState.images && this.prevState.images !== null ?
                          <Grid item xs={12} style={{ height: "100%", width: 1000 }}>
                            <div className="slide-container" style={{ border: 1, borderColor: '#c8c8c8', borderStyle: 'solid',  borderRadius: 6}}>
                              <Slide>
                              {
                                this.prevState.images.map((row) => {
                                  return <div className="each-fade">
                                    <img src={row} width='100%' height='400px' style={{ borderRadius: 6 }} />
                                  </div>
                                })
                              }
                              </Slide>
                            </div>
                          </Grid>
                      : "No Image uploaded"
                    }
                    
                  </div>
                </Paper>
              </Grid>

              
          </Grid>
        </Grid>

        

        <div style={{ marginBottom: "40px" }} />
        
        <Grid container>
            
        </Grid>

          <div style={{ marginBottom: "40px" }} />
        </div>

        <Dialog
            open={this.state.showModal}
            scroll="paper"
            PaperProps={{
                style: { borderRadius: 12 }
              }}
            >
          <DialogTitle className="DialogTitle" id="scroll-dialog-title">
            {this.state.modalTitle}
          </DialogTitle>

          <DialogContent dividers={true}>
            <div className="form">
              <div className="form-group">
                <Grid container>
                  <img src={`https://lexicard-public.s3.ap-southeast-1.amazonaws.com/organizer/payoutinformation/${this.state.imageSrc}`}
                    width='100%' />
                </Grid>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              className="Button"
              id="cancelBtn"
              onClick={() => {
                  this.setState({
                    showModal: false,
                    imageSrc: ''
                  })
              }}
            >
              Close
            </Button>
            <Button
              className="Button"
              variant="contained"
              color={this.state.DialogButtonColor}
              onClick={() => {
                  this.setState({
                    showModal: false,
                    imageSrc: ''
                  })
              }}
            >
              Ok
            </Button>
          </DialogActions>
      </Dialog>

        <Notifications
          setFlashSuccess={(func) => (this.flashSuccess = func)}
          setFlashError={(func) => (this.flashError = func)}
        />
      </>
    );
  }
}
