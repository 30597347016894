import React, { Component } from "react";
import "./MenuDrawer.scss";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListAltIcon from "@mui/icons-material/ListAlt";
import RequestQuote from "@mui/icons-material/RequestQuote";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EventIcon from '@mui/icons-material/Event';
import ListItemButton from "@mui/material/ListItemButton";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WorkIcon from "@mui/icons-material/Work";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeIcon from "@mui/icons-material/Badge";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Badge from "@mui/material/Badge";
import RedeemIcon from '@mui/icons-material/Redeem';
//import RedeemIcon from "@mui/icons-material/Redeem";

import Api from "../services/Api";
import { getEnvVars } from '../services/Environment';

const { env } = getEnvVars();

const menulist = {
  dashboard: 0,
  notifications: 1,
  lexseeker: 2,
  lexployer: 3,
  jobposts: 4,
  lexiscard: 5,
  adminmanagement: 6,
  lexiscardreward: 7,
  lexispayoutlist: 8,
  eventorganizer: 9,
};
export default class MenuDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: true,
      listIndex: 0,
      name: "",
      showDrp: "",
      overlay: "",
      allNotifications: [],
      usertype: "",
      permission: {},
    };
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  async componentDidMount() {
    this.getNoti();
    this.setState({ listIndex: this.props.history.location.state });
    //let userdata = JSON.parse(localStorage.getItem("@userdata"));
    let userdata = this.getWithExpiry("@userdata");

    let noti = JSON.parse(localStorage.getItem("@noti"));
    var name = this.truncateNameString(userdata.username); //.substring(0, 22) + "..";

    this.setState({
      name: name,
      showDrip: "",
      overlay: "",
      notiCount: noti,
      usertype: userdata.usertype,
    });

    var param = window.location.href.split("/");

    if (param[3] in menulist) {
      this.setState({ listIndex: menulist[param[3]] });
    }

    this.getPerm(userdata.usertype);
  }

  getPerm = async (type) => {
    const res = await Api.post("/getPermission", { type: type });
    console.log('res.data.data[0].permission : ',res.data.data[0].permission)
    if (res.data.message === "success") {
      this.setState({ permission: res.data.data[0].permission });
    }
  };

  getNoti = async () => {
    // let result;
    // const res = await Api.post("/getNoti");
    // if (res.data.message === "success") {
    //   result = res.data.data;
    //   this.setState({ allNotifications: result });
    // }

    // window.localStorage.setItem(
    //   "@noti",
    //   JSON.stringify(
    //     this.state.allNotifications.filter((obj) => obj.status === 0).length
    //   )
    // );
  };

  truncateNameString = (name) => {
    var n = "";

    if (name.length > 22) {
      n = name.substring(0, 22) + "..";
    } else {
      n = name;
    }
    return n;
  };

  openDrawerMethod = () => {
    this.setState({ openDrawer: true });
  };
  closeDrawerMethod = () => {
    this.setState({ openDrawer: false });
  };

  scrollToHeroMobile = () => {
    window.location.href = "/dashboard";

    // document
    //   .getElementById("heroMobile")
    //   .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToPriceMobile = () => {
    document
      .getElementById("pricingMobile")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToContactMobile = () => {
    document
      .getElementById("contactMobile")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  open = () => {
    setTimeout(() => {
      if (this.state.showDrp === "") {
        this.setState({ showDrp: "accountDropdown", overlay: "overlayMenu" });
      } else {
        this.setState({ showDrp: "", overlay: "noOverlayMenu" });
      }
    }, 100);
  };

  closeOverlay = () => {
    setTimeout(() => {
      this.setState({ showDrp: "", overlay: "noOverlayMenu" });
    }, 100);
  };

  logout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };

  list = (anchor) => (
    <Box
      role="presentation"
      onClick={this.closeDrawerMethod}
      onKeyDown={this.closeDrawerMethod}
      className="MenuDrawer"
    >
      <List>
        <div className={this.state.overlay} onClick={this.closeOverlay}></div>
        <ListItem
          className="logo-container center"
          onClick={this.scrollToHeroMobile}
        >
          <img
            className="logo"
            src="/assets/images/LexisJobs_Logo.png"
            alt="logo"
          />
        </ListItem>
      </List>
      {/* <Divider className="divider"/> */}
      {this.state.permission ? (
        <List
          className="navLink"
          sx={{
            // selected and (selected + hover) states
            "&& .Mui-selected, && .Mui-selected:hover": {
              bgcolor: "",
              "&, & .MuiListItemIcon-root": {
                color: "",
              },
              "&, & .itemText": {
                color: "white",
                opacity: 100,
                fontWeight: 700,
              },
              "&, & .itemIcon": { color: "white", opacity: 100 },
            },
            // hover states
            "& .MuiListItemButton-root:hover": {
              bgcolor: "#0247F1",
              "&, & .MuiListItemIcon-root": {
                color: "",
              },
            },
          }}
        >
          {this.state.permission?.menu?.dashboard && (
            <ListItemButton
              selected={this.state.listIndex === 0}
              onClick={() =>
                this.props.history.push({ pathname: "/dashboard", state: 0 })
              }
            >
              <HomeIcon className="itemIcon" />
              <ListItemText className="itemText" primary="Dashboard" />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.notifications && (
            <ListItemButton
              selected={this.state.listIndex === 1}
              onClick={() =>
                this.props.history.push({
                  pathname: "/notifications",
                  state: 1,
                })
              }
            >
              <Badge badgeContent={JSON.parse(localStorage.getItem("@noti"))}>
                <NotificationsIcon className="itemIcon" />
              </Badge>
              <ListItemText className="itemText" primary="Notifications" />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.lexseeker && (
            <ListItemButton
              selected={this.state.listIndex === 2}
              onClick={() =>
                this.props.history.push({ pathname: "/lexseeker", state: 2 })
              }
            >
              <BadgeIcon className="itemIcon" />
              <ListItemText className="itemText" primary="Lexseeker List" />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.lexployer && (
            <ListItemButton
              selected={this.state.listIndex === 3}
              onClick={() =>
                this.props.history.push({
                  pathname: "/lexployer",
                  state: 3,
                })
              }
            >
              <BusinessIcon className="itemIcon" />
              <ListItemText className="itemText" primary="Lexployer List" />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.jobposts && (
            <ListItemButton
              selected={this.state.listIndex === 4}
              onClick={() =>
                this.props.history.push({ pathname: "/jobposts", state: 4 })
              }
            >
              <WorkIcon className="itemIcon" />
              <ListItemText className="itemText" primary="Job Posts" />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.lexiscard && (
            <ListItemButton
              selected={this.state.listIndex === 5}
              onClick={() =>
                this.props.history.push({ pathname: "/lexiscard", state: 5 })
              }
            >
              <ListAltIcon className="itemIcon" />
              <ListItemText
                className="itemText"
                primary="LexisCard User List"
              />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.lexiscard && (
            <ListItemButton
              selected={this.state.listIndex === 5}
              onClick={() =>
                this.props.history.push({ pathname: "/lexiscardgifts", state: 5 })
              }
            >
              <RedeemIcon className="itemIcon" />
              <ListItemText
                className="itemText"
                primary="LexisCard Gifts"
              />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.lexispayoutlist && env !== 'production' && (
            <ListItemButton
              selected={this.state.listIndex === 8}
              onClick={() =>
                this.props.history.push({ pathname: "/lexispayoutlist", state: 7 })
              }
            >
              <RequestQuote className="itemIcon" />
              <ListItemText
                className="itemText"
                primary="Lexis Event Payout"
              />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.eventorganizer && env !== 'production' && (
            <ListItemButton
              selected={this.state.listIndex === 9}
              onClick={() =>
                this.props.history.push({ pathname: "/eventorganizer", state: 9 })
              }
            >
              <ManageAccountsIcon className="itemIcon" />
              <ListItemText
                className="itemText"
                primary="Lexis Event Organizers"
              />
            </ListItemButton>
          )}
          {this.state.permission?.menu?.lexiseventlist && env !== 'production' && (
            <ListItemButton
              selected={this.state.listIndex === 10}
              onClick={() =>
                this.props.history.push({ pathname: "/lexiseventlist", state: 10 })
              }
            >
              <EventIcon className="itemIcon" />
              <ListItemText
                className="itemText"
                primary="Lexis Event List"
              />
            </ListItemButton>
          )}
          {/*
          this.state.permission?.menu?.lexiscardreward && (
            <ListItemButton
              selected={this.state.listIndex === 7}
              onClick={() =>
                this.props.history.push({
                  pathname: "/lexiscardreward",
                  state: 7,
                })
              }
            >
              <RedeemIcon className="itemIcon" />
              <ListItemText
                className="itemText"
                primary="LexisCard Reward Redemption"
              />
            </ListItemButton>
          )
          */}
          {this.state.permission?.menu?.adminmanagement && (
            <ListItemButton
              selected={this.state.listIndex === 6}
              onClick={() =>
                this.props.history.push({
                  pathname: "/adminmanagement",
                  state: 6,
                })
              }
            >
              <PeopleIcon className="itemIcon" />
              <ListItemText className="itemText" primary="Admin Management" />
            </ListItemButton>
          )}
        </List>
      ) : (
        <></>
      )}

      <div className="navFooter">
        <div className={this.state.showDrp}>
          <div className="dropdown-content">
            <div
              className="dropLink"
              onClick={() =>
                this.props.history.push({
                  pathname: "/changepassword",
                })
              }
            >
              <LockOutlinedIcon />
              <div className="dropItem">Change Password</div>
            </div>
            <div className="dropLink logout" onClick={this.logout}>
              <LogoutIcon /> <div className="dropItem logout">Logout</div>
            </div>
          </div>
        </div>
        <Divider className="divider" />
        <ListItemButton onClick={this.open}>
          <AccountCircleIcon className="itemIconAccount" />
          <ListItemText className="itemTextAccount" primary={this.state.name} />
        </ListItemButton>
      </div>
    </Box>
  );

  render() {
    return (
      <>
        <Drawer
          sx={{
            width: this.props.drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: this.props.drawerWidth,
              boxSizing: "border-box",
            },
          }}
          BackdropProps={{ invisible: true }}
          anchor={"left"}
          open={this.state.openDrawer}
          onClose={this.closeDrawerMethod}
          variant="permanent"
        >
          {this.list()}
        </Drawer>
      </>
    );
  }
}

// export default function MenuDrawer(props) {

//   const [state, setState] = React.useState({
//     top: false,
//     left: props.openDrawer ? props.openDrawer : false,
//     bottom: false,
//     right: false,
//   });

//   if (props.openDrawer){
//     setState({ ...state, left: true });
//   }
//   const toggleDrawer = (anchor, open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };

//   const list = (anchor) => (
//     <Box
//       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >
//       <List>
//         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {['All mail', 'Trash', 'Spam'].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   return (
//     <div>
//          <Drawer
//             anchor={'left'}
//             open={state['left']}
//             onClose={toggleDrawer('left', false)}
//           >
//             {list('left')}
//           </Drawer>
//     </div>
//   );
// }
