import React from "react";
import "./Styles/CompanyProfile.scss";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { initiateCall } from "../../../utils/ApiHandler";
import RequestType from "../../../utils/RequestType";

export default class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    //  this.prevState =  this.props.history.location.state;

    this.state = {
      companyProfile: this.props.data,
    };
  }

  componentDidMount = async (...args) => {
    // let location = this.props.history;
    console.log(this.state.companyProfile.workculture);
    // this.setState({companyProfile:this.props.data});
    //this.getdata(this.props.data);
  };

  //   getdata =async (userid) =>{
  //     const res = await initiateCall(RequestType.POST, `/getcompanyprofilebyid`, {id:userid});
  //     this.setState({companyProfile:res.data.data})

  //     console.log("why",res.data.data);

  // }

  render() {
    return (
      <div className="companyProfile-container">
        <Grid sm={12} md={8} lg={6} container>
          <Typography
            variant="h6"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            Company Details
          </Typography>
        </Grid>

        <Grid sm={12} md={8} lg={6} container className="paper">
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Typography color="#b8b5b5">Company Name</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {this.state.companyProfile.compname}
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Typography color="#b8b5b5">Company Uen</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {this.state.companyProfile.compuen}
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Typography color="#b8b5b5">Industry</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {this.state.companyProfile.industry}
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Typography color="#b8b5b5">Company Website</Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {this.state.companyProfile.website}
            </Typography>
          </Grid>

          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Typography color="#b8b5b5">Description</Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              {" "}
              {this.state.companyProfile.description}
            </Typography>
          </Grid>

          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Typography color="#b8b5b5">Logo</Typography>
            <Grid xs={2} className="logoImg">
              <img
                src={this.state.companyProfile.complogo}
                alt="image here"
                className="logoImg"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          sm={12}
          md={8}
          lg={6}
          style={{ marginBottom: "10px", marginTop: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            Work Culture
          </Typography>
        </Grid>

        <Grid container md={8} lg={6} spacing={1} className="workculture">
          {this.props.data.workculture.map((data) => (
            <Grid className="item" item={true}>
              {data}
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          md={8}
          lg={6}
          style={{ marginBottom: "10px", marginTop: "20px" }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            Qualities of an Ideal Candidate
          </Typography>
        </Grid>

        <Grid
          container
          sm={12}
          md={8}
          lg={6}
          spacing={1}
          className="workculture"
        >
          {this.state.companyProfile.candidatepossess.map((item, key) => (
            <Grid className="item" key={key} item={true}>
              {item}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}
