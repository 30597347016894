import React, {Component} from "react";
import { Grid, Tabs, Tab, Dialog, IconButton } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import SearchBox from "../SearchBox.js";
import "./components.scss";
import {initiateCall} from "../../../utils/ApiHandler";
import RequestType from "../../../utils/RequestType";
import {REDEMPTION_TABLE} from "../../../Globals";
import Notifications from "../../../components/Notifications";
import RedemptionModal from "./redemptionModal.js";
import EditIcon from '@mui/icons-material/Edit';
import {dateChecker} from "./reward";

const DATA_PAGE = 0; // default page number
const DATA_PAGE_SIZE = 10; // default size of page
// tab values
export const REQUEST_RECEIVED = 0;
export const CONTACTED = 1;
export const COLLECTION_ARRANGED = 2;
export const COLLECTED = 3;
export const NO_SHOW = 4;

const ITEM_BASE = { // base template for redemption list modal
    status: REQUEST_RECEIVED,
    useremail: "",
    name: "",
    arrangedon: new Date(),
    arrangedtime: new Date(),
    collectedon: new Date(),
    collectedtime: new Date(),
    updatedon: "",
}

export default class Redemption extends Component {
    constructor(props) {
        super(props);
        this.searchFilterRef = React.createRef();
        this.state = {
            tab: REQUEST_RECEIVED,
            dataLoading: false,
            searchDisable: false,
            dataRows: [],
            dataPage: DATA_PAGE,
            dataPageSize: DATA_PAGE_SIZE,
            searchValue: "",
            sort: "asc",
            field: "useremail", // sort by name asc by default
            status: REQUEST_RECEIVED, // TBD how to arrange tabs

            redemptionModal: false,
            modalData: ITEM_BASE,

            requestscount: 0,
            contactedcount: 0,
            collectioncount: 0,
            collectedcount: 0,
            noshowcount: 0,

        };
    }

    componentDidMount(...args) {
        this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue)
        this.props.setExcelParams({ // passes parameters for excel exporting
            search: this.state.search,
            status: this.state.status
        })
    }

    handleTabChange = (e, newValue) => {
        this.setState({
            tab: newValue,
            status: newValue,
            dataPage: DATA_PAGE,
            dataPageSize: DATA_PAGE_SIZE,
        })
        this.props.setExcelParams({
            search: this.state.searchValue,
            status: newValue
        })

        this.fetchData(DATA_PAGE, DATA_PAGE_SIZE, newValue, this.state.searchValue)

      }

    fetchData = async (
        page = null,
        pageSize = null,
        status = null,
        search = null,
        field = null,
        sort = null,
    ) => {
        this.setState({ dataLoading: true, searchDisable: true });

        if (field == null) {
            field = this.state.field;
        }

        if (sort == null) {
            sort = this.state.sort;
        }

        const params = {
            tablename: REDEMPTION_TABLE,
            page: page,
            pageSize: pageSize,
            search: search,
            sort: sort,
            field: field,
            status: status,
        }

        const res = await initiateCall(RequestType.POST, `/redemptions`, params);

        if (res) {
            this.setState({
                dataRows: res.data.data.rows,
                requestscount: parseInt(res.data.requestscount),
                contactedcount: parseInt(res.data.contactedcount),
                collectioncount: parseInt(res.data.collectioncount),
                collectedcount: parseInt(res.data.collectedcount),
                noshowcount: parseInt(res.data.noshowcount)
            });
        } else {
            this.setState({
                dataRows: [],
                requestscount: 0,
                contactedcount: 0,
                collectioncount: 0,
                collectedcount: 0,
                noshowcount: 0,
            });
        }
        this.setState({ dataLoading: false, searchDisable: false });

        // Auto focus on searchbox if this was a search
        if (search !== null) {
            document.getElementById("userSearchBox").focus();
        }
    };

    rowCount = () => {
        if (this.state.tab === REQUEST_RECEIVED) {
            return this.state.requestscount;
        } else if (this.state.tab === CONTACTED) {
            return this.state.contactedcount;
        } else if (this.state.tab === COLLECTION_ARRANGED) {
            return this.state.collectioncount;
        } else if (this.state.tab === COLLECTED) {
            return this.state.collectedcount
        } else if (this.state.tab === NO_SHOW) {
            return this.state.noshowcount
        }

        return -1;
    };

    handleSortModelChange = (event) => {
        if (event[0]) {
            let field = event[0].field;
            let sort = event[0].sort;

            this.setState({
                sort: sort,
                field: field,
            });

            this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue, field, sort);
        }
    };

    modalRedemptionToggle = (row) => {
        if (row?.useremail) { // if opening modal
            this.setState({modalData: row})
        }
        this.setState({redemptionModal: !this.state.redemptionModal})
    }
    searchBoxChange = (event) => {
        this.setState({ searchValue: event.target.value });
        clearTimeout(this.searchFilterRef);
        this.searchFilterRef = setTimeout(
            () => this.fetchData(DATA_PAGE, DATA_PAGE_SIZE, this.state.status, event.target.value),
            700
        );
        this.setState({
            dataPage: DATA_PAGE,
            dataPageSize: DATA_PAGE_SIZE
        })

        // pass props to parent for excel parameters
        this.props.setExcelParams({
            search: event.target.value,
            status: this.state.status
        })
    };

   getStatus = (value) => {
       if (value === REQUEST_RECEIVED) {
           return "Request Received"
       } else if (value === CONTACTED) {
           return "Contacted"
       } else if (value === COLLECTION_ARRANGED) {
            return "Collection Arranged"
       } else if (value === COLLECTED) {
            return "Collected"
       } else if (value === NO_SHOW) {
            return "No Show"
       }
       return "INVALID"
   }

   parseDateTime = (date, time) => {
       if ((date === undefined || date === null) && (time === undefined || time === null)) {
           return null
       } else if (date === undefined) {
           return null
       } else if (time === undefined) {
           time = new Date() // if no time selected, put current time as default
       }
       const newDate = date
       const removeTime = new Date(newDate.getTime() - newDate.getHours() * 60 * 60000 - newDate.getMinutes() * 60000 - newDate.getSeconds() * 1000)
       const hour = time.getHours()
       const minute = time.getMinutes()
       const seconds = time.getSeconds()
       const newTime = hour * 60000 * 60 + minute * 60000 + seconds * 1000
       const dateTime = new Date(removeTime.getTime() + newTime)
       return dateTime
   }

   submit = async (item) => {
       const params = item
       params.tablename = REDEMPTION_TABLE
       const userid = JSON.parse(localStorage.getItem("@userdata"))
       params.modifiedby = userid.value.id // uuid in database
       params.collectedon = this.parseDateTime(params.collectedon, params.collectedtime)
       params.arrangedon = this.parseDateTime(params.arrangedon, params.arrangedtime)
       const res = await initiateCall(RequestType.POST, `/editredemptionitem`, params);

       if (res.data.message === 'success') {
           this.fetchData(this.state.dataPage, this.state.dataPageSize, this.state.status, this.state.searchValue)
           this.flashSuccess("Successfully updated status!", 3000)
           // clear data
           this.setState({modalData: ITEM_BASE})
           this.modalRedemptionToggle()
       } else {
           this.flashError("Error updating status! Please ensure all fields are filled in!", 3000)
       }
   }

    render() {
        const columns = [
            { field: 'useremail', headerName: 'Email', width: 150, flex: 1},
            { field: 'pretty_request_date', headerName: 'Date Requested', width: 300, flex: 1 },
            { field: 'name', headerName: 'Item Name', width: 130, flex: 1},
            { field: 'status', headerName: 'Status', width: 130, flex: 1, 
            renderCell: (params) => (
                <>
                    <IconButton sx={{color: "#666666", marginRight: 1}} onClick={() => this.modalRedemptionToggle(params.row)}>
                        <EditIcon fontSize="small"/>
                    </IconButton>
                    {this.getStatus(params.value)}
                </>
              ),},
            { field: 'pretty_modifiedon', headerName: 'Updated On', width: 150 },
        ];
    return (
        <>
        <Dialog
            open={this.state.redemptionModal}
            onClose={this.modalRedemptionToggle}
            scroll="paper"
            PaperProps={{
                style: { borderRadius: 12 }
              }}
            >
            <RedemptionModal onClose={this.modalRedemptionToggle}
                             data={this.state.modalData}
                             submit={this.submit}/>
        </Dialog>
        <Grid container spacing={1} sx={{padding: "12px", marginTop: "40px"}}>
            <Grid item xs={12}>
                <SearchBox boxlean={false}
                           handleTextChange={this.searchBoxChange}
                           disabled={this.state.searchDisable}></SearchBox>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleTabChange}
                    className="datatabs"
                    TabIndicatorProps={{
                    style: {
                        height: "3px",
                        marginTop: "-3px",
                    },
                    }}
                    sx={{border: " solid rgba(224, 224, 224, 1)", borderWidth: "1px 1px 1px 0px;"}}
                >
                    <Tab label={`Request Received (${this.state.requestscount})`} sx={{ textTransform: 'none' }}/>
                    <Tab label={`Contacted (${this.state.contactedcount})`} sx={{ textTransform: 'none' }}/>
                    <Tab label={`Collection Arranged (${this.state.collectioncount})`} sx={{ textTransform: 'none' }}/>
                    <Tab label={`Collected (${this.state.collectedcount})`} sx={{ textTransform: 'none' }}/>
                    <Tab label={`No Show (${this.state.noshowcount})`} sx={{ textTransform: 'none' }}/>
                </Tabs>
                <DataGrid
                    rows={this.state.dataRows}
                    columns={columns}
                    loading={this.state.dataLoading}
                    rowsPerPageOptions={[10, 50, 100]}
                    paginationMode="server"
                    page={this.state.dataPage}
                    sortingMode="server"
                    rowCount={this.rowCount()}
                    pageSize={this.state.dataPageSize}
                    sx={{ bgcolor: "white", borderTopRightRadius: 0, borderTopLeftRadius: 0, borderTopWidth: 0}}
                    autoHeight
                    disableColumnSelector
                    disableSelectionOnClick
                    onPageChange={(newPage) => {
                        this.setState({ dataPage: newPage });
                        this.fetchData(newPage, this.state.dataPageSize, this.state.status, this.state.searchValue);
                    }}
                    onPageSizeChange={(newPageSize) => {
                        this.setState({ dataPageSize: newPageSize, dataPage: DATA_PAGE });
                        this.fetchData(DATA_PAGE, newPageSize, this.state.status, this.state.searchValue);
                    }}
                    onSortModelChange={this.handleSortModelChange}
                />
            </Grid>
            <Notifications
                setFlashSuccess={(func) => (this.flashSuccess = func)}
                setFlashError={(func) => (this.flashError = func)}
            />
        </Grid>
        </>
    )
}

}
  