import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import React, { Component } from "react";

import Stack from "@mui/material/Stack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DropDown from "./DropDown";
import AlertDialog from "./AlertDialog";
import EditAlertDialog from "./EditAlertDialog";

export default class ActivePost extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Grid
          lg={12}
          container
          height="70vh"
          sx={{ backgroundColor: "white", marginTop: "20px" }}
        >
          <DataGrid
            rows={this.props.rows}
            columns={this.props.columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  There are no reported job posts
                </Stack>
              ),
            }}
          />
        </Grid>

        <DropDown
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          onClose={this.props.closeDropDown}
          selectStatus={this.props.selectStatus}
          selectDialog={this.props.onselectDropDownDialog}
        />

        <AlertDialog
          open={this.props.openDialog}
          close={this.props.closeDialog}
          dialogHeader={this.props.dialogHeader}
          buttonText={this.props.buttonText}
          buttonStyle={this.props.buttonStyle}
          dialogContent={this.props.dialogContent}
          showDialogTextInput={this.props.showDialogTextInput}
        />

        {/* <EditAlertDialog 
              openEditDialog={this.props.openEditDialog}
              closeEditDialog={this.props.closeDialog}
           /> */}
      </div>
    );
  }
}
