import React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

const DropDownMenu = ({
  anchorEl,
  open,
  close,
  selectItem,
  dropDownMenuClose,
  editSelected,
  selectedUser,
}) => {
  const [optionsList, setOptionsList] = useState([
    {
      value: "delete",
      text: "Delete Account",
      status: "",
      buttonStatus: "Delete",
    },
  ]);

  const editOptions = {
    value: "edit",
    text: "Edit Details",
    status: "",
    buttonStatus: "Edit",
    data: selectedUser,
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={close}
      onClick={dropDownMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {selectedUser.usertype != "Owner" &&
        optionsList.map((option, index) => {
          return (
            <MenuItem
              key={index}
              className="drpdownLexployerJobDelete"
              onClick={() => selectItem(option)}
            >
              {option.text}
            </MenuItem>
          );
        })}

      <MenuItem
        /*
        style={{
          borderTopWidth: 1,
          borderColor: "rgb(197, 194, 194)",
          borderStyle: "solid",
        }}
        
        */
        className="drpdownLexployerJob"
        onClick={() => editSelected(editOptions)}
      >
        {editOptions.text}
      </MenuItem>
    </Menu>
  );
};

export default DropDownMenu;
