import { Component } from "react";
import "./ForgotPassword.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { initiateCall } from "../../utils/ApiHandler";
import RequestType from "../../utils/RequestType";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Notifications from "../../components/Notifications";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailSubmittedEmpty: false,
      errorEmail: "",
      isLoading: false,
    };
  }

  enterToResetPassword = (e) => {
    if (e.key === "Enter") {
      this.handleResetPassword();
    }
  };

  async componentDidMount() {
    window.addEventListener("keydown", this.enterToResetPassword);
  }

  async componentWillUnmount() {
    window.removeEventListener("keydown", this.enterToResetPassword);
  }

  emailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleResetPassword = async () => {
    if (!this.emailValidation()) {
      return;
    }

    this.setState({ isLoading: true });
    const res = await initiateCall(RequestType.POST, `/forgetPassword`, {
      email: this.state.email,
    });
    if (res) {
      this.setState({ email: "" });
      this.flashSuccess(
        "An email will be sent to the address if it is associated with an existing account.",
        3000
      );
    } else {
      this.flashError("Error sending email. Please try again.", 3000);
    }

    this.setState({ isLoading: false });
  };

  emailValidation() {
    /*
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      */
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (!this.state.email) {
      this.setState({
        emailSubmittedEmpty: true,
        errorEmail: "Please enter an email address.",
      });
      return false;
    } else if (regex.test(this.state.email) === false) {
      this.setState({
        emailSubmittedEmpty: true,
        errorEmail: "Email address is not valid.",
      });
      return false;
    }
    this.setState({
      emailSubmittedEmpty: false,
      errorEmail: "",
    });
    return true;
  }

  render() {
    return (
      <div className="ForgotPassword">
        <div className="ForgetPasswordContainer">
          <Grid item xs={4}>
            <img
              className="MainLogo"
              src="assets/images/LexisJobs_Logo.png"
              alt="LexisJobs App"
            />
            <Typography
              className="BackButton"
              onClick={() => this.props.history.push("/")}
            >
              <ArrowBackIosIcon sx={{ color: "white", mr: 0, my: -0.8 }} />
              Back
            </Typography>
            <Paper
              elevation={10}
              sx={{
                justifyContent: "center",
                padding: 4,
                borderRadius: "10px",
              }}
            >
              <Typography className="SubHeader">Reset Your Password</Typography>
              <Typography className="SubLabel">Email</Typography>
              <Box
                sx={{
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "flex-end",
                  marginBottom: "25px",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.email}
                  id="input-with-sx"
                  onChange={this.emailChange}
                  error={this.state.emailSubmittedEmpty}
                  helperText={
                    this.state.emailSubmittedEmpty ? this.state.errorEmail : ""
                  }
                />
              </Box>
              {!this.state.isLoading && (
                <Button
                  className="ResetPasswordButton"
                  onClick={this.handleResetPassword}
                >
                  Reset Password
                </Button>
              )}
              {this.state.isLoading && (
                <LoadingButton
                  className="ResetPasswordButton"
                  loading={true}
                  loadingPosition="start"
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Reset Password
                </LoadingButton>
              )}
            </Paper>
          </Grid>
        </div>
        <Notifications
          setFlashSuccess={(func) => (this.flashSuccess = func)}
          setFlashError={(func) => (this.flashError = func)}
        />
      </div>
    );
  }
}
