import React, { Component } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";

export default class DropdownStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [
                {value: "-3", text: "All"},
                {value: "1", text: "Active"},
                {value: "0", text: "Inactive"},
                {value: "2", text: "Suspended"},
                {value: "3", text: "Deleted"},
            ]
        }
    }

    render() {
        return (
            <Box id="DropdownStatus">
                <Typography className="title">Status</Typography>
                <FormControl className="filter" size="small">
                    <Select
                        className="Select"
                        value={this.props.status}
                        onChange={this.props.handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {
                            this.state.list.map((item, key)=>{
                                return (
                                    <MenuItem sx={{fontSize: 14}} key={key} value={item.value}>{item.text}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        )
    }
}